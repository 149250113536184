import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";

import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"

const Emp_Report=()=> {
	const history = useHistory()
	
	const [emp_list, setEvent] = useState([])
	const [active_count,setCount]=useState([])
	const [isLoading, setLoading] = useState(true)
	const [total_act_today,setTotalAT]=useState('')
	const [Today_Total_work,setTotalWT]=useState('')
	const [Yesterday_Total_Work,setTotalWY]=useState('')
	const [This_month_Total_Work,setTotalWTM]=useState('')
	const [This_week_Total_Work,setTotalWTW]=useState('')

	useEffect(()=>{
		/*console.log("Com[p dashbpoard",localStorage.company_id)*/
		let count=[]
		fetch(localStorage.ip_address+'/users_list/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"company_id":localStorage.company_id
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				setEvent(resJson)
				/*console.log(resJson)*/
				setLoading(false)
				count=resJson.filter(data=>data.Online==true);
		  		setCount(count)
			}).catch((error)=> {
				console.log(error)
			})
			
		fetch(localStorage.ip_address+'/today_login_count/',{
            method: 'post',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            body: JSON.stringify({
                   "company_id":localStorage.company_id,  
               })
        }).then(response => response.json())
		  .then((jsonResponse) => {		 
		  		setTotalAT(jsonResponse.Today_Login)
		  		setTotalWT(jsonResponse.Today_Total_work)
		  		setTotalWY(jsonResponse.Yesterday_Total_Work)
		  		setTotalWTM(jsonResponse.This_month_Total_Work)
		  		setTotalWTW(jsonResponse.This_week_Total_Work)
		  	/*console.log(jsonResponse)*/
		  }).catch((error) => {
		  	setLoading(false)
	  	    	console.log('not data found')
	  	   });
	},[])

	const detail=(id)=>{
	 	fetch(localStorage.ip_address+'/user_detail/'+id+'/',{
	 		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
	 	})
		  .then(response => response.json())
		  .then((jsonResponse) => {
			  	/*console.log("User Details",jsonResponse)*/
			  	if(jsonResponse.Images.length){	
			  		history.push({pathname: '/emp_screenshots',state: { Data: jsonResponse,User_id:id }})
			  	}
			  	else{
			  		Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: 'No screenshot of this employee is available Now',
						icon: 'info',
						showConfirmButton: false,
					})
			  	}
		  	}).catch(error=>{console.log(error)
		  		console.log(error)
		})	
	}


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);

	const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font" 
		    	style={{color:'#206090'}} 
		    	value={ cell.id } >
		    	{cell.employee_name}
	    	</a>
	  	);
	}

	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"
					style={{color:'#206090'}} 
					value={ cell.id } >
					{cell.No}
				</a>
			)
	}


	const actionFormatterMonth=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" 
					style={{color:'#206090'}} 
					value={ cell.id } >
					{cell.Time.This_Month}
				</a>
			)
	}

	const handleEmail=(e, cell, row, rowIndex)=>{
		return (
				<a className="table-content-font"  
					style={{color:'#206090'}} 
					value={ cell.id } >
					{cell.username}
				</a>
	  	);
	}

	const actionFormatterScreenshot=(e,cell,row,rowIndex)=>{
		return(
				<a className="table-content-font point" 
					onClick={()=>{detail(cell.id)}}  
					style={{color:'#206090'}} 
					value={ cell.id } >
					<i className="fa fa-eye"></i>
				</a>
			);
	}

	const showSummary=(id)=>{
		localStorage.setItem('summary_id',id)
		history.push('/summary');
	}

	const actionFormatterGenerate=(e,cell,row,rowIndex)=>{
		return(
			<a onClick={()=>{showSummary(cell.id)}} value={cell.id} 
			  className="table-content-font text-primary"> 
			    <i class="fas fa-arrow-alt-circle-right " 
			     style={{cursor:'pointer' }} title='Generate Summary' 
			     aria-hidden="true">
			    </i> 
			</a>
		)
	}

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'left',
	  align:'left',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
   	  fontSize:'20px',
   	  color:'#fff',
   	  backgroundColor: '#215C8E'
    }
	},{ 
	  dataField: 'employee_name',
	  text: 'Name',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: actionFormatter,
	  classes: 'align-middle',
	  headerStyle: {
   	  fontSize:'20px',
   	  color:'#fff',
   	  backgroundColor: '#215C8E'
  }
	}, {
	  dataField: 'Email',
	  text:'Email',
	  headerAlign: 'center',
	  formatter: handleEmail,
	  align:'center',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#215C8E'
  }
	},  {
	  dataField: 'Time.This_Month',
	  headerAlign: 'center',
	  align:'center',
	  formatter: actionFormatterMonth,
	  text: 'Month',
	  classes: 'align-middle',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#1B7097'
  }
	},
	{
	  dataField: '',
	  headerAlign: 'center',
	  formatter: actionFormatterScreenshot,
	  align:'center',
	  text: 'Screenshot',
	  classes: 'align-middle',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#1B7097'
  }
	},
	 {
	  dataField: '',
	  headerAlign: 'center',
	  formatter: actionFormatterGenerate,
	  align:'center',
	  text: 'Report',
	  classes: 'align-middle',
	  headerStyle: {
   	  fontSize:'20px',
   	  color:'#fff',
   	  backgroundColor: '#1B7097'
  }
	},
	];

	const options = {
	  	totalSize: emp_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: emp_list.length
		}] 
	};

	return(
		<Fragment>
			{
				isLoading				
				?
				<div className="container loader m-auto">					
				{
				    <center className="mt-5 pt-5 " id="loader_position">
					     <FadeLoader
     			          css=""
     			          height={15}
     			          width={5}
     			          radius={2}
     			          margin={2}
     			          color={"black"}
     			          loading={isLoading}    			         
     			        />
     			    </center>
     			}				
				</div>
				:
				<div className='container-fluid'>
					<div className='row'>					
						<div className="col-md-12 mt-5 col-sm-12 col-12">
							<div className="row ">
								<div className="col-lg-12">
									<div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
										<BootstrapTable
											keyField="id"
											data={ emp_list }
											columns={ columns }
											headerClasses="event-head"
											pagination={ paginationFactory(options) }
											bordered={ false }									
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<br/>
						<br/>
						<hr className="my-5"/>
						<Footer/>
					</div>
				</div>
			}
			
		</Fragment>
	)
}

export default Emp_Report

							