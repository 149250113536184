import React,{useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
/*import './App.css';*/
import Emp_Dashboard from './emp_components/Emp_Dashboard'
import Emp_Logout from './emp_components/Emp_Logout'
/*import Employee from './components/Employee'
import Add_Employee from './components/Add_Employee'*/
import Change_Password from './components/Change_Password'
/*import Logout from './components/Logout'
*/import nav_logo from './images/nav_logo.jpeg'
import {Helmet} from 'react-helmet'
import Summary from "./Summary"
import FilterImg from './components/FilterImg'
import Screenshot_summary from './Screenshot_summary'
import Task_list from "./emp_components/Task_list"
import Project_list from "./emp_components/Project_list"


/*
import Settings from './components/Settings'
import Download from "./components/Download"
import Change from "./components/Change"*/


function EmpApp(props) {
 /* useEffect(() => {
   console.log("Emp useeffect",props.detail) 
  })*/
 
  return (
    <div className="EmpApp">
    
      <Router >
        <div >
        <Helmet>
          <meta name="description" content = 'wecome to my web app'/>
          <link rel="shortcut icon" href={nav_logo} /> 
        </Helmet>
          <nav id="navigation-bar" className="navbar navbar-expand-lg navbar-dark nav-bg-color " >
           <a className="navbar-brand" href="/"><img className="logo_attribute" src={nav_logo} alt="not found" />  </a>
           
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className='nav-item'><Link to={'/'} className="nav-link"> Home </Link></li>
                   <li className='nav-item'><Link to={'/Project_list'} className="nav-link">Projects</Link></li>
                  <li className="nav-item dropdown ">
                     <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       Profile
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left:'-92px'}}>
                       <p className='dropdown-item pb-0 mb-0 '> {localStorage.Name}</p>
                       <hr className="m-0"/>
                        {/*<Link to={'/settings'}><p className="dropdown-item pb-0 mb-0" >Settings</p></Link>
                        <hr className="m-0"/>*/}
                        <Link to={'/summary'}><p className="dropdown-item pb-0 mb-0" >Summary</p></Link>
                        <hr className="m-0"/>
                        <Link to={'/Change_Password'}><p className="dropdown-item pb-0 mb-0" >Change Password</p></Link>
                         <hr className="m-0"/>
                       <Link to={'/emp_logout'}><p className='dropdown-item pb-0 mb-0 '> Logout</p></Link>                   
                      </div>
                  </li>
               </ul>
              </div>
          </nav>
          <br/>
          <Switch>
             <Route exact path='/' render={(props) => (<Emp_Dashboard detail={props.detail} {...props}/>)} /> 
             <Route path='/emp_logout'  component={Emp_Logout}/> 
             <Route path='/Change_Password' component={Change_Password} />   
             <Route path='/summary' component={Summary} /> 
              <Route path='/FilterImg' component={FilterImg} /> 
              <Route path='/showscreenshot' component={Screenshot_summary} /> 
              <Route path='/Task_list' component={Task_list}/>
              <Route path='/Project_list' component={Project_list}/>      

             {/*<Route  path='/add_employee' component={Add_Employee} />
             <Route  path='/employee' component={Employee}/>
             
             <Route path='/FilterImg' component={FilterImg} />
             <Route path='/settings' component={Settings} />
              <Route path='./change' componentrender={(props) => (<Home test="hi" {...props}/>)} {Change} />
              */}
          </Switch>
        </div>
      </Router>
    
    </div>
  );
}

export default EmpApp;
