import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import signup_logo from "../images/signup_logo.png"
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

const Comp_Dashboard=()=> {
	const history = useHistory()
	const [emp_list, setUserdetail] = useState([])
	const [active_count,setCount]=useState([])
	const [isLoading, setLoading] = useState(true)
	const [total_act_today,setTotalAT]=useState('')
	/*const [Today_Total_work,setTotalWT]=useState('')*/
	/*const [Yesterday_Total_Work,setTotalWY]=useState('')
	const [This_month_Total_Work,setTotalWTM]=useState('')*/
	/*const [This_week_Total_Work,setTotalWTW]=useState('')*/

	useEffect(()=>{
		/*console.log("Com[p dashbpoard",localStorage.company_id)*/


		let count=[]
		fetch(localStorage.ip_address+'/users_list/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"company_id":localStorage.company_id
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				console.log("Users _list",resJson)
				setUserdetail(resJson)
				/*console.log(resJson)*/
				setLoading(false)
				count=resJson.filter(data=>data.Online==true);
		  		setCount(count)
			}).catch((error)=> {
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
			})
			
		fetch(localStorage.ip_address+'/today_login_count/',{
            method: 'post',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            body: JSON.stringify({
                       "company_id":localStorage.company_id,
                   })
            })
		  .then(response => response.json())
		  .then((jsonResponse) => {		 
		  		setTotalAT(jsonResponse.Today_Login)
		  		/*setTotalWT(jsonResponse.Today_Total_work)*/
		  		/*setTotalWY(jsonResponse.Yesterday_Total_Work)
		  		setTotalWTM(jsonResponse.This_month_Total_Work)*/
		  		/*setTotalWTW(jsonResponse.This_week_Total_Work)*/
		  	/*console.log(jsonResponse)*/
		  }).catch((error) => {
		  	Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
	  	     /*console.log('not data found')*/
	  	   });
	},[])


	 const detail=(id)=>{
	 	fetch(localStorage.ip_address+'/user_detail/'+id+'/',{
	 		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
	 	})
		.then(response => response.json())
		.then((jsonResponse) => {
		  	/*console.log("User Details",jsonResponse)*/
		  	if(jsonResponse.Images.length){	
		  		localStorage.setItem('refitem',1)
		  		history.push({pathname: '/emp_screenshots',state: { User_id:id }})
		  	}
		  	else{
		  		Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'No screenshot of this employee is available Now',
					icon: 'info',
					showConfirmButton: false,
				})
		  	}
		}).catch(error=>{
		  		console.log(error)
		})	
	}


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);

	const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.employee_name}</a>
	  	);
	}

	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.No}</a>
			)
	}

	const actionFormatterTime=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.Time.Today}</a>
			)
	}

	const actionFormatterYesterday=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.Time.Yestarday}</a>
			)
	}

	const actionFormatterWeek=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.Time.This_Week}</a>
			)
	}

	const actionFormatterMonth=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" href="#" onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.Time.This_Month}</a>
			)
	}

	const actionFormatterShow=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font point"  onClick={()=>{showtask(cell.id)}} style={{color:'#206090'}} value={ cell.id } >Show</a>
			)
	}

	const showtask=(id)=>{
		/*alert(id)*/
		localStorage.setItem("comp_emp_Tid",id)
		history.push("/Assignproject_list")
	}

	const handleImage=(e, cell, row, rowIndex)=>{
		return (
			cell.images.length
			?
	     	<img  className="dashboard_img" onClick={()=>{detail(cell.id)}} value={cell.id} width='170' height='96' alt="Not available" src={cell.images[0]} />
		  	:
	     	<img loading="lazy" onClick={()=>{detail(cell.id)}} value={cell.id}  className="dashboard_img text-center" width='109' height='75' alt="Not available" src={signup_logo} />
	  	);
	}

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'left',
	  align:'left',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'username',
	  text: 'Employee',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: actionFormatter,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}, {
	  dataField: 'images',
	  text:'LastActive',
	  headerAlign: 'center',
	  formatter: handleImage,
	  align:'center',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}, {
	  dataField: 'Time.Today',
	  headerAlign: 'center',
	  formatter: actionFormatterTime,
	  align:'center',
	  text: 'Today',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, {
	  dataField: 'Time.Yestarday',
	  headerAlign: 'center',
	  align:'center',
	  formatter: actionFormatterYesterday,
	  text: 'Yesterday',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, {
	  dataField: 'Time.This_Week',
	  headerAlign: 'center',
	  formatter: actionFormatterWeek,
	  align:'center',
	  text: 'Week',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, {
	  dataField: 'Time.This_Month',
	  headerAlign: 'center',
	  align:'center',
	  formatter: actionFormatterMonth,
	  text: 'Month',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, {
	  dataField: '',
	  headerAlign: 'center',
	  align:'center',
	  formatter: actionFormatterShow,
	  text: 'Task ',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}
	];

	const sizePerPageOptionRenderer = ({
	  text,
	  page,
	  onSizePerPageChange
	}) => (
	  <li
	    key={ text }
	    role="presentation"
	    className="dropdown-item"
	  >
	    <a
	      href="#"
	      tabIndex="-1"
	      role="menuitem"
	      data-page={ page }
	      onMouseDown={ (e) => {
	        e.preventDefault();
	        onSizePerPageChange(page);
	      } }
	      style={ { color: 'rgb(33, 92, 142)!important' } }
	    >
	      { text }
	    </a>
	  </li>
	);

	const options = {
	  	totalSize: emp_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{		
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: emp_list.length
		}]  
	};

	const handleReport = (id) =>{
		fetch(localStorage.ip_address+'/delete_employee/' + id +'/',{
            method: 'POST',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            body: JSON.stringify({
                       "id": id,
                   })
            })
		  .then(response => response.json())
		  .then((jsonResponse) => {	
		  		let count=[]	 
		  		console.log(jsonResponse)
		  		if('message' in jsonResponse){
			  		fetch(localStorage.ip_address+'/users_list/', {
					method: 'POST',
					headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"company_id":localStorage.company_id
						   })
					})
					.then((response)=> response.json())
					.then((resJson)=>{
						console.log("Users _list",resJson)
						setUserdetail(resJson)
						/*console.log(resJson)*/
						setLoading(false)
						count=resJson.filter(data=>data.Online==true);
				  		setCount(count)
					}).catch((error)=> {
						setLoading(false)
						Swal.fire({
							position: 'top-end',
				  			timer: 2150,
							text: 'Error',
							icon: 'error',
							showConfirmButton: false,
						})
					})
				}
		  }).catch((error) => {
		  	Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
	  	     /*console.log('not data found')*/
	  	   });
	}

	return(
			<Fragment>
			   	{
			   isLoading				
			   ?
			   <div className="container loader m-auto">
			      {
			      <center className="mt-5 pt-5 " id="loader_position">
			         <FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      </center>
			      }				
			   </div>
			   :
			   <div className='container-fluid'>
			      <div className='row'>
			         <div className="col-12">
			            <a className="float-right nav-link mb-2 text-primary" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={()=>{history.push('/invitation_comp')}}>
			        		<span className="text-primary"><i class="fa fa-paper-plane mt-2" aria-hidden="true"></i></span>
			        			Send Invitation
			        	</a>
			         </div>
			         <br/>
			         <br/>
			         <div id="online_card" className="card px-0 pt-0 col-md-3 mt-0 " style={{maxHeight:'700px'}}>
			            <h6 className='p-3' style={{fontSize: '15px'}}> <span className="text-success ">{active_count.length}</span> <span className='text-muted text-small'>Active Now</span> <span className='text-danger'>{total_act_today}</span> <span className='text-muted'>Total Worked </span></h6>
			               <div className="scrollable" style={{maxHeight:'400px',overflowY:'auto'}}>
			                  <div>
			                     <br/>
			                     <div className="card-text pl-3 pr-3">
			                        {
			                        emp_list.map((data)=>(
			                        <div className="mb-3 py-2 light_bg online_list" key={data.username}>
			                           {
			                           data.timer_status =='running'
			                           ? 
			                           <span className='text-success col-3' style={{filter: "contrast(0) sepia(1) saturate(100) hue-rotate(133deg)" }} >&#127761;</span>
			                           :
			                           data.timer_status=='paused'
			                           ?
			                            <span className='col-3' style={{color:"	rgb(255,255,0)"}} >&#127761;</span>
			                           :
			                           <span className='text-danger col-3' style={{filter: "sepia(1) saturate(100)"}} >&#127761;</span>
			                           }
			                           <span className="point p-2" onClick={()=>{detail(data.id)}} >{data.employee_name}</span>
			                        	<button onClick={()=> handleReport(data.id) } class="btn text-primary float-right"><i class="fa fa-close"></i></button>
			                        </div>
			                        ))
			                        }
			                     </div>
			                  </div>
			                  <p className='text-right text-muted mr-4'>{active_count.length}/{emp_list.length}</p>
			               </div>
			               <br/>
			               <hr className="mb-0"/>
			            </div>
			            <div className="col-md-9 col-sm-12 col-12">
			               <div className="row ">
			                  <div className="col-lg-12">
			                     <div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
			                     {/*<BootstrapTable
			                     keyField="id"
			                     data={ emp_list }
			                     columns={ columns }
			                     headerClasses="event-head"
			                     pagination={ paginationFactory(options) }
			                     bordered={ false }									
			                     />*/}
			                     <ToolkitProvider
						          	keyField="id"
				                    data={ emp_list }
				                    columns={ columns }
				                    headerClasses="event-head"
				                    bordered={ false }	
						          	search
						        >
						          {
						            toolkitprops => (
						              <div>
						                <SearchBar { ...toolkitprops.searchProps } />
						                <BootstrapTable
						                	bordered={ false }
						                	pagination={ paginationFactory(options) }
						                  { ...toolkitprops.baseProps }
						                />
						              </div>
						            )
						          }
						        </ToolkitProvider>
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			      
			      <hr className="my-5"/>
			  	 <Footer/>
			   </div>
			   }
			   <br/>
			   <br/>
			</Fragment>
			)
		}
		
export default Comp_Dashboard;						
