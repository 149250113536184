import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Register_emp from './Register_emp'
import {Helmet} from 'react-helmet'



function Register_employee() {	
  return (
    <div className="App">
      <Router >
          <div >
            <Helmet>
              <meta name="description" content = 'wecome to my web app'/> 
            </Helmet>           
          <br/>
          <Switch>          
             <Route exact path={'/'+localStorage.id_href} component={Register_emp} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default Register_employee;
