import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Reset_pass from './Reset_pass'
import {Helmet} from 'react-helmet'



function Reset_password(props) {	
	 	/*console.log("alert",props.reset_id,props.token)*/
  return (

    <div className="App">
      <Router >
          <div >
            <Helmet>
              <meta name="description" content = 'wecome to my web app'/> 
            </Helmet>           
          <br/>
          <Switch>          
             <Route exact path={'/'+'change_password/'+localStorage.link1+'/'+localStorage.link2+'/'} component={Reset_pass} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}



export default Reset_password