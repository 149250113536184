import React from 'react'
import Home2 from '../Home2'
import ReactDOM from 'react-dom'
import * as Ip_address from "../Ip_address"

export class Logout extends React.Component {
	componentDidMount(){
		this.props.history.push('')	
		localStorage.clear()
		/*Change ip address in src folder having Ip_address.js file only*/
		localStorage.setItem('ip_address',Ip_address.ip_address)
		localStorage.setItem('show','login')										
		ReactDOM.render(<Home2 />,document.getElementById('root'));
	}
	
	render() {
		return (
			<div>
				
			</div>
		)
	}
}

export default Logout

	
