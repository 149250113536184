import React, { useState, useEffect, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"
import moment from 'moment'

const AssignTask_list=()=> {
	const history = useHistory()
	const [emp_list, setUserdetail] = useState([])
	const [isLoading, setLoading] = useState(true)
	
	useEffect(()=>{	
		fetch(localStorage.ip_address+'/employee_task_list/'+localStorage.comp_emp_Tid+'/'+localStorage.project_id+'/', {
			method: 'GET',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				/*console.log("Users _list",resJson)*/
				setUserdetail(resJson.task_list)
				setLoading(false)
			}).catch((error)=> {
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
			})
	},[])

	 const detail=(id)=>{
	 		
	}

	const Show_task=(task_id)=>{
		const id=task_id
		localStorage.setItem("p_task_id",id)
		history.push({pathname: '/Company_tasks'})		
	}

	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);

	const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font"  onClick={()=>{detail(cell.Task_id)}} style={{color:'#206090'}} value={ cell.Task_id } >{cell.Task_Title}</a>
	  	);
	}

	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  onClick={()=>{detail(cell.Task_id)}} style={{color:'#206090'}} value={ cell.Task_id } >{cell.No}</a>
			)
	}

	const actionFormatterDate=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  
					onClick={()=>{detail(cell.Task_id)}}
				 	style={{color:'#206090'}} value={ cell.Task_id } >
				 	{moment(cell.created_at).format("MMM Do YYYY")}
				 </a>
			)
	}

	const actionFormatterTask=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  onClick={()=>{Show_task(cell.Task_id)}} style={{color:'#206090', cursor: 'pointer'}} value={ cell.Task_id } >View</a>
			)
	}

	const actionFormatterStatus=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" 
				 onClick={()=>{detail(cell.Task_id)}} 
				style={{color:'#206090'}} value={ cell.Task_id } >
				{cell.Completed ? <p className="mb-0">Complete</p>:<p className="mb-0">Incomplete</p>}</a>
			)
	}

	const actionFormatterTime=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  onClick={()=>{detail(cell.id)}} style={{color:'#206090'}} value={ cell.id } >{cell.Time}</a>
			)
	}

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Task_Title',
	  text: 'Task',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: actionFormatter,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},
	{ 
	  dataField: 'Time',
	  text: 'Working Hours',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: actionFormatterTime,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}, 
	{
	  dataField: 'created_at',
	  headerAlign: 'center',
	  formatter: actionFormatterDate,
	  align:'center',
	  text: 'Date',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	},
	{
	  dataField: 'Completed',
	  headerAlign: 'center',
	  formatter: actionFormatterStatus,
	  align:'center',
	  text: 'Status',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}
	
	];

	const options = {
	  	totalSize: emp_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: emp_list.length
		}] 
	};

	return(
			<Fragment>
			   	{
			   isLoading				
			   ?
			   <div className="container loader m-auto">
			      {
			      <center className="mt-5 pt-5 " id="loader_position">
			         <FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      </center>
			      }				
			   </div>
			   :
			   <div className='container-fluid'>
			      <div className='row'>
			         <div className="col-12">
			            <a className="float-right nav-link text-primary mb-2" 
			             ></a>
			         </div>
			         <br/>
			         <br/>
			            <div className="col-md-12 col-sm-12 col-12">
			               <div className="row ">
			                  <div className="col-lg-12">
			                     <div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
			                     <BootstrapTable
			                     keyField="id"
			                     data={ emp_list }
			                     columns={ columns }
			                     headerClasses="event-head"
			                     pagination={ paginationFactory(options) }
			                     bordered={ false }									
			                     />
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			      <hr className="my-5"/>
			  	 <Footer/>
			   </div>
			   }
			   <br/>
			   <br/>
			</Fragment>
			)
		}

export default AssignTask_list;

							