import React,{useState,useEffect,Fragment} from 'react'
import "../css/common.css"
import * as $ from 'jquery';
import Swal from "sweetalert2"
const Settings=()=> {

	const [Time,setTime]=useState('')
	const [Newtime,setNewtime]=useState('')
	const[ip_address]=useState(localStorage.ip_address)

	useEffect(() => {
		fetch(ip_address+'/set_shot_time/')
		.then(res=>res.json())
		.then(resJson=>{
			resJson ? setTime(resJson.screenshot_time):console.log("Error in getting Time")					
		}).catch(err=> {
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Error',
				icon: 'error',
				showConfirmButton: false,
			})
			console.log(err)})
	},[])
	

	const onChange = event => setNewtime({[event.target.name]:event.target.value});
	const handleSubmit=(e)=>{
		e.preventDefault()
		fetch(ip_address+'/set_shot_time/', {
		  	   method: 'post',
		  	   headers: {'Content-Type':'application/json'},
		  	   body: JSON.stringify({
		  	   	   	"shot_time":Newtime
	  	   	   })
	  	  }).then((response) => response.json())
	  	   .then((resJson)=>{
	  	   /*	console.log(resJson)*/
	  	   	document.getElementById('time').innerHTML=resJson.screenshot_time
	  	   	$("#exampleModalCenter .close-btn").click()
	  	   	setNewtime('')
	  	   }).catch((err)=>{
	  	   	console.log("errr",err)
	  	   })
	}

	
	return (
		<Fragment>
			<div className='container'>
				<div className="card col-md-7">
					<h3 className="card-title text-color text-center">Change Screenshot Time</h3>
					<hr/>
					  <div className="card-body text-center">
				   		<h2 className='text-center'><small>Current Time is:</small><span id="time">{Time}</span>min</h2>
				   		<button className='btn btn-sm btn-primary' type="button" data-toggle="modal" data-target="#exampleModalCenter">
						  Change Time
						</button>
				   	 	<hr className="w-50" />					    
					  </div>
				</div>
				
				<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
					    <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title font-weight-bold" id="exampleModalLongTitle">Change Time</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								  <span aria-hidden="true">&times;</span>
								</button>
							</div>
						    <div className="modal-body ">
						       	<div className="modal-content " >
								    <div className="card-body m-auto">
								      	<form className='form' onSubmit={handleSubmit}>
								      		<div className='form-group m-auto'>
								      			<input className='m-auto' value={Newtime} id="Newtime" maxLength="2" placeholder='Enter Time in minute' type='text' name='Newtime' onChange={e=>{setNewtime(e.target.value)}}/>
								      			<button className='btn set-btn btn-sm ml-2 btn-outline-primary'>Submit</button>
								      		</div>
								      	</form>
								    </div>
		 						</div>
						    </div>
						   	<div className="modal-footer">
						        <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">Close</button>
						    </div>
					    </div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default Settings
					  

