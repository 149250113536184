import React, { Component, Fragment,useState,useEffect } from 'react';
import ReactDom from 'react-dom';
import '../css/Add_Employee.css';
import history from '../history';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Footer from '../Footer';

function Employee_list1() {
	const history = useHistory()
	const [password1, setPass1] = useState('')
	const [password2,setPass2]=useState('')
	const [csrftoken,setCsrf]=useState('')
	const [email,setEmail]=useState('')
	const [total_registered,setReg]=useState([])
	const [data, setData] = useState('')

	useEffect(() => {
		/*List of employee, whom invitation have been sent*/
	   fetch(localStorage.ip_address+'/invitation_list/', {
			method: 'POST',
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
			   	"company_id": localStorage.company_id
			   })
			})
	   .then(res=>res.json())
	   .then((resJson)=>{
	   /*	console.log(resJson)*/
	   	setReg(resJson)	   		
	   	})
		
	}, [])

	const handleSubmit=(event)=>{ 
		event.preventDefault();
		/*Send Invitation api*/
		fetch(localStorage.ip_address+'/send_invite/', {
		method: 'post',
		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
		body: JSON.stringify({
		   	"email": email
		   })
		}).then((response) => response.json())
		.then((responseJson) => {
			/*console.log("response of register type",responseJson)*/
			if(responseJson.message=="Invite send"){
				Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Invite send to ' +email,
					icon: 'success',
					showConfirmButton: false,
				})
				setEmail({email: ''})
			}
			else if(responseJson.message=="Already Invited")	
			{
				Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Already Invited',
					icon: 'success',
					showConfirmButton: false,
				})
				setEmail('')
			}				   
		})
		.catch((error) => {
		 /*alert("Invalid login detail");*/
			 Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Invalid login detail',
				icon: 'error',
				showConfirmButton: false,
			})
		 console.log(error)
		});
 	}


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);


	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  style={{color:'#206090'}} value={ cell.id } >{cell.no}</a>
			)
	}

	const actionFormatterEmail=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"   style={{color:'#206090'}} value={ cell.id } >{cell.email}</a>
			)
	}

	const actionFormatterDate=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"   style={{color:'#206090'}} value={ cell.id } >{moment(cell.send_at).format("Do MMM YYYY")}</a>
			)
	}

	 const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font"  style={{color:'#206090'}}  value={ cell.id } >{cell.is_active?"True":"Pending"}</a>
	  	);
	}

	 const columns = [{
	  dataField: 'no',
	  text: 'S.No.',
	  formatter: actionFormatterSn,
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle',
	  headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'email',
	  text: 'Email',
	  formatter:actionFormatterEmail,
	  headerAlign: 'center'	,
	  align:'center',
	  classes: 'align-middle',
	  headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}, {
	  dataField: 'send_at',
	  formatter:actionFormatterDate,
	  text:'Date',
	  headerAlign: 'center',
	  align:'center',
	  headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'is_active',
	  text:'Active',
	  formatter: actionFormatter,
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle',
	  headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}
	 
	];

	const options = {
	  	totalSize: total_registered.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: total_registered.length
		}] 
	};

	const onChange = (e) =>{
		if(e.target.value===''){
			fetch(localStorage.ip_address+'/invitation_list/', {
			method: 'POST',
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
			   	"company_id": localStorage.company_id
			   })
			})
		   .then(res=>res.json())
		   .then((resJson)=>{
		   /*	console.log(resJson)*/
		   	setReg(resJson)	   		
		   	})
		}
	}

	const HandleKeyDown = (e) =>{
	    if (e.key === 'Enter') {
	    	const headers = {
	    		headers:
	    		 {"token":localStorage.token,
	    		 "username":localStorage.username,
	    		 'Content-Type':'application/json'}
	    	}
		    const data = {'data': e.target.value}
		    axios.post(localStorage.ip_address+"/filter_invited_employee/", data, headers)
		    .then(response=>{
		      	setReg(response.data)
		    }).catch(error=> console.log(error))
	    }
	}

	return (
		<div>
			<div className="container-fluid">
				<div className="row reverse">
					<div className="col-lg-2 mb-1">
			        	<a className="float-right nav-link mb-2 text-primary" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={()=>{history.push('/invitation_comp')}}>
			        		<span className="text-primary"><i class="fa fa-paper-plane mt-2" aria-hidden="true"></i></span>
			        		Send Invitation
			        	</a>
			        </div>
					<div className="col-lg-2 search">
			            <div className="input-group">
			            	<div className="input-group-prepend">
			            		<span className="input-group-text border-left-0 border-right-0 border-top-0 search-icon mt-2"><i className="fa fa-search"></i></span>
			            	</div>
			            	<input 
			            	 className="form-control search-btn border-left-0 border-right-0 border-top-0" 
			            	 placeholder="Search"
			            	 data-tip="Search by email, date(format YYYY-mm-dd) and active status(True/Pending)" 
			            	 data-place="bottom"
			            	 onChange={onChange} onKeyDown={HandleKeyDown}/>
			            	<ReactTooltip />
			            </div>
			        </div>
					<br/>
					<br/>
					{total_registered.length > 0?
						<div className="col-lg-12">
							<div className="table-responsive col-lg-12" style={{maxHeight:'700px'}}>
								<BootstrapTable
									keyField="no"
									data={ total_registered }
									columns={ columns }
									headerClasses="event-head"
									pagination={ paginationFactory(options) }
									bordered={ false }									
								/>
							</div>
						</div>
						:<h3 className="text-center text-primary" style={{marginTop: '120px'}}>You are not send any invite.</h3>}
				</div>
			</div>
			<hr className="my-5"/>
			<Footer/>
		</div>
	)
}

export default Employee_list1
