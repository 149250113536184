import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Comp_Dashboard from './company_components/Comp_Dashboard'
import Logout from './components/Logout'
import Change_Password from './components/Change_Password'
import nav_logo from './images/nav_logo.jpeg'
import {Helmet} from 'react-helmet'
import Emp_screenshots from './company_components/Emp_screenshots'
import Invite from "./company_components/Invite"
import Employee_list1 from './company_components/Employee_list1'
import FilterImg from './components/FilterImg'
import Set_time from './company_components/Set_time'
import Emp_Report from './company_components/Emp_Report'
import Summary from "./Summary"
import Screenshot_summary from './Screenshot_summary'
import Company_projects from './company_components/Company_projects'
import Company_tasks from "./company_components/Company_tasks"
import Assignproject_list from "./company_components/Assignproject_list"
import AssignTask_list from "./company_components/AssignTask_list";
import WeeklyReport from "./company_components/WeeklyReport";
import EmployeeArchive from "./company_components/EmployeeArchive";

function EmpApp(props) {
	return (
		 <div className="EmpApp">
			 <Router >
					<div >
						 <Helmet>
								<meta name="description" content = 'wecome to my web app'/>
								<link rel="shortcut icon" href={nav_logo} />
						 </Helmet>
						 <nav id="navigation-bar" className="navbar navbar-expand-lg navbar-dark nav-bg-color" >
								<a className="navbar-brand" href="/">
									 <img className="logo_attribute" src={nav_logo} alt="not found" />  
								 </a>         
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"></span>
								</button>
								<div className="collapse navbar-collapse " id="navbarSupportedContent">
									 <ul className="navbar-nav ml-auto">
											<li className='nav-item '>
												 <Link to={'/'} className="nav-link text-white">
												 Home </Link>
											</li>
											<li className='nav-item'>
												 <Link to={'/Employee_list1'} className="nav-link text-white">
												 Employee </Link>
											</li>
											<li className='nav-item'>
												 <Link to={'/Company_projects'} className="nav-link text-white">
												 Projects </Link>
											</li>
											<li className='nav-item'>
												 <Link to={'/report'} className="nav-link text-white">
												 Report </Link>
											</li>
											<li className="nav-item dropdown ">
												 <a className="nav-link text-white dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												 Profile
												 </a>
												 <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left:'-96px'}}>
													<p className='dropdown-item pb-0 mb-0 text-primary text-capitalize'>{localStorage.Name}</p>
													<hr className="m-0"/>
													<Link to={'/employee-archive'}>
													<p className="dropdown-item pb-0 mb-0" >Empoloyee Archive List</p>
													</Link>
													<Link to={'/invitation_comp'}>
													<p className="dropdown-item pb-0 mb-0" >Send Invitation</p>
													</Link>
													<hr className="m-0"/>
													<Link to={'/set_time'}>
													<p className="dropdown-item pb-0 mb-0" >Set Time</p>
													</Link>
													<hr className="m-0"/>
													<Link to={'/Change_Password'}>
													<p className="dropdown-item pb-0 mb-0" >Change Password</p>
													</Link>
													<Link to={'/comp_logout'}>
													<p className='dropdown-item pb-0 mb-0'> Logout</p>
													</Link> 
												 </div>
											</li>
									 	</ul>
									</div>
							 </nav>
						 <br/>
						 <Switch>
								<Route exact path='/' component={Comp_Dashboard}  />
								<Route path='/comp_logout'  component={Logout}/>
								<Route path='/Change_Password' component={Change_Password} />
								<Route path='/invitation_comp' component={Invite}/>
								<Route path='/Company_projects' component={Company_projects} />
								<Route path="/Company_tasks" component={Company_tasks} />
								<Route path='/Employee_list1' component={Employee_list1} />
								<Route path='/emp_screenshots' component={Emp_screenshots} />
								<Route path='/FilterImg' component={FilterImg} />
								<Route path='/set_time' component={Set_time} />
								<Route path='/report' component={Emp_Report} />
								<Route path='/summary' component={Summary} />
								<Route path='/showscreenshot' component={Screenshot_summary} />
								<Route path="/Assignproject_list" component={Assignproject_list}/>
								<Route path="/AssignTask_list" component={AssignTask_list}/>
								<Route path="/weekly-report" component={WeeklyReport}/>
								<Route path="/employee-archive" component={EmployeeArchive}/>
						 </Switch>
					</div>
			 </Router>
		</div>
	);
}

export default EmpApp;
