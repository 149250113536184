import React,{Fragment,useEffect,useState} from 'react'
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from 'react-router-dom';

	


function Company_list() {
	const [company_list ,setList]=useState([])
	const history = useHistory()
	useEffect(() => {
		fetch(localStorage.ip_address+"/all_company_details/")
		.then(res=>res.json())
		.then(resJson=>{
			/*console.log("company list",resJson)*/
			setList(resJson.company_details)
		}).catch(err=>console.log(err))
	}, [])
	/*console.log("list of company",company_list)*/

	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	)

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'center',
	  align:'center',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#215C8E'
  }
	},{ 
	  dataField: 'company_name',
	  text: 'Name',
	  
	  headerAlign: 'center'	,
	  align:'center',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#215C8E'
  }
	}, {
	  dataField: 'contact_number',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Contact.No.',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#215C8E'
  }
	}, {
	  dataField: 'email',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Mail',
	  headerStyle: {
   	fontSize:'20px',
   	color:'#fff',
   	backgroundColor: '#215C8E'
  }
	}];

	const options = {
	  	totalSize: company_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '10', value: 10
		}, {
		text: '20', value: 20
		}, {
		text: 'All', value: company_list.length
		}] 
	};


	return (
		<Fragment>
		<div className="container-fluid">
			<div className="col-sm-12 col-12">
				<div className="row ">
				<div className="col-12">
						<a className="float-right nav-link text-primary" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={()=>{history.push('/invitation')}}>Send invitation</a>
					</div>
					<br/>
					<br/>
					<div className="col-lg-12">
					<div className="col-lg-12 table-responsive" style={{maxHeight:'700px'}}>
							<BootstrapTable
								keyField="id"
								data={ company_list }
								columns={ columns }
								headerClasses="event-head"
								pagination={ paginationFactory(options) }
								bordered={ false }									
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		</Fragment>
	)
}

export default Company_list