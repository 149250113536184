import React, { Component,Fragment } from 'react';
import ReactDom from 'react-dom'
import Home2 from './Home2';
import history from './history'
import { BrowserRouter as Router} from 'react-router-dom';
import Swal from 'sweetalert2'
import FadeLoader from "react-spinners/FadeLoader";

import {
  Modal, 
  ModalHeader, 
  ModalBody, 

} from "reactstrap";

class Footer extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			 name:'',
			 email:'',
			 contact_number:'',
			 message:'',
			 csrftoken:'',
			 loader:false,
			 modal: false,
			 feedback:'',
			 modal_f:false
		}
		this.Contact_us=this.Contact_us.bind(this)
		this.toggle=this.toggle.bind(this)
		this.submitFeedback=this.submitFeedback.bind(this)
		this.toggle_f=this.toggle_f.bind(this)
	}

	onChange=(event)=>{this.setState({[event.target.name]:[event.target.value]})}

	/*Contact us modal toggling*/
	 toggle = () =>{

	  this.setState({modal:!(this.state.modal)})
	}

	/*Submit contact us form*/
	Contact_us =(event)=>{
		event.preventDefault()	
			this.setState({loader:true})
			if (this.state.contact_number[0].match( 
	                    /[0-9]/g) && this.state.contact_number[0].length == 10) 
	            {
					fetch(localStorage.ip_address+'/contact_us/', {
					method: 'post',
					headers: {"X-CSRFToken": this.state.csrftoken,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"name": this.state.name[0],
						    "contact_number": this.state.contact_number[0],
						    "email":this.state.email[0],
						    "message":this.state.message[0]
						    
						   })
					}).then((response) => response.json())
					.then((responseJson) => {
						if(responseJson.message=="Message send Successfully"){
							
							this.setState({
								name:'',
								 email:'',
								 contact_number:'',
								 message:'',
								 loader:false,phone_no:''
							})
							this.toggle()
							Swal.fire({
								position: 'top-end',
					  			timer: 2000,
								text: "Send Successfully",
								icon: 'success',
								showConfirmButton: false,
							})
						}
										   
					})
					.catch((error) => {
					this.setState({loader:false})
					this.toggle()
					 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Error In sending detail",
						icon: 'error',
						showConfirmButton: false,
					})
					 console.log(error)
					});
				}
				else{
				this.setState({loader:false,phone_no:''})
					this.toggle()
					this.setState({
								 contact_number:'',
							})

					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Please Enter Phone Number carefully",
						icon: 'warning',
						showConfirmButton: false,
					})
					
				}
	}

	/*Navigate to ./Home2.js component*/
	Navigate=(val)=>{
		if(val=="login_register"){
			localStorage.setItem('show','login')
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			);
		}
		else if(val=="download"){
			localStorage.setItem('show','download')
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			);
		}
		else if(val=="register"){
			localStorage.setItem('show','register_company')
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			 );
		}
	}

	/*Submit feedback form*/
	submitFeedback =(event)=>{
		event.preventDefault()
		fetch(localStorage.ip_address+'/submit_feedback/', {
					method: 'post',
					headers: {"X-CSRFToken": this.state.csrftoken,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"name": this.state.name[0],
						    "email":this.state.email[0],
						    "message":this.state.feedback[0]
						    
						   })
					}).then((response) => response.json())
					.then((responseJson) => {
						/*console.log(responseJson)*/
						if(responseJson.message=="Feedback send successfully"){
							
							this.setState({
								name:'',
								 email:'',
								 contact_number:'',
								 message:'',
								 loader:false
							})
							this.toggle_f()
							Swal.fire({
								position: 'top-end',
					  			timer: 2000,
								text: "Send Successfully",
								icon: 'success',
								showConfirmButton: false,
							})
						}
										   
					})
					.catch((error) => {
					this.setState({loader:false})
					this.toggle_f()
					 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Error In sending detail",
						icon: 'error',
						showConfirmButton: false,
					})
					 console.log(error)
					});
	}

	/*Feed Back modal Toggling*/
	toggle_f = () =>{

	  this.setState({modal_f:!(this.state.modal_f)})
	}

	render() {
		return (
			<Fragment>
			{
				this.state.loader==true
		      		?
	      				<div className="container loader m-auto">					
					     	{
					     	<center className="mt-5 pt-5 " id="loader_position">
						     	<FadeLoader
		     			          css=""
		     			          height={15}
		     			          width={5}
		     			          radius={2}
		     			          margin={2}
		     			          color={"black"}
		     			          loading={this.state.loader}    			         
		     			        />
	     			        </center>}
						</div> 
	    	        :
	    	    <Fragment>
	    	       { /*Contact Us Modal*/}
					<Modal isOpen={this.state.modal} fade={false} toggle={this.toggle}>
						<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.toggle} className="bg-color-grad">
				          <span className="m-auto text-color-gradient font-weight-bold">Contact Us</span></ModalHeader>					          <ModalBody>
			              <form onSubmit={this.Contact_us}>
						        <div className="modal-body px-5">
						          <div className="form-group">
						            <input type="text" className="form-control" name="name" onChange={this.onChange} value={this.state.name} id="username" placeholder="Enter Full Name" required />
						          </div>
						          <div className="form-group">
						            <input type="email" className="form-control" name="email" onChange={this.onChange} value={this.state.email} id="email"  placeholder="Enter email" required />
						          </div>
						          <div className="form-group">
					            <input type="text" className="form-control" name="contact_number" onChange={this.onChange} value={this.state.contact_number} id="phone_no" maxLength="10" placeholder="Phone Number" required />
						          </div>
						          <div className="form-group">
						          	<textarea className="form-control mt-3" name="message" id='meassage' onChange={this.onChange} value={this.state.message} placeholder="Write..."></textarea>
						          </div>
						        </div>
						        <div className="modal-footer border-top-0 d-flex justify-content-center">
						         <br/>
						          <button type="submit" className="btn btn-primary col-md-4 mt-0">Submit</button>
						        </div>
					      </form>
				        </ModalBody>
				    </Modal>

	    {/*Support And FeedBack model*/}
	    		<Modal size="lg" style={{maxWidth: '1600px', width: '50%'}}
 				className="text-align-center"  className="text-align-center" isOpen={this.state.modal_f} fade={false} toggle={this.toggle_f}>
		          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.toggle_f} className="">
		          <span className="m-auto text-color-gradient font-weight-bold">FEEDBACK</span></ModalHeader>
		          <ModalBody>
		              <form onSubmit={this.submitFeedback}>
					        <div className="modal-body px-5">
					        	<div className="form-group">
					            <input type="text" className="form-control" name="name" onChange={this.onChange} value={this.state.name} id="username" placeholder="Enter Your Name" required />
					          </div>
					          <div className="form-group">
					            <input type="email" className="form-control" name="email" onChange={this.onChange} value={this.state.email} id="email"  placeholder="Enter Your Email Address" required />
					          </div>
					          <div className="form-group">
					          	<textarea className="form-control mt-3" rows={4} name="feedback" id='feedback' onChange={this.onChange} value={this.state.feedback} placeholder="Write your feedback..."></textarea>
					          </div>
					          <button type="submit" className="btn btn-primary col-12  mt-0">Submit</button>
					        </div>
					        <br/><br/>
					    </form>
		          </ModalBody>
		        </Modal>


				<footer className="footer" role="contentinfo">
			    <div className="container">
			      <div className="row">
			        <div className="col-md-4 text-justify mb-4 mb-md-0">
			          <h3>About Trackme</h3>
			          <p>Trackme app is free Desktop application to keep an eye on your employee simply Register your account as company send invitation 
			          to your employee on email,make them register and download the trackme app just few simple steps and you are ready to go. </p>
			          <p className="social">
			            <a href="#"><span className="icofont-twitter fab fa-linkedin-in"></span></a>
			            <a href="#"><span className="icofont-facebook fab fa-facebook"></span></a>
			            <a href="#"><span className="icofont-dribbble fab fa-twitter"></span></a>
			            <a href="#"><span className="icofont-behance fab fa-instagram"></span></a>
			          </p>
			        </div>
			        <div className="col-md-7 ml-auto">
			          <div className="row site-section pt-0">
			            <div className="col-md-4 mb-4 mb-md-0">
			              <h3>Explore</h3>
			              <ul className="list-unstyled">
			                
			                {/*<li><a href="#">About Us</a></li>*/}
			                <li><a href="#" onClick={this.toggle} type="button">Contact Us</a></li>
			              </ul>
			            </div>
			                
			            <div className="col-md-4 mb-4 mb-md-0">
			              <h3>Services</h3>
			              <ul className="list-unstyled">
			                {/*<li><a href="#">Team</a></li>
			                			                <li><a href="#">Collaboration</a></li>
			                			                <li><a href="#">Todos</a></li>*/}
			                <li><a href="#" onClick={this.toggle_f}>Support/FeedBack</a></li>
			              </ul>
			            </div>
			            {/*<div className="col-md-4 mb-4 mb-md-0">
			              <h3>Downloads</h3>
			              <ul className="list-unstyled">
			                <li><a href="#" onClick={()=>{this.Navigate("download")}}>Donload page </a></li>
			                
			              </ul>
			            </div>*/}
			          </div>
			        </div>
			      </div>

			      	<div className="row justify-content-center text-center">
				        <div className="col-md-7">
				          <p className="copyright">&copy; Copyright SoftLand. All Rights Reserved</p>
				          <div className="credits">
				           
				            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
				          </div>
				        </div>
				      </div>
			    </div>
			  </footer>
			  </Fragment>
			}
			
			</Fragment>
		);
	}
}


export default Footer;