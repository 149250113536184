import React, { Component,Fragment } from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import nav_logo from './images/nav_logo.jpeg'
import "./css/Home2.css"
import history from './history'
import * as Ip_address from "./Ip_address"
import Home2 from "./Home2"
import $ from 'jquery'
import FadeLoader from "react-spinners/FadeLoader";

import axios from 'axios'
import Swal from 'sweetalert2'


class Register_emp extends Component {


	constructor(props) {
		localStorage.setItem("register_page","show")
		super(props)
	
		this.state = {
			email:'',
			password1:'',
			password2:'',
			company_url:'',
			no_of_employees:'',
			username :'',
		  	password :'',
		  	csrftoken :'',
		  	ip_address:localStorage.ip_address,
		  	no_mode:false,
		  	id_url:localStorage.id_href.split('_'),
		  	company_id:null,
		  	count:'',
		  	text_color:'text-white',
		  	loader:false,
		}
		this.handleSubmit3 = this.handleSubmit3.bind(this);
		this.onChange=this.onChange.bind(this)
		this.Redirect=this.Redirect.bind(this)
		this.Setloader=this.Setloader.bind(this)

	}



	componentDidMount(){
		axios.get(localStorage.ip_address+'/get_application_version/')
		.then(resJson=>{
			console.log("Version",resJson)
			localStorage.setItem('app_version',resJson.data[0])
		}).catch(err=>{
			console.log("error",err)
		})
/*		console.log('id_url',this.state.id_url)
*/		fetch(this.state.ip_address+'/get_email/', {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
				   	'invitation_id':this.state.id_url[1]
				   })
			}).then(res=>res.json())
			.then(resJson=>{
				$("#warning").css({'color':'white'})
			/*console.log(resJson)*/				
				if(resJson.message=="Already Used"){
					localStorage.setItem("Register_page","alreadyregister")
					this.setState({count:"1"})					

				}
				else if(resJson.message=="Invitation Not Found")
					{
					localStorage.setItem("Register_page","no register")
					this.setState({count:"2"})
				}
				else{
					localStorage.setItem("Register_page","register")
					/*console.log("email",resJson)*/
					this.setState({
						email:resJson.email,
						company_id:resJson.company_id,
						count:"3"
					})

				}	
			}).catch(err=>{
				console.log(err)
			})
	}

	Setloader=(val)=>{
		val?this.setState({loader:true}):this.setState({loader:false})
	}

	Redirect=()=>{
		
		this.props.history.push('')	
		localStorage.clear()
		/*Change ip address in src folder having Ip_address.js file only*/
		localStorage.setItem('ip_address',Ip_address.ip_address)
		localStorage.setItem("show","download")
										
		ReactDOM.render(<Home2 />,document.getElementById('root'));

		ReactDOM.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			);
	}


	/*Register Employee*/
	handleSubmit3=(e)=>{

		e.preventDefault();
		if(this.state.password1==this.state.password2){
			this.Setloader(true)
				if (this.state.password1.match( 
	                    /[0-9]/g) || this.state.password1.match( 
	                    /[^a-zA-Z\d]/g) && this.state.password1.length >= 8) 
	            {
	            	const { username, password } = this.state;
					fetch(localStorage.ip_address+'/register/', {
					method: 'post',
					headers: {"X-CSRFToken": this.state.csrftoken,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"username": this.state.username,
						    "password": this.state.password1,
						    "email":this.state.email,
						    "company_id":this.state.company_id
						    
						   })
					}).then((response) => response.json())
					.then((responseJson) => {
					/*console.log("response of register type company",responseJson)*/
						if(responseJson.message=="Email id is already registered"){
							this.Setloader(false)
							Swal.fire({
								position: 'top-end',
					  			timer: 2000,
								text: responseJson.message,
								icon: 'warning',
								showConfirmButton: false,
							})
							this.setState({email:''})
							localStorage.setItem("Register_page","hide")
						}
						else{
							this.Setloader(false)
							Swal.fire({
								position: 'top-end',
					  			timer: 2150,
								text: "Successfully Registered",
								icon: 'success',
								showConfirmButton: false,
							})
							this.setState({email:'', username:'', password1:'', password2:'', check_mail: true})
							
					   		this.setState({showlink:"log"})
					   		localStorage.setItem("Register_page","alreadyregister")
							this.setState({count:"1"})	
						}					   
					})
					.catch((error) => {
					this.Setloader(false)
					 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Invalid login detail",
						icon: 'error',
						showConfirmButton: false,
					})
					 console.log(error)
					});
				}
				else{
					this.Setloader(false)
					this.setState({text_color:"text-danger"})
				}
			}
			else{
				this.Setloader(false)
					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Re-enter password carefully",
						icon: 'error',
						showConfirmButton: false,
					})
					this.setState({password1: '', password2: ''})
				}
		}
	
	onChange(e) {
    	this.setState({ [e.target.name]: e.target.value });
  	}

  	

	render() {
		return (
			<Fragment>
			   <div className="container">
			      	<div className="row">
			      		{
			      			localStorage.Register_page=="register" && this.state.loader==true
			      			?
		      				<div className="container loader m-auto">					
						     	{
						     	<center className="mt-5 pt-5 " id="loader_position">
							     	<FadeLoader
			     			          css=""
			     			          height={15}
			     			          width={5}
			     			          radius={2}
			     			          margin={2}
			     			          color={"black"}
			     			          loading={this.state.loader}    			         
			     			        />
		     			        </center>}
							</div> 
		    	         	:
			      			localStorage.Register_page=="register" && this.state.loader==false
			      			?
		    	         <div className="col-md-8 m-auto pt-0">
		    	            <div id="Download" className="Download">
		    	            <br/>
		    	               <div className="container register mt-5">
		    	                  <div className="row">
		    	                     <div className="col-md-3 register-left">
		    	                        <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt=""/>
		    	                        <h3>Welcome</h3>
		    	                        <p>Sign Up here</p>
		    	                     </div>
		    	                     <div className="col-md-9 register-right">
		    	                        <div className="tab-content">
		    	                           <form className="mt-5 ml-5 form mb-3" onSubmit={this.handleSubmit3}>
		    	                              <div className="form-group">
		    	                              	<input required="required" value={this.state.email} onChange={this.onChange} className="form-control com_input input-focus" name="email" type="email" placeholder="Email"  readOnly={true}/>
		    	                              </div>
		    	                              <div className="form-group">
		    	                              	<input required="required" value={this.state.username} onChange={this.onChange} className="form-control com_input input-focus" name="username" type="text" placeholder="Enter Full Name" autoFocus />
		    	                              </div>
		    	                              <div className="form-group">
		    	                              	<input required="required" value={this.state.password1} className="form-control input-focus" id="password1" type="password" name="password1" onChange={this.onChange} placeholder="Password" minLength="8"/>
		    	                              </div>
		    	                              <div className="form-group">
		    	                              	<input required="required" value={this.state.password2} className="form-control input-focus" id="password2" type="password" name="password2" onChange={this.onChange} placeholder="Confirm password" minLength="8"/>
		    	                              </div>		    	                             
		    	                              <div className="form-group">
		    	                              	<input type="submit" class="btn col-4 ,mb-3 btn-outline-primary"   value="Sign Up"/>
		    	                               </div>
		    	                               <small className={this.state.text_color} id="warning">Password combination must have one Number,one character, length eight.  </small>

		    	                               {/*<a href="#" onClick={()=>this.showDownload("login")} className="forgot-password mb-3">
						                			Already have an account? Login here
						            		 	</a>*/}
		    	                              
		    	                           </form>
		    	                        </div>
		    	                     </div>
		    	                  </div>
		    	               </div>
		    	            </div>
		    	         </div>	

		    	         :
		    	         null
		    	    }

		    	         {
		    	        localStorage.Register_page=="alreadyregister"
		    	        ?
		    	        <div className="container-fluid body">
								<div className="row py-5">
									<div className="card col-sm-5 m-auto" style={{borderRadius: '20px'}}>
							          
										<p id="profile-name" className="profile-name-card"></p>
										<div className="row">
											<div className="col-sm-12 text-center">
											<span >You are already registered</span>
												<form className="form-signin offset-2 mb-3" >
									                
									               <div className="row text-center">
									                	<div className="col-12 mt-1">
									                		<button onClick={()=>this.Redirect()}  className="btn btn-primary btn-block btn-signin-create col-12"  >Go to Login page</button>
									                	</div>
									                </div>
								           		</form>        
								           	</div>	
							           	</div>
									</div>
								</div>
							</div>
							:
							null
		    	         }

		    	         {
		    	         	localStorage.Register_page=="no register"
		    	         	?
		    	         	/*<h1>Invalid Link address</h1>*/
		    	         	<div className="card card-waves col-md-9 p-5">
							    
							    <div className="p-5 mb-0 alert text-center alert-danger alert-dismissible fade show">
								    <strong>Error!</strong> Invalid Link Address<br/><br/>
								    <a className="mt-2" href="trackme.wangoes.com">Click here to visit our website</a>
								</div>
							</div>	
		    	         	:
		    	         	null
		    	         }


		      		</div>
		   		</div>
			</Fragment>
		)
	}
}

export default Register_emp