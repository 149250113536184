import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import './css/Employee.css'
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import FadeLoader from "react-spinners/FadeLoader";
/*import ProgressBar from 'react-progressbar';
*/import ProgressBar from 'react-bootstrap/ProgressBar'
import Swal from 'sweetalert2'
import moment from 'moment';
import Footer from './Footer';

class Screenshot_summary extends React.Component {

	constructor(props) {
		super(props)
		/*const response=this.props.location.state.Data*/
		const user_id=this.props.location.state.User_id	
		const date=this.props.location.state.Date
		this.state = {
			all_images:[],
			date:date,
			ip_address:localStorage.ip_address,
			hourly_img:[],
			user_id:user_id,
			NewTime:'',
			emp_name:'',
			isLoading:true,
			next_prev_img:[],
				
		}
		this.setHourlyImg=this.setHourlyImg.bind(this)
	}

	componentDidMount(){

		fetch(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',{
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
		})
		.then(resj=>resj.json())
		.then(res=>{
			/*console.log(res.Images)*/
			/*console.log("name",res.Name)*/
			this.setState({
				all_images:res.Images,
				emp_name:res.Name,
				/*emp_Email:res.Email*/
			})
			this.setHourlyImg()
		})
			
	}

	/*Set hourly image in array*/		
	setHourlyImg=()=>{
		let date=this.state.date.split("-")
		/*console.log("date",date,this.state.all_images)*/
		let array=[]
		let today=this.state.all_images.filter(data=> data.date==date[2] && data.month==date[1] && data.year==date[0]  && parseInt(data.hour)>=0 )						
		// console.log("check after date",today)
		for(let h=24; h>=0; h--)
		{
			let temp=today.filter(value=>value.hour==h)
			if(temp.length)
			{
				array.push(temp)
			}
		}
			
		if(array.length){
			this.setState({
				hourly_img:array,
				isLoading:false
			},()=>{
				/*console.log("check",this.state.hourly_img)*/
				let arr=[]
				for(let i=0; i<this.state.hourly_img.length; i++ ){
					/*console.log("check",i,this.state.hourly_img[i])*/
					for(let j=0; j<this.state.hourly_img[i].length; j++){
						arr.push(this.state.hourly_img[i][j].image)
					}
				}/*console.log("completed img",arr)*/
				this.setState({next_prev_img:arr})
			})
		}else{
			Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: "There is no screen shots.",
					icon: 'warning',
					showConfirmButton: false,
				})
			this.setState({
				isLoading:false
			})
		}
	}
		

	/*DateWiseFilter=(val_date)=>{

		console.log("val_date",val_date,this.state.all_images)
  		this.props.history.push({pathname: '/FilterImg',state: {User_id:val_date,all_images:this.state.all_images }})
	}*/
		
	
	openPopupbox=(path)=> {
		/*console.log(path)*/
	    var content = <div>
    	<img className="text-styles img-fluid mb-2"  height='550'  src={path} alt="Image not found"/>
    	{	
    		this.state.next_prev_img.indexOf(path)!=0
			?
	    	<span onClick={()=>this.openPopupbox(this.state.next_prev_img[this.state.next_prev_img.indexOf(path)-1])} className="btn btn-primary mr-5">
	    		<i className=" fas fa-angle-left  text-white">-Prev</i>
	    	</span>
	    	:
	    	null
	    	}


	    {	
    		this.state.next_prev_img.indexOf(path)!= this.state.next_prev_img.length-1
			?
	   	<span onClick={()=>this.openPopupbox(this.state.next_prev_img[this.state.next_prev_img.indexOf(path)+1])} className="btn btn-primary float-right" >
	   		{/*console.log(this.state.next_prev_img.indexOf(path))*/}
	   		Next-<i className="fas fa-angle-right  text-white"></i>

		</span>
		:
	    	null
	    	}
	    </div>

		PopupboxManager.open({
	        content,
	        config: {
	          titleBar: {
	            enable: true,
	            text: 'Screenshot',
	            
	          },
	          fadeIn: true,
	          fadeInSpeed: 100,

	        }
	      })
	}

	
			   		  
	render() {
		const {all_images}=this.state
		const {ip_address,hourly_img,user_id,NewTime,isLoading,emp_name}=this.state
		return (
			<Fragment>				
				<div className="container ">
				<h4 className="text-color text-capitalize">{moment(this.state.date).format("MMM Do YYYY")} :{emp_name}</h4>
					{/*<div class="card-group">
							  <div class="card mr-3">

							    <div className="card-body ">
				                <h3 className="card-title text-center mt-1 text-color  font-weight-bold">{emp_today_date}</h3>
							    <hr className='w-75 mx-auto'/>
							    <h1 className="card-subtitle mb-2 text-center text-muted" id="emp_today_hrs">{emp_today_hrs}&nbsp;Hrs</h1>
							    <p className="card-text text-center text-muted">Week:{emp_week} &nbsp; = &nbsp; Month {emp_month}</p>
				            </div>
							  </div>
							  <div class="card">
							    <div class="card-body">
							   
				                    <h3 className="card-title text-center mt-1 text-color  font-weight-bold">Note</h3>
				                    	<hr className='w-75 mx-auto' />
				                    	<ol className='pl-0'>
				                    		<li>Name:-{emp_name}</li>
					                    	<li>Screen shot captures in every <span className='text-color font-weight-bold'>{NewTime}min</span></li>
					                    	<li>Email:{emp_Email}</li>		                    		
										</ol>   
								
									</div>
							  </div>
							  
					</div>*/}
				       
    				
		    		{/*<div className="mt-4">
		    			<a className="text-color"  style={{cursor:'pointer'}} onClick={()=>{this.DateWiseFilter(user_id)}}>Show Date wise filter</a>
		    			<i className="fa fa-search ml-1 text-color" aria-hidden="true"></i>
		    		</div>*/}

		   	{	
								 				
		   		isLoading
		   		?
					<div className="container loader m-auto">					
				    	{
						    <center className="mt-5 pt-5 " id="loader_position">
						    	<FadeLoader
		     			          css=""
		     			          height={15}
		     			          width={5}
		     			          radius={2}
		     			          margin={2}
		     			          color={"black"}
		     			          loading={isLoading}    			         
		     			        />
		     			    </center>
		     			}				
					</div>		   		
		   		:
		   		hourly_img.map((data,index)=>(
		   			<Fragment key={index+"500"}>	   		
		   				<h2 className="text-color text-center font-weight-bold mb-3">{data[0].hour}:00{data[0].mode} to {parseInt(data[0].hour)+1}:00{data[0].mode}</h2>
				   		<div className="col-md-12 mb-4"><hr className="w-25 m-auto" style={{backgroundColor:'#1976D2'}} /></div>
	   				   	<div className="row text-center text-lg-left">	
			   				{
			   				data.map((value,i)=>(
								<div key={value.mode+i} className="col-lg-3 mb-3 col-md-4 col-6">
									<div className='px-1 my-0 pb-2 card' style = {{cursor: 'pointer'}}>
								    <small className="">{value.hour}:{value.min}{value.mode}&nbsp;&nbsp;</small>
								      <a onClick={()=>{this.openPopupbox(value.image)}} className="d-block mb-2 h-100">
								            <img loading="lazy" height='150' width='300' className="img-fluid img-thumbnail" src={value.image} alt="not available"/>
								          </a>
								{
									parseInt(value.activity)>60
									?
									<span  className="px-3"><ProgressBar variant="success"  now={parseInt(value.activity)} label={`${value.activity}%`} /></span>									    
									:
									<span  className="px-3"><ProgressBar variant="danger"  now={parseInt(value.activity)} label={`${value.activity}%`} /></span>									    
								}
								 </div>  

							    </div>
				   			))

			   				}
			   				<br/>
			   				<div className="btn btn-sm col-md-12 my-2"></div>
	   				   	</div>
		   			</Fragment>	
		   			))
		   				   		
				}
 			</div> 						    
			<PopupboxContainer />
		   	<ScrollUpButton style={{backgroundColor: '#1976D2'}} />
		   	<hr className="my-5"/>
			<Footer/>
		</Fragment>
		)
	}
}

export default Screenshot_summary;