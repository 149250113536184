import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"
import moment from "moment"
import axios from 'axios'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ReactTooltip from 'react-tooltip';
import chroma from 'chroma-js';
import Select from 'react-select';
import ShowMoreText from 'react-show-more-text';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const { SearchBar } = Search;

const Company_projects=()=> {
	const inputElement = useRef(null);
	const [user_list,setUserdetail]=useState([])
	const history = useHistory()
	const [project_list, setProjectlist] = useState([])
	const [isLoading, setLoading] = useState(true)	
	const [ p_title,setTitle]=useState("")
	const [modal,setModal]=useState(false)
	const [P_modal,setP_model]=useState(false)
	const [value,setValue]=useState("")
	const [assignP_id,setProject_id]=useState("")
	const [EA_modal,setEA_model]=useState(false)
	const [p_status_id,setStatusid]=useState("")
	const [selected,setSelected]=useState([])
	const [delete_selected,setDelete_ps]=useState("")
	const [ task_title,setTaskTitle]=useState("")
	const [task_description,setDesc]=useState("")
	const [tab_task, setTab]=useState(true)
	const [active_tab, setActiveTab]=useState("tab_project")
	const [edit_modal,setEditModal]=useState(false)
	const [employee_list, setEmployeeList] = useState([])
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [edit_status, setEditStatus] = useState("");
	const [task_status, setTaskStatus] = useState("");
	const [custom_date, setCustomDate] = useState("")
	const [edit_custom, setEditCustom] = useState(false)

  	const dropdown_toggle = () => setDropdownOpen(prevState => !prevState);

  	const edit_status_toggle = () => setEditStatus(prevState => !prevState);

  	const edit_custom_toggle = () => setEditCustom(prevState => !prevState);

	useEffect(()=>{
		/*console.log("Com[p dashbpoard",localStorage.company_id)*/
		get_projects()
		if (inputElement.current) {
	      inputElement.current.focus();
	    }
		fetch(localStorage.ip_address+'/users_list/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"company_id":localStorage.company_id
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				setUserdetail(resJson)
				/*console.log(resJson)*/
				setLoading(false)
			}).catch((error)=> {
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
				setLoading(false)
			})
	},[])
			
	const EA_toggle = () =>{
	  setEA_model(!(EA_modal))
	}

	const get_projects=()=>{
		const head={
				 headers:{"company_id":localStorage.company_id,
				 "username":localStorage.username,"token":localStorage.token,
				  'Content-Type':'application/json'}
				 }
		axios.get(localStorage.ip_address+'/projects_list/'+localStorage.company_id+'/',head)
		  .then((response) => {
		    console.log("axios reponse",response.data);
		    setProjectlist(response.data)
		    setLoading(false)
		  	}).catch(error => {
    		console.log(error)
    		setLoading(false)
    		Swal.fire({
				position: 'top-end',
	  			timer: 2150,
				text: 'Error in geeting project list',
				icon: 'error',
				showConfirmButton: false,
			})
  		})
		    
		/*fetch(localStorage.ip_address+'/projects_list/'+localStorage.company_id+'/', {
		method: 'GET',
		headers: {"token":localStorage.token,"username":localStorage.username,
		           'Content-Type':'application/json'}
		})
		.then((response)=> response.json())
		.then((resJson)=>{
			setProjectlist(resJson)
			setLoading(false)
		}).catch((error)=> {
			setLoading(false)
			Swal.fire({
				position: 'top-end',
	  			timer: 2150,
				text: 'Error in geeting project list',
				icon: 'error',
				showConfirmButton: false,
			})
		})*/
	}


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		  Showing { from } to { to } of { size } Results
		</span>
	);

	const projectTitle=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font"   
	    		style={{color:'#206090'}} value={ cell.id } >
	    		{cell.Project_Title}
	    	</a>
	  	);
	}

	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font pl-2"  
					style={{color:'#206090'}} value={ cell.id } >
					{cell.No}
				</a>
			)
	}

	const actionFormatterTime=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"
				  style={{color:'#206090'}} 
				  value={ cell.id } >
				  {cell.Total_time}
				</a>
			)
	}

	const created_date=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  style={{color:'#206090'}} 
					value={ cell.id } >
					{moment(cell.created_at).format("MMM Do YYYY")}
				</a>
			)
	}

	const Show_task=(project_id)=>{
		const id=project_id
		localStorage.setItem("p_task_id",id)
		history.push({pathname: '/Company_tasks'})		
	}

	const Task_list=(e, cell, row, rowIndex)=>{
		return(
			<a className="table-content-font point" 
				onClick={()=>{Show_task(cell.id)}} 
				style={{color:'#206090'}} 
				value={ cell.id } >
				<i class="fa fa-eye" aria-hidden="true"></i>
			</a>
		)
	}

	const Task_count=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font text-center" 
					onClick={()=>{Show_task(cell.id)}} 
					style={{color:'#206090'}} value={ cell.id } >
					{cell.No_of_task}
				</a>
			)
	}

	const assign_project=(e,cell,row,rowIndex)=>{
		return(
				<a className="table-content-font text-center text-primary point" 
					onClick={()=>{Assign_project(cell)}} >
					Assign
					{/*<i class="fas fa-tasks"></i>*/}
			 	</a>
			)
	}

	const Assign_project=(cell)=>{
		setProject_id(cell.id)
		P_toggle()
	}

	const formatdelete_project=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font text-center point"  
					onClick={()=>{delete_project(cell.id)}} 
						style={{color:'#206090'}} value={ cell.id } >
						<i className="fas fa-trash"></i>
				</a>
			)
	}

	const formatedit_project=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font text-center point"
					style={{color:'#206090'}} value={ cell.id } 
					onClick={()=>edit_toggle(cell.id)}
					>
						<i className="fas fa-edit"></i>
				</a>
			)
	}

	const delete_project=(id)=>{
		setLoading(true)
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You won't be able to revert this!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		    if (result.isConfirmed) {
		  	/*console.log('deleted',id)*/
		  	fetch(localStorage.ip_address+'/delete_project/'+id+'/', {
				method: 'POST',
				headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
				body: JSON.stringify({				   	
					   })
				})
				.then((response)=> response.json())
				.then((resJson)=>{
				/*console.log("Users _list",resJson)*/
				if(resJson.message=="Project Deleted" && resJson.project_list)
				{
					Swal.fire({
								position: 'top-end',
					  			timer: 2150,
								text: "Your project has been deleted.",
								icon: 'success',
								showConfirmButton: false,
							})
				/*console.log(resJson)*/
					setLoading(false)
					setProjectlist(resJson.project_list)
				}
				else{
					setLoading(false)
					Swal.fire({
							position: 'top-end',
				  			timer: 2150,
							text: "Can not delete at this moment.",
							icon: 'error',
							showConfirmButton: false,
						})
				}
				}).catch((error)=> {
					setLoading(false)
					Swal.fire({
								position: 'top-end',
					  			timer: 2150,
								text: error,
								icon: 'Error',
								showConfirmButton: false,
							})
					get_projects()
					})
				}
			else{
				setLoading(false)
			}
		})
	}

	const project_status=(e,cell,row,rowIndex)=>{
		return(
				<a className="table-content-font point"  
					onClick={()=>{Change_pstatus(cell)}} style={{color:'#206090'}}
					 value={ cell.id } >
				{
					cell.Status ? <div className="mb-0">Completed&nbsp;<i class="fas fa-edit"></i>
					</div>:<p className="mb-0">Incomplete&nbsp;<i class="fas fa-edit"></i></p>}
				
				</a>
			)
	}

	const assignedTo=(e,cell,row,rowIndex)=>{
		return(
				<a className=""   
		    		style={{color:'#206090'}} value={ cell.id } >
		    		{typeof cell.Assigned_to!=='undefined'?
		    			<ShowMoreText
			                /* Default options */
			                lines={1}
			                more='Show more'
			                less='Show less'
			                className='content-css'
			                anchorClass='my-anchor-css-class'
			                onClick={executeOnClick}
			                expanded={false}
			                width={120}
			            >{cell.Assigned_to.join(', ')}
            			</ShowMoreText>
            :null}
	    		</a>
		)
	}

	const Change_pstatus=(cell)=>{
		setEA_model(true)
		setStatusid(cell.id)	
	}

	const Change_status =(e) =>{
		e.preventDefault()
		setLoading(true)
		fetch(localStorage.ip_address+'/change_project_status/'+p_status_id+'/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"status":value,
				   })
			})   	
			.then((response)=> response.json())
			.then((resJson)=>{
				EA_toggle()
				if(resJson.message=="Project status changed" && resJson.project_list){
					Swal.fire({
							position: 'top-end',
				  			timer: 2150,
							text: "Project status changed successfully",
							icon: 'success',
							showConfirmButton: false,
						})
					setProjectlist(resJson.project_list)
					/*get_projects()*/
				}
				else if(resJson.details){
					get_projects()
					Swal.fire({
							position: 'top-end',
				  			timer: 2150,
							text: resJson.details,
							icon: 'Error',
							showConfirmButton: false,
						})
				}
				setLoading(false)
			}).catch((error)=> {
				EA_toggle()
				get_projects()
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
			})
	}

    const handleBtnClick = () => {
	    if (!selected.includes(2)) {
	       setSelected([...selected, 2])
	    }else {
	        setSelected(selected.filter(x => x !== 2))
	    }
	}

	const handleOnSelect = (row, isSelect) => {
	    if (isSelect) {
	    	var search_element = document.getElementsByClassName('search')[0]
	    	search_element.classList.remove("offset-lg-9")
	    	search_element.classList.add("offset-lg-6")
	        setSelected([...selected, row.id])
	    } else {
	        setSelected(selected.filter(x => x !== row.id))
	        var search_element = document.getElementsByClassName('search')[0]
	    	search_element.classList.add("offset-lg-9")
	    	search_element.classList.remove("offset-lg-6")
		}
	}

	const handleOnSelectAll = (isSelect, rows) => {
	    const ids = rows.map(r => r.id);
	    if (isSelect) {
	        setSelected(ids)
	    } else {
	        setSelected([])
	    }
	}

  	const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      style: { backgroundColor: '#c8e6c9' },
      selected: selected,
      onSelect: handleOnSelect,
      onSelectAll:handleOnSelectAll,
       selectColumnPosition: 'right',
    };

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'left',
	  align:'left',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Project_Title',
	  text: 'Project',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: projectTitle,
	  classes: 'align-project',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Assigned_to',
	  text: 'Assigned To',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: assignedTo,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}
	,{ 
	  dataField: 'Total_time',
	  text: 'Working Hour',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: actionFormatterTime,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}
	,{ 
	  dataField: 'created_at',
	  text: 'Date',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: created_date,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'No_of_task',
	  text: 'Task',
	  headerAlign: 'center'	,
	  align:'center',
	  classes: 'align-middle',
	  formatter: Task_count,
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Completed',
	  text: 'Status',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: project_status,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: assign_project,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: Task_list,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: formatdelete_project,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: formatedit_project,
	  classes: 'align-middle',
	 headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}
	
];

	const pageButtonRenderer = ({
	  page,
	  active,
	  disable,
	  title,
	  onPageChange
	}) => {
	  const handleClick = (e) => {
	    e.preventDefault();
	    onPageChange(page);
	  };
	  const activeStyle = {};
	  if (active) {
	    activeStyle.backgroundColor = 'black';
	    activeStyle.color = 'white';
	    activeStyle.padding = '10px'
	    activeStyle.borderRadius = '5px 0px 0px 5px'
	    activeStyle.borderRadiusRight = '0px'
	  } else {
	    activeStyle.backgroundColor = 'gray';
	    activeStyle.color = 'black';
	    activeStyle.padding = '10px'
	  }
	  if (typeof page === 'string') {
	    activeStyle.backgroundColor = 'white';
	    activeStyle.color = 'black';
	  }
	  return (
	    <li className="page-item" style={{float: 'right!important'}}>
	      <a onClick={ handleClick } style={ activeStyle }>{ page }</a>
	    </li>
	  );
	};

	const sizePerPageOptionRenderer = ({
	  text,
	  page,
	  onSizePerPageChange
	}) => (
	  <li
	    key={ text }
	    role="presentation"
	    className="dropdown-item"
	  >
	    <a
	      href="#"
	      tabIndex="-1"
	      role="menuitem"
	      data-page={ page }
	      onMouseDown={ (e) => {
	        e.preventDefault();
	        onSizePerPageChange(page);
	      } }
	      style={ { color: 'rgb(33, 92, 142)!important' } }
	    >
	      { text }
	    </a>
	  </li>
	);


	const options = {
	    totalSize: project_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{		
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: project_list.length
		}] 
	};

	const HandleKeyDown = (e) =>{
	    if (e.key === 'Enter') {
	    	const headers = {
	    		headers:
	    		 {"token":localStorage.token,
	    		 "username":localStorage.username,
	    		 'Content-Type':'application/json'}
	    	}
		    const data = {'data': e.target.value}
		    axios.post(localStorage.ip_address+"/search_project/", data, headers)
		    .then(response=>{
		      	setProjectlist(response.data)
		    }).catch(error=> console.log(error))
	    }
	}

	const toggle = () =>{
	  setModal(!(modal))
	}

	const P_toggle = () =>{
	  setP_model(!(P_modal))
	}

	const Add_project =(e) =>{
		e.preventDefault()
		setLoading(true)
		console.log(employee_list)
		fetch(localStorage.ip_address+'/create_project/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"company_id":localStorage.company_id,
				   	"project_title":p_title,
				   	"emp_id": employee_list
				})
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				// toggle()
				if(resJson.message=="Project created successfully" && resJson.project_list){
					setTab(false)
					setActiveTab("tab_task")
					localStorage.setItem('new_project_id', resJson['project_list'][0]['id'])
					setTitle('')
					setProject_id(resJson['project_list'][0]['id'])
					fetch(localStorage.ip_address+'/assign_project/'+localStorage.company_id+'/', {
					method: 'POST',
					headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"project_id":resJson['project_list'][0]['id'],
						   	"emp_id": employee_list,
						})
					})
					.then((response)=> response.json())
					.then((resJson)=>{
						if(resJson.message=="Project assigned successfully" && resJson.project_list){
							setProjectlist(resJson.project_list)
							Swal.fire({
								position: 'top-end',
					  			timer: 2150,
								text: "Project created successfully",
								icon: 'success',
								showConfirmButton: false,
							})
							setEmployeeList([])
						}
						else if(resJson.details){
							get_projects()
						setProjectlist(resJson.project_list)
						}
						setLoading(false)
					}).catch((error)=> {
						P_toggle()
						get_projects()
						setLoading(false)
						Swal.fire({
							position: 'top-end',
				  			timer: 2150,
							text: error,
							icon: 'Error',
							showConfirmButton: false,
						})
					})
				}
				else if(resJson.details){
					Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: resJson.details,
						icon: 'Error',
						showConfirmButton: false,
					})
				}
				//get_projects()
				setLoading(false)
			}).catch((error)=> {
				toggle()
				// get_projects()
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
			})
	}
				
	const Assign_P =(e) =>{
		e.preventDefault()
		setLoading(true)
		/*console.log(typeof(value),typeof(assignP_id))*/
		fetch(localStorage.ip_address+'/assign_project/'+localStorage.company_id+'/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   	"project_id":assignP_id,
				   	"emp_id": employee_list,
				})
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				P_toggle()
				if(resJson.message=="Project assigned successfully" && resJson.project_list){
					setProjectlist(resJson.project_list)
					Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Project assigned successfully",
						icon: 'success',
						showConfirmButton: false,
					})
				}
				else if(resJson.details){
					get_projects()
					Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: resJson.details,
						icon: 'Error',
						showConfirmButton: false,
					})
				}
				setLoading(false)
			}).catch((error)=> {
				P_toggle()
				get_projects()
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
			})
	}

	const Add_tasks =(e, val) =>{
		e.preventDefault()
		setLoading(true)
		if(task_title === "" && task_description ==="" ){
			Swal.fire({
				position: 'top-end',
				timer: 2150,
				text: "Please fill all the field of 'Create Task'",
				icon: 'Error',
				showConfirmButton: false,
			})
			setLoading(false)
		}
		else{
		fetch(localStorage.ip_address+'/create_task/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"project_id":localStorage.new_project_id,
					"task_title":task_title,
					"task_description":task_description
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				if(val === 'add'){
					toggle()
				}
				if(resJson.message=="Task created successfully" && resJson.task_list){
					setProjectlist(resJson.project_list)
					setDesc('')
					setTaskTitle('')
					setValue('')
					// Assign task api call
					fetch(localStorage.ip_address+'/assign_task/', {
						method: 'POST',
						headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
						body: JSON.stringify({
								"task_id": resJson['task_list'][0]['Task_id'],
								"emp_id": value,
								"reassign": false				   	
							   })
						})
						.then((response)=> response.json())
						.then((resJson)=>{
							/*console.log(resJson)*/
							if(resJson.message=="Task assigned successfully" && resJson.task_list){			
								
								setProjectlist(resJson.project_list)
								Swal.fire({
									position: 'top-end',
									timer: 2150,
									text: "Task created successfully",
									icon: 'success',
									showConfirmButton: false,
								})
							}
							else if(resJson.details){
								
								get_projects()
								Swal.fire({
									position: 'top-end',
									timer: 2150,
									text: resJson.details,
									icon: 'Error',
									showConfirmButton: false,
								})
							}
							/*console.log("Users _list",resJson)*/
							/*setUserdetail(resJson)*/
							/*console.log(resJson)*/
							setLoading(false)				
						}).catch((error)=> {
							get_projects()
							Swal.fire({
								position: 'top-end',
								timer: 2150,
								text: error,
								icon: 'Error',
								showConfirmButton: false,
							})
						})					
					}
					else if(resJson.details){
						/*get_tasks()*/
						Swal.fire({
							position: 'top-end',
							timer: 2150,
							text: resJson.details,
							icon: 'Error',
							showConfirmButton: false,
						})
					}
					
					/*console.log(resJson)*/
					setLoading(false)
					
				}).catch((error)=> {
					toggle()
					setLoading(false)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: error,
						icon: 'Error',
						showConfirmButton: false,
					})
				})
			}
	}

	const handleChoose=(e)=>{
		e.preventDefault()
		if(delete_selected=="Delete"){
			setLoading(true)
			fetch(localStorage.ip_address+'/delete_multiple_project/'+localStorage.company_id+'/', {
				method: 'POST',
				headers: {"token":localStorage.token,"username":localStorage.username,
				 'Content-Type':'application/json'},
				  body: JSON.stringify({
				   	"project_ids":selected
				})
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				if(resJson.message=="Projects deleted successfully"){
					setSelected([])
					setProjectlist(resJson.project_list)
					Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Selected projects deleted successfully",
						icon: 'success',
						showConfirmButton: false,
					})
					/*console.log(resJson)*/
					setLoading(false)
				}
			}).catch((error)=> {
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
				setLoading(false)
				})
				/*console.log(selected)*/
			}
		else if(delete_selected=="Create")
			toggle()
	}

	const onChange = (e) =>{
		if(e.target.value===''){
			get_projects()
		}
	}

	const UserOptions = [
		user_list.map((data)=>(
	  { value: data.id, label: data.employee_name }
	  ))
	];

	const handleChange = (e) => {
	  setEmployeeList(Array.from(e, option => option.value))
	  console.log(employee_list)
	}

	const executeOnClick=(isExpanded)=> {
        console.log(isExpanded);
    }

    const edit_toggle = (id) =>{
    	if(typeof id == 'number'){
    		setProject_id(id)
	    	fetch(localStorage.ip_address+'/project_detail/'+id+'/',{
	    		headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
	    	}
	    	)
	    	.then(res=>res.json())
	    	.then(resJson=>(
	    		// console.log(resJson.Project_Title)
	    		setTitle(resJson.Project_Title)
	    	))
	    	.catch(error=> console.log(error))
	    }
	    else{ setTitle('')}
    	setEditModal(!(edit_modal))
    }

    const editProject = (e) =>{
    	e.preventDefault()
    	fetch(localStorage.ip_address+'/update_project/'+assignP_id+'/',{
    		method: 'post',
    		headers :{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
    		body:JSON.stringify({
			   	"project_title": p_title
			})
    	})
    	.then(res=> res.json())
    	.then((resJson=>{
    		if('id' in resJson){
    			Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'Project edited successfully',
					icon: 'success',
					showConfirmButton: false,
				})
				setTitle('')
				setProject_id('')
				edit_toggle()
				get_projects()
    		}
    	}))
    	.catch(error=> console.log(error))
    }

    const changeValue = (e) => {
        console.log(e.currentTarget.textContent)
        
        const headers = {
    		headers:{"token":localStorage.token,
    		 		 "username":localStorage.username,
    		 		 'Content-Type':'application/json'}
    	}

        if(e.currentTarget.textContent === 'Task Status'){
        	edit_status_toggle()
        }
        if(e.currentTarget.textContent === "Today's Date"){
        	let d = new Date()
        	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
			const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
			const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        	console.log(da +'/' + mo + '/' + ye)
        	const data = {'data': ye +'-' + mo + '-' + da}

        	axios.post(localStorage.ip_address+"/search_project/", data, headers)
		    .then(response=>{
		      	if(!('message' in response.data && response.data.message === "No result found")){
		    		setProjectlist(response.data)
		    	}
		    	else{
		    		Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: 'No result found',
						icon: 'info',
						showConfirmButton: false,
					})	
		    	}
		    }).catch(error=> console.log(error))
        }
        if(e.currentTarget.textContent === "Custom Date"){
        	edit_custom_toggle()
        }
    }

    const submitStatus = (e) =>{
    	e.preventDefault()
	   	const headers = {
			headers:
			 {"token":localStorage.token,
			 "username":localStorage.username,
		 	 'Content-Type':'application/json'}
    	}
		const data = {'data': task_status}
        axios.post(localStorage.ip_address+"/search_project/", data, headers)
	    .then(response=>{
	    	if(!('message' in response.data && response.data.message === "No result found")){
	    		setProjectlist(response.data)
	    	}
	    	else{
	    		Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'No result found',
					icon: 'info',
					showConfirmButton: false,
				})	
	    	}
	      	edit_status_toggle()
	    }).catch(error=> console.log(error))
    }

    const submitCustomDate = (e) =>{
    	e.preventDefault()
	   	const headers = {
			headers:
			 {"token":localStorage.token,
			 "username":localStorage.username,
		 	 'Content-Type':'application/json'}
    	}
		const data = {'data': custom_date}
        axios.post(localStorage.ip_address+"/search_project/", data, headers)
	    .then(response=>{
	      	if(!('message' in response.data && response.data.message === "No result found")){
	    		setProjectlist(response.data)
	    	}
	    	else{
	    		Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'No result found',
					icon: 'info',
					showConfirmButton: false,
				})	
	    	}
	      	edit_custom_toggle()
	    }).catch(error=> console.log(error))
    }

	return(
			<Fragment>
			   	{
			   isLoading				
			   ?
			   <div className="container loader m-auto">
			      {
			      <center className="mt-5 pt-5 " id="loader_position">
			         <FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      </center>
			      }				
			   </div>
			   :
			   <div className='container-fluid'>
			        <div className='row'>
				        	<div className="col-md-6 col-lg-3 choose">
   				        {
   				        	selected.length
   				        	?
				        		<form className="mb-2 float-right pr-4" onSubmit={handleChoose}>
								  <div class="form-row align-items-center">
								    <div class="col-md-7 mx-2 my-1">
								      <select value={delete_selected} onChange={e => setDelete_ps(e.currentTarget.value) } class="custom-select  mr-sm-2" >
								        <option selected>Choose...</option>
								        <option value="Delete">Delete</option>
								        {/*<option value="Create">Create New Project</option>*/}
								      </select>
								    </div>
								    
								    <div class="col-3 my-1">
								      <button type="submit" class="btn btn-primary">Submit</button>
								    </div>
								  </div>
								</form>
				        	:
				        	null
			        	}
				        	</div>
			        	<div className="offset-lg-6 col-lg-1 search">
   				            <div className="">
   				            	{/*<div className="input-group-prepend">
   				            		<span className="input-group-text border-left-0 border-right-0 border-top-0 search-icon mt-2"><i className="fa fa-search"></i></span>
   				            	</div>
   				            	<input 
   				            	 className="form-control search-btn border-left-0 border-right-0 border-top-0" 
   				            	 placeholder="Search"
   				            	 data-tip="Search by project title, date(format YYYY-mm-dd) and status" 
   				            	 data-place="bottom"
   				            	 onChange={onChange} onKeyDown={HandleKeyDown}/>
   				            	<ReactTooltip />*/}
   				            	<Dropdown isOpen={dropdownOpen} toggle={dropdown_toggle}>
							      <DropdownToggle caret className="btn-primary">
							        Filter By
							      </DropdownToggle>
							      <DropdownMenu>
							        <DropdownItem header>Apply Filters</DropdownItem>
							        <DropdownItem onClick={changeValue}>Today's Date</DropdownItem>
							        <DropdownItem onClick={changeValue} text>Custom Date</DropdownItem>
							        <DropdownItem onClick={changeValue}>Task Status</DropdownItem>
							      </DropdownMenu>
							    </Dropdown>
   				            </div>
   				        </div>
			        	<div className="col-lg-2">
   				            <a className=" nav-link text-primary pb-2 mt-1" 
   					            style={{cursor:'pointer',textDecoration: 'underline'}}
   					             onClick={toggle}>
   				            	<i class="fa fa-plus" aria-hidden="true"></i>Create Project
   				            </a>
   				        </div>
				      
				         <br/>
				         <br/>
				         <Modal  className="text-align-center" isOpen={modal} fade={false} toggle={toggle}>
				        	<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={toggle} className="">
				        	<span className="m-auto text-color-gradient font-weight-bold">Create New Project</span></ModalHeader>
				        	<ModalBody>
				        		<Tabs defaultActiveKey={active_tab} transition={false} id="noanim-tab-example">
								  <Tab eventKey="tab_project" title="Create Project" tabClassName="tab-color">
								    <form className="form m-auto col-8 p-4" onSubmit={Add_project}>
						          		<div className="form-group mt-3">
							          		<input className="form-control m-auto input-focus" placeholder="Enter Project Title" 
							          				type="text" name="p_title" value={p_title} 
							          				onChange={e => setTitle(e.target.value)} autoFocus
							          				ref={inputElement}
							          				required
							          		/>
							          		<Select
												className="input-focus mt-3"
											    closeMenuOnSelect={false}
											    isMulti
											    options={UserOptions[0]}
											    onChange={handleChange}
											  />
						          			<button type="submit" className="btn btn-primary col-12 mt-2">Save</button>
						          		</div>
					        		</form>
								  </Tab>
								  <Tab defaultActiveKey={active_tab} eventKey="tab_task" title="Create Task" tabClassName="tab-color" disabled={tab_task}>
								    <form className="form m-auto col-10">
										<div className="form-group mt-3">
											<br/>
											<input className="form-control m-auto input-focus" placeholder="Enter Task Title" 
											   type="text" name="task_title" value={task_title} 
											   onChange={e => setTaskTitle (e.target.value)} autofocus
												required
											/>
										</div>
										<div className="form-group m-auto">
											<textarea className="form-control mt-3 input-focus" name="task_desc" 
											 id='task_desc' onChange={e => setDesc(e.target.value)} 
											 value={task_description} required placeholder="Write Task Description..."></textarea>
											<select className="input-focus" value={value} required onChange={e =>
												setValue(e.currentTarget.value) } className="form-control mt-3">
												<option className="text-muted" disable>Select</option>
												{user_list.length
												?
												user_list.map(data=>(
												<option className="text-dark" value={data.id}>{data.employee_name}</option>
												))
												:
												null
												}
											</select>
											<button type="button" onClick={(e)=> Add_tasks(e, 'add')} className="btn btn-primary col-lg-3 mt-3">Save</button>
						          			<button type="button" onClick={(e)=> Add_tasks(e, 'another')}  className="btn btn-primary col-lg-8 ml-2 mt-3">Save and add another</button>
									  </div>
									  <br/><br/><br/>
								   </form>
								  </Tab>
								</Tabs>
		         		 	</ModalBody>
		        		</Modal>

		        		 {/* Assign Project */}
				        <Modal  className="text-align-center" isOpen={P_modal} fade={false} toggle={P_toggle}>
				        	<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={P_toggle} className="">
				        	<span className="m-auto text-color-gradient font-weight-bold">Assign Project</span></ModalHeader>
				        	<ModalBody>
		              			<form className="form m-auto col-10" onSubmit={Assign_P}>
						          	<div className="form-group mt-3">
						          	<br/>
						          		{/*<select value={value} onChange={e => setValue(e.currentTarget.value) } className="form-control mt-3">
						          		<option className="text-muted" disable>Select</option>
						          		{user_list.length
						          			?
						          			user_list.map(data=>(
						          				<option className="text-dark" value={data.id}>{data.employee_name}</option>
						          				))
						          			:
						          			null
						          		}
						          		</select>*/}
						          		<Select
												className="input-focus mt-3"
											    closeMenuOnSelect={false}
											    isMulti
											    options={UserOptions[0]}
											    onChange={handleChange}
											  />
						          	</div>
						          	<div className="form-group m-auto">
						          		<button type="submit" className="btn btn-primary col-12 mt-3">Submit</button>
						          	</div>
						          	<br/><br/><br/>
						        </form>
		         		 	</ModalBody>
		        		</Modal>

		        		{/* Project Status */}
				        <Modal  className="text-align-center" isOpen={EA_modal} fade={false} toggle={EA_toggle}>
				        	<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={EA_toggle} className="">
				        	<span className="m-auto text-color-gradient font-weight-bold">Change Project Status</span></ModalHeader>
				        	<ModalBody>
		              			<form className="form m-auto col-10" onSubmit={Change_status}>
						          	<div className="form-group mt-3">
						          	<br/>
						          		<select value={value} onChange={e => setValue(e.currentTarget.value) } className="form-control mt-3">
						          		<option className="text-muted" disable>Select</option>
						          		<option className="text-dark" value="True">Complete</option>
						          		<option className="text-dark" value="False">Incomplete</option>
						          		{/*<option className="text-dark" value={data.id}>{data.employee_name}</option>*/}

						          		</select>
						          	</div>
						          	<div className="form-group m-auto">
						          		<button type="submit" className="btn btn-primary col-12 mt-3">Submit</button>
						          	</div>
						          	<br/><br/><br/>
					        	</form>
		         		 	</ModalBody>
		        		</Modal>		

				         <div className="col-md-12 col-sm-12 col-12">
				             <div className="row ">
				                <div className="col-lg-12">
				                     <div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
					                     {/*<BootstrapTable
					                     keyField="id"
					                     data={ project_list }
					                     columns={ columns }
					                     headerClasses="event-head"
					                     pagination={ paginationFactory(options) }
					                     bordered={ false }	
					                     selectRow={ selectRow }								
					                     />*/}
					                     <ToolkitProvider
								          	keyField="id"
					                     	data={ project_list }
					                     	columns={ columns }
					                     	headerClasses="event-head"
					                     	pagination={ paginationFactory(options) }
								          	search
								        >
								          {
								            toolkitprops => (
								              <div>
								                <SearchBar { ...toolkitprops.searchProps } />
								                <BootstrapTable
								                	bordered={ false }
								                	selectRow={ selectRow }
								                	pagination={ paginationFactory(options) }	
								                  { ...toolkitprops.baseProps }
								                />
								              </div>
								            )
								          }
								        </ToolkitProvider>
				                  	</div>
				               	</div>
				            </div>
				         </div>
			      	</div>
			      <hr className="my-5"/>
			  	 <Footer/>
			   </div>
			   }
			   <Modal isOpen={edit_modal} toggle={edit_toggle}>
		        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_toggle}>
		        	<span className="m-auto text-color-gradient font-weight-bold">Edit Project</span>
		        </ModalHeader>
		        <ModalBody>
					<form className="form m-auto col-8 p-4" onSubmit={editProject}>
		          		<div className="form-group mt-3">
			          		<input className="form-control m-auto input-focus" placeholder="Enter Project Title" 
		          				type="text" name="p_title" value={p_title} 
		          				onChange={e => setTitle(e.target.value)} autoFocus
		          				ref={inputElement}
		          				required
			          		/>
		          			<button type="submit" className="btn btn-primary col-12 mt-2">Edit Project</button>
		          		</div>
	        		</form>		          
		        </ModalBody>
		      </Modal>
		      <Modal isOpen={edit_status} toggle={edit_status_toggle}>
		        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_status_toggle}>
		        	<span className="m-auto text-color-gradient font-weight-bold">Enter Task Status</span>
		        </ModalHeader>
		        <ModalBody>
					<form className="form m-auto col-8 p-4" onSubmit={submitStatus}>
		          		<div className="form-group mt-3">
			          		<input className="form-control m-auto input-focus" placeholder="Incomplete / Completed" 
		          				type="text" name="p_title" value={task_status} 
		          				onChange={e => setTaskStatus(e.target.value)} autoFocus
		          				ref={inputElement}
		          				required
			          		/>
		          			<button type="submit" className="btn btn-primary col-12 mt-2">Apply Filter</button>
		          		</div>
	        		</form>		          
		        </ModalBody>
		      </Modal>
		      <Modal isOpen={edit_custom} toggle={edit_custom_toggle}>
		        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_custom_toggle}>
		        	<span className="m-auto text-color-gradient font-weight-bold">Enter Date</span>
		        </ModalHeader>
		        <ModalBody>
					<form className="form m-auto col-8 p-4" onSubmit={submitCustomDate}>
		          		<div className="form-group mt-3">
			          		<input className="form-control m-auto input-focus" placeholder="Enter date" 
		          				type="date" name="p_title" value={custom_date} 
		          				onChange={e => setCustomDate(e.target.value)} autoFocus
		          				ref={inputElement}
		          				required
			          		/>
		          			<button type="submit" className="btn btn-primary col-12 mt-2">Apply Filter</button>
		          		</div>
	        		</form>		          
		        </ModalBody>
		      </Modal>
			   <br/>
			   <br/>
			</Fragment>
			)
		}

export default Company_projects;

							