import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import '../css/Employee.css'
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import FadeLoader from "react-spinners/FadeLoader";
/*import ProgressBar from 'react-progressbar';
*/import ProgressBar from 'react-bootstrap/ProgressBar'
import Swal from 'sweetalert2'
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers"
import LazyLoad from 'react-lazy-load';
import {
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";
import {Helmet} from 'react-helmet'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Footer from "../Footer"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';


class Emp_screenshots extends React.Component {
	constructor(props) {
		super(props)
		const user_id=this.props.location.state.User_id	
		this.state = {
			all_images:[],
			emp_month:"",
			emp_week:"",
			emp_today_date:"",
			emp_today_hrs:['00','00','00'],
			ip_address:localStorage.ip_address,
			hourly_img:[],
			user_id:user_id,
			NewTime:'',
			isLoading:true,
			emp_name:""	,
			emp_Email:'',
			next_prev_img:[],
			img_path:null,
			img_modal:false,
			startDate:new Date(),
			date_modal:false,
		}
		this.setHourlyImg=this.setHourlyImg.bind(this)
		this.removeImg=this.removeImg.bind(this)
		this.openPopupbox1=this.openPopupbox1.bind(this)
	}
	

	componentDidMount(){
		const head={
				 headers:{"company_id":localStorage.company_id,
				 "username":localStorage.username,"token":localStorage.token,
				  'Content-Type':'application/json'}
				 }
		axios.get(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',head)
		  .then((response) => {
		    console.log("axios reponse",response.data);
		    this.setState({
				all_images:response.data.Images,
				emp_name:response.data.Name,
				emp_Email:response.data.Email,
				emp_month:response.data.This_Month,
				emp_week:response.data.This_Week,
				emp_today_date:response.data.Date,
				emp_today_hrs:response.data.Today.split(":"),
			})

			this.setHourlyImg()
		    
		  }).catch(error => {
    console.log(error)
  })

		/*Get all the images of an employee,name and its email*/
		/*fetch(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',{
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
		})
		.then(resj=>resj.json())
		.then(res=>{
			
			console.log("user_detail",res)
			this.setState({
				all_images:res.Images,
				emp_name:res.Name,
				emp_Email:res.Email,
				emp_month:res.This_Month,
				emp_week:res.This_Week,
				emp_today_date:res.Date,
				emp_today_hrs:res.Today.split(":"),
			})
			this.setHourlyImg()
		})*/
		/*Get screen shot capture time set by company*/
		fetch(this.state.ip_address+'/set_shot_time/',{
			headers:{"company_id":localStorage.company_id,"username":localStorage.username,"token":localStorage.token, 'Content-Type':'application/json'}
		})
		.then(res=>res.json())
		.then(resJson=>{
			resJson ? this.setState({NewTime:resJson.screenshot_time}):alert("Error in getting Time")			
		}).catch(err=>console.log(err))
			
	}

	setHourlyImg=()=>{
		/*Filter and store the image on houly basis of today's Images*/
		let date = new Date().getDate();
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
 
      /*alert(date + '-' + month + '-' + year);*/
      if(date<10){
      	date=0+String(date)
      }
      if(month<10){
      	month=0+String(month)
      }
		
		let array=[]
		let today=this.state.all_images.filter(data=> data.date==String(date) && data.month==String(month) 
			&& data.year==String(year) && parseInt(data.hour)>=0 )						
		/*console.log("check after date",today)*/
		for(let h=24; h>=0; h--)
		{
			let temp=today.filter(value=>value.hour==h)
			if(temp.length)
			{
				array.push(temp)
			}
		}
			
		if(array.length){
			this.setState({
				hourly_img:array,
				isLoading:false
			},()=>{
				/*console.log("check",this.state.hourly_img)*/
				let arr=[]
				for(let i=0; i<this.state.hourly_img.length; i++ ){
/*					console.log("check",i,this.state.hourly_img[i])
*/					for(let j=0; j<this.state.hourly_img[i].length; j++){
						arr.push(this.state.hourly_img[i][j].image)
					}
				}/*console.log("completed img",arr)*/
				this.setState({next_prev_img:arr})
			})

		}else{
			Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: "There is no screen shots of today",
					icon: 'warning',
					showConfirmButton: false,
				})
			this.setState({
				isLoading:false
			})
		}
	}
		

	/*Function to show the filter page for viewing date wise filter images*/
	DateWiseFilter=(val_date)=>{
		/*console.log("val_date",val_date,this.state.all_images)*/
  		this.props.history.push({pathname: '/FilterImg',state: {User_id:val_date,all_images:this.state.all_images }})
	}
		
	openPopupbox=(path)=> {
		this.setState({img_path:path},
			()=>{this.img_toggle()})
	   
	}

	openPopupbox1=(path)=> {
		this.setState({img_path:path})
	   
	}

	img_toggle=()=>{
		this.setState({img_modal:!(this.state.img_modal)})
	}

	removeImg=(Img_id)=>{
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You want to delete this Screenshot!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		  if (result.isConfirmed) {
		    fetch(this.state.ip_address+'/delete_shot_image/'+Img_id+'/',{
	  	headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
	  	})
		.then(res=>res.json())
		.then(resJson=>{
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Screenshot deleted',
				icon: 'info',
				showConfirmButton: false,
			})	
			/*console.log(resJson.message)*/
			fetch(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',{
				headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
			}).then(resj=>resj.json())
			  .then(res=>{
					/*console.log("set images",res.Images)*/
					this.setState({all_images:res.Images})
					this.setHourlyImg()
				})
		}).catch(err=>{			
			console.log(err)
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Error',
				icon: 'error',
				showConfirmButton: false,
			})
		})}
		}) 		
	}

	date_toggle = () =>{
		if(this.state.date_modal){
			this.setState({date_modal: false})
		}
		else{
			this.setState({date_modal: true})
		}
	}

	onFormSubmit =(e)=> {
	    e.preventDefault();

	    /*console.log(localStorage.b_os.split(" ")[0])*/

	    if(localStorage.browser=="firefox" || localStorage.b_os.split(" ")[0]=="Windows"){
		    var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			if(parseInt(date[1])<10){
				date[1]=0+date[1]
			}
			if(parseInt(date[0])<10){
				date[0]=0+date[0]
			}

			console.log(date[2]+'-'+date[1]+'-'+date[0] )
			/*console.log(this.state.User_id)*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.user_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[0]+'-'+date[1]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({filter_day_hours: resJson.today_work.split(":"),
								   emp_today_date: this.state.startDate.toDateString()})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[1],month:date[0],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[1] && (data.month)==date[0] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
				},()=>{
					let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
				})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    }
	    else{
	    	// console.log(this.state.startDate)
	    	var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			/*console.log(typeof(date[2]+'-'+date[1]+'-'+date[0]))*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.user_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[1]+'-'+date[0]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({emp_today_hrs:resJson.today_work.split(":"),
				 				   emp_today_date: this.state.startDate.toDateString()})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[0],month:date[1],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[0] && (data.month)==date[1] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
					},()=>{
						let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
					})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    } 
		this.date_toggle()
	}

	handleChange=(date) =>{
		console.log(date)
	    this.setState({
	      startDate: date
	    })
	}

	handleClick = (event) =>{
	  event.preventDefault();
	  console.log('You clicked a breadcrumb.');
	  this.props.history.push("/weekly-report")
	}

	render() {
		/*console.log("today hrs",this.state.emp_today_hrs)*/
		const {all_images,emp_month,emp_week,emp_today_date,emp_today_hrs,emp_Email}=this.state
		const {ip_address,hourly_img,user_id,NewTime,isLoading,emp_name,img_path}=this.state
		return (
			<Fragment>
			<Helmet>
				<meta http-equiv='cache-control' content='no-cache'/>
				<meta http-equiv='expires' content='0'/>
				<meta http-equiv='pragma' content='no-cache'/>
			</Helmet>
			   <div className="container ">
			   {	
			      !isLoading
			      ?
			     	 <Fragment>
					  	<div className="card-group">
					        <div className="card m-3">
					            <div className="card-body ">
					               <h3 className="card-title text-center mt-1 text-color  font-weight-bold">{emp_today_date}</h3>
					               <hr className='w-75 mx-auto'/>
					               <h1 className="card-subtitle mb-2 text-center text-muted" id="emp_today_hrs">{emp_today_hrs[0]}<small>Hrs</small>:{emp_today_hrs[1]}<small>Min</small>:{emp_today_hrs[2]}<small>Sec</small></h1>
					               <p className="card-text text-center text-muted">This Week:{emp_week} &nbsp; = &nbsp; This Month {emp_month}</p>
					            </div>
					        </div>
					        <div className="card m-3">
					            <div className="card-body">
					               <h3 className="card-title text-center mt-1 text-color  font-weight-bold">Note</h3>
					               <hr className='w-75 mx-auto' />
					               <ol className='pl-0'>
					                <li>Name:-{emp_name}</li>
					                <li>Screen shot captures in every 
					                  	<span className='text-color font-weight-bold'>
					                  		{NewTime}min
					                  	</span>
					                </li>
					                  <li>Email:{emp_Email}</li>
					               </ol>
					            </div>
					        </div>
			      		</div>
			      		<div className="d-flex flex-row">
			      			<Breadcrumbs aria-label="breadcrumb">
			      				<Typography color="primary">Menu</Typography>
								<Link color="primary" className="text-color" style={{cursor:'pointer'}} onClick={this.date_toggle}>
									Show Date wise filter
								</Link>
								<Link color="primary" className="text-color" style={{cursor:'pointer'}} onClick={this.handleClick}>
									Weekly Report
								</Link>
							</Breadcrumbs>
						    {/*<div className="col-6 mt-4">
						    	<a className="text-color"  style={{cursor:'pointer'}}>View Weekly Report</a>
						    </div>
						    <div className="col-6 mt-4">
						    	<i className="fa fa-search ml-2 text-color" aria-hidden="true"></i>
						    	{/*<a className="text-color"  style={{cursor:'pointer'}} onClick={()=> this.DateWiseFilter()}>Show Date wise filter</a>*/}
						        {/*<a className="text-color"  style={{cursor:'pointer'}} onClick={this.date_toggle}>Show Date wise filter</a>
						    </div>*/}
						</div>
			     	</Fragment>
			      :
			      null
			  }
			    {	
			      isLoading
			      ?
			      <div className="container loader m-auto">
			         {
			         <center className="mt-5 pt-5 " id="loader_position">
			            <FadeLoader
			               css=""
			               height={15}
			               width={5}
			               radius={2}
			               margin={2}
			               color={"black"}
			               loading={isLoading}    			         
			               />
			         </center>
			         }				
			      </div>
			      :
			      hourly_img.length
			      ?
			      hourly_img.map((data,index)=>(
			      	<Fragment key={index+"500"}>
			        	<h2 className="text-color text-center font-weight-bold mb-3">
			        		{data[0].hour}:00{data[0].mode} to {parseInt(data[0].hour)+1}:00{data[0].mode}
			        	</h2>
			        	<div className="col-md-12 mb-4">
			            	<hr className="w-25 m-auto" style={{backgroundColor:'#1976D2'}} />
			         	</div>
				        <div className="row text-center text-lg-left">
				            {
				            data.map((value,i)=>(
				            <div key={value.mode+i} className="col-lg-3 mb-3 col-md-4 col-6">
				               <div className='px-1 my-0 pb-2 card' style = {{cursor: 'pointer'}}>
					               <small className="">{value.hour}:{value.min}{value.mode}&nbsp;&nbsp;
					               	<i onClick={()=>this.removeImg(value.id)} 
					               		className="float-right pt-1 fa fa-trash delete-icon" aria-hidden="true">
					               	</i>
					               	</small>
					               <a onClick={()=>{this.openPopupbox(value.image)}} className="d-block mb-2 h-100">
					               <LazyLoad>
					               <img loading="lazy" height='150' width='300' className="img-fluid img-thumbnail" src={value.image} alt="not available"/>
					              	</LazyLoad>
					               </a>
					               {
					               parseInt(value.activity)>60
					               ?
					               <span  className="px-3">
					                  <ProgressBar variant="success"  now={parseInt(value.activity)} label={`${value.activity}%`} />
					               </span>
					               :
					               <span  className="px-3">
					                  <ProgressBar variant="danger"  now={parseInt(value.activity)} label={`${value.activity}%`} />
					               </span>
					               }
					            </div>
				        	 </div>
				         	))
				         }
				         <br/>
				         <div   className="btn btn-sm col-md-12 my-2"></div>
				   		</div>
				   </Fragment>	
			   	))
			      :
			      <h3 className="text-center text-primary">No screenshot available for {emp_today_date}</h3>
			   }
			   </div> 
			    <hr className="my-5"/>
			  	 <Footer/>
			   {/*Image modal show magnify*/}
	    	 <Modal   size="lg" style={{maxWidth: '1400px', width: '80%',margin:'auto'}}
 				className="text-align-center" isOpen={this.state.img_modal} fade={false} toggle={this.img_toggle}>
		          	<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} 
			          toggle={this.img_toggle} 
			          className="font-weight-bold text-align-center">
		          	</ModalHeader>
		          	<ModalBody className="pt-0">
						<div className="container  bg-transparent">
						{ 
							this.state.img_path!=null
							?
							<div className="w-90 m-auto">
							    <Magnifier
									  imageSrc={img_path}
									  imageAlt="image not found"
									  largeImageSrc={img_path}// Optional
									  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} // Optional
									  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} // Optional
								/>
					    	{/*<img className="text-styles img-fluid mb-2"  height='550'  src={this.state.ip_address+path} alt="Image not found"/>*/}
					    	{	
					    		this.state.next_prev_img.indexOf(img_path)!=0
								?
						    	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)-1])} 
						    	className="btn btn-primary mr-5">
						    		<i className=" fas fa-angle-left  text-white">-Prev</i>
						    	</span>
						    	:
						    	null
						    	}
							    {	
						    		this.state.next_prev_img.indexOf(img_path)!= this.state.next_prev_img.length-1
									?
								   	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)+1])} 
								   	className="btn btn-primary float-right" >
								   		{/*console.log(this.state.next_prev_img.indexOf(path))*/}
								   		Next-<i className="fas fa-angle-right  text-white"></i>

									</span>
									:
							    	null
						    }
						    </div>
			          		:
			          		null
						}
						</div>
			       </ModalBody>
	        	</Modal>
	        	<Modal className="text-align-center" isOpen={this.state.date_modal} fade={false} toggle={this.state.date_toggle}>
		            <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.state.date_toggle} className="font-weight-bold text-align-center"><span className="m-auto">Select Date</span></ModalHeader>
		            <ModalBody>
			            <form onSubmit={this.onFormSubmit}>
			                <div className="modal-body">
			                    <div className="form-group ">
			                        <center>
			                            <DatePicker
			                              className="form-control w-100"
			                              onChange={(e)=>this.handleChange(e)}
			                              selected={this.state.startDate}
			                              dateFormat="MMMM d, yyyy "							        
			                             />
			                        </center>
			                        <br/>
			                        <center><button type="submit" className="btn col-6 btn-primary">Show</button>
			                        </center>
			                    </div>
			                </div>
		                  {/*
		                  <div className="modal-footer border-top-0 d-flex justify-content-center">
		                     <br/>
		                     <button type="submit" className="btn btn-primary col-md-4 mt-0">Submit</button>
		                  </div>
		                  */}
		               	</form>
		            </ModalBody>
	        	</Modal>
			   {/*<PopupboxContainer />*/}
			   <ScrollUpButton style={{backgroundColor: '#1976D2'}} /> 
			</Fragment>
		)
	}
}

export default Emp_screenshots