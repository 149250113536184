import React, { Component, Fragment,useState,useEffect } from 'react';
import ReactDom from 'react-dom';
import '../css/Add_Employee.css';
import history from '../history';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function Add_Employee (){

	const history = useHistory()
	const [password1, setPass1] = useState('')
	const [password2,setPass2]=useState('')
	const [csrftoken,setCsrf]=useState('')
	const [email,setEmail]=useState('')
	const [total_registered,setReg]=useState([])

	/*constructor(props){
	  super(props);
	  this.state = {
	  	username :'',
	  	password1 : '',
	  	password2 : '',
	  	csrftoken : '',
	  	message : '',
	  	login_id:'',
	  	company_msg:'',
	  	email:'',
	  	total_registered:[],
	  }
	  this.handleSubmit = this.handleSubmit.bind(this);
	}*/

/*	const onChange = event => ({[event.target.name]:event.target.value});
*/
	useEffect(() => {
		/*fetch(localStorage.ip_address+'/get_csrf_token/')
		.then((res) => res.json())
		.then((resJson)=>{
			this.setState({csrftoken : resJson.csrfToken})
	   })
	   .catch((error) => {
	     console.log("csrf error")
	   });*/

	   fetch(localStorage.ip_address+'/invitation_list/', {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			})
	   .then(res=>res.json())
	   .then((resJson)=>{
	   	setReg(resJson)	   		
	   	})
		
	}, [])

	const handleSubmit=(event)=>{ 

			event.preventDefault();
			
			fetch(localStorage.ip_address+'/send_invite/', {
			method: 'post',
			headers: {"X-CSRFToken": csrftoken,'Content-Type':'application/json'},
			body: JSON.stringify({
			   	"email": email
			   })
			}).then((response) => response.json())
			.then((responseJson) => {
				/*console.log("response of register type",responseJson)*/
				if(responseJson.message=="Invite send"){
					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: 'Invite send to ' +email,
						icon: 'success',
						showConfirmButton: false,
					})
					setEmail({email: ''})
				}
				else if(responseJson.message=="Already Invited")	
				{
					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: 'Already Invited',
						icon: 'success',
						showConfirmButton: false,
					})
					setEmail('')
				}				   
			})
			.catch((error) => {
/*			 alert("Invalid login detail");
*/			 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: 'Invalid login detail',
						icon: 'error',
						showConfirmButton: false,
					})
			 console.log(error)
			});
		
	 }

	 const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);

	 const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a href="#" value={ cell.id } >{cell.is_active?"True":"False"}</a>
	  	);
	}

	 const columns = [{
	  dataField: 'no',
	  text: 'S.No.',
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle'
	},{ 
	  dataField: 'email',
	  text: 'Email',
	  headerAlign: 'center'	,
	  align:'center',
	  classes: 'align-middle'
	}, {
	  dataField: 'send_at',
	  text:'Date',
	  headerAlign: 'center',
	  align:'center',
	},
	 {
	  dataField: 'is_active',
	  text:'Active',
	  formatter: actionFormatter,
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle'
	}
	];

	const options = {
	  	totalSize: total_registered.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: total_registered.length
		}] 
	};


	return(
		<Fragment>
			<div className="container">
				<div className="row ">
					<div className="col-12">
						<a className="float-right nav-link mb-5" 
							style={{cursor:'pointer',textDecoration: 'underline'}} 
							onClick={()=>{history.push('/invitation')}}>
							 Send invitation
						</a>
					</div>
					<br/>
					<br/>
					<br/>
					<div className="col-lg-12">
						<div className="table-responsive" style={{maxHeight:'700px'}}>
							<BootstrapTable
								keyField="id"
								data={ total_registered }
								columns={ columns }
								headerClasses="event-head"
								pagination={ paginationFactory(options) }
								bordered={ false }									
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Add_Employee;
				