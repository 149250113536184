import React, {Component, Fragment} from 'react';
import Footer from "../Footer"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

const downloadFormatterSn=(e, cell, row, rowIndex)=>{
	return(
			<a className="table-content-font pl-2"  
				style={{color:'#206090'}} value={ cell.id } href={cell.link} >
				<i className="fa fa-download"></i>
			</a>
		)
}

class EmployeeArchive extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			data:{
			  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			  datasets: [
			    {
			      label: 'Weekly Report',
			      fill: false,
			      lineTension: 0.1,
			      backgroundColor: 'rgba(75,192,192,0.4)',
			      borderColor: 'rgba(75,192,192,1)',
			      borderCapStyle: 'butt',
			      borderDash: [],
			      borderDashOffset: 0.0,
			      borderJoinStyle: 'miter',
			      pointBorderColor: 'rgba(75,192,192,1)',
			      pointBackgroundColor: '#fff',
			      pointBorderWidth: 1,
			      pointHoverRadius: 5,
			      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
			      pointHoverBorderColor: 'rgba(220,220,220,1)',
			      pointHoverBorderWidth: 2,
			      pointRadius: 1,
			      pointHitRadius: 10,
			      data: [65, 59, 80, 81, 56, 55, 40]
			    }
			  ]
			},
			data_val: [],
			columns: [
			  { dataField: 'id',
			  	text: 'S.No.',
			  	headerAlign: 'left',
			  	align:'left',
			  	classes: 'align-middle',
			  	headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			  { dataField: 'employee_name',
			  	text: 'Employee Name',
			  	headerAlign: 'center',
			  	align:'center',
			  	classes: 'align-middle',
			  	headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			  { dataField: '',
			  	text: 'Download Excel',
			  	headerAlign: 'center',
			  	align:'center',
			  	formatter: downloadFormatterSn,
			  	classes: 'align-middle',
			  	headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			],
			options: {
			    totalSize: 4,
				paginationSize: 4,
				pageStartIndex: 1,
				firstPageText: 'First',
				prePageText: 'Back',
				nextPageText: 'Next',
				lastPageText: 'Last',
				nextPageTitle: 'First page',
				prePageTitle: 'Pre page',
				firstPageTitle: 'Next page',
				lastPageTitle: 'Last page',
				showTotal: true,
				disablePageTitle: true,
				sizePerPageList: [{		
				text: '5', value: 5
				}, {
				text: '10', value: 10
				}, {
				text: 'All', value: 4
				}] 
			}
		}
	}

	componentDidMount(){
		fetch(localStorage.ip_address+"/archive_list/")
		.then(response=> response.json())
		.then((resJson=>{
			console.log(resJson)
			this.setState({data_val: resJson.emp_list})
		}))
	}

	render(){
		return(
			<Fragment>
				<h3 className="text-primary text-center mt-3">Employee Archive List</h3>
				<div className="col-lg-12 mt-3">
					<div className="table-responsive col-lg-12" style={{maxHeight:'700px'}}>
						<ToolkitProvider
				          	keyField="id"
		                    data={this.state.data_val}
		                    columns={ this.state.columns }
		                    headerClasses="event-head"
		                    pagination={ paginationFactory(this.state.options) }
		                    bordered={ false }	
				          	search
				        >
				          {
				            toolkitprops => (
				              <div>
				                <SearchBar { ...toolkitprops.searchProps } />
				                <BootstrapTable
				                	bordered={ false }
				                	pagination={ paginationFactory(this.state.options) }	
				                  { ...toolkitprops.baseProps }
				                />
				              </div>
				            )
				          }
				        </ToolkitProvider>
					</div>
				</div>
				<hr className="my-5"/>
				<Footer />
			</Fragment>
		)
	}
}

export default EmployeeArchive;