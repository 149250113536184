import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from "./Homepage/index.js"
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router} from 'react-router-dom';
import history from "./history"
import * as Ip_address from "./Ip_address"



	

localStorage.setItem('ip_address',Ip_address.ip_address)


		/*This renders when only website url enters */
		ReactDOM.render(
		<Router history={history}>
		  		<Homepage/>
		  	</Router>
		  ,
		  document.getElementById('root')
		);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
