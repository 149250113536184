import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard'
import Employee from './components/Employee'
import Add_Employee from './components/Add_Employee'
import Change_Password from './components/Change_Password'
import Company_list from "./components/Company_list"
import Logout from './components/Logout'
import nav_logo from './images/nav_logo.jpeg'
import {Helmet} from 'react-helmet'
import FilterImg from './components/FilterImg'
import Settings from './components/Settings'
import Invite from "./components/Invite"


function App() {
  return (
    <div className="App">
      <Router >
          <div >
            <Helmet>
              <meta name="description" content = 'wecome to my web app'/>
              <link rel="shortcut icon" href={nav_logo} /> 
            </Helmet>
            <nav id="navigation-bar" className="navbar navbar-expand-lg navbar-dark nav-bg-color" >
               <a className="navbar-brand" href="/"><img className="logo_attribute" src={nav_logo} alt="not found" />  </a>
             
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="text-white navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                      <li className='nav-item'><Link to={'/'} className="nav-link"> Home </Link></li>
                      <li className='nav-item'><Link to='/add_employee' className="nav-link">Employee</Link></li>
                      <li className='nav-item'><Link to='/company_list' className="nav-link">Companies</Link></li>
                      <li className="nav-item dropdown ">
                         <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Profile
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left:'-92px'}}>
                           <Link to={'/logout'}><p className='dropdown-item pb-0 mb-0'> Logout</p></Link>                   
                           <hr className="m-0"/>
                            <Link to={'/invitation'}><p className="dropdown-item pb-0 mb-0" >Send Invitation</p></Link>
                          
                           <hr className="m-0"/>
                            <Link to={'/settings'}><p className="dropdown-item pb-0 mb-0" >Settings</p></Link>
                            <hr className="m-0"/>
                            <Link to={'/Change_Password'}><p className="dropdown-item pb-0 mb-0" >Change Password</p></Link>
                          </div>
                      </li>
                   </ul>
                </div>
          </nav>
          <br/>
          <Switch>
             <Route exact path='/' component={Dashboard} />             
             <Route  path='/add_employee' component={Add_Employee} />
             <Route  path='/employee' component={Employee}/>
             <Route path='/logout' component={Logout}/>
             <Route path='/FilterImg' component={FilterImg} />
             <Route path='/settings' component={Settings} />
              <Route path='/company_list' component={Company_list}/>
              <Route path='/Change_Password' component={Change_Password} />
              <Route path='/invitation' component={Invite}/>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
