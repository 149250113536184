import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import signup_logo from "../images/signup_logo.png"
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'

const Dashboard=()=> {
	const history = useHistory()
	const [emp_list, setEvent] = useState([])
	const [active_count,setCount]=useState([])
	const [isLoading, setLoading] = useState(true)
	const [total_act_today,setTotalAT]=useState('')
	/*const [Today_Total_work,setTotalWT]=useState('')
	const [Yesterday_Total_Work,setTotalWY]=useState('')
	const [This_month_Total_Work,setTotalWTM]=useState('')
	const [This_week_Total_Work,setTotalWTW]=useState('')*/

	useEffect(()=>{
		let count=[]
		fetch(localStorage.ip_address+'/users_list/',{
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				setEvent(resJson)
				/*console.log(resJson)*/
				setLoading(false)
				count=resJson.filter(data=>data.Online==true);
		  		setCount(count)
			}).catch((error)=> {
				console.log(error)
			})


		fetch(localStorage.ip_address+'/today_login_count/',{
            method: 'post',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            })
		  .then(response => response.json())
		  .then((jsonResponse) => {	
		  	/*console.log("today login count",jsonResponse)*/	 
		  		setTotalAT(jsonResponse.Today_Login)
		  		/*setTotalWT(jsonResponse.Today_Total_work)
		  		setTotalWY(jsonResponse.Yesterday_Total_Work)
		  		setTotalWTM(jsonResponse.This_month_Total_Work)
		  		setTotalWTW(jsonResponse.This_week_Total_Work)*/
		  		/*setTotalAT(jsonResponse)*/
		  	/*console.log(jsonResponse)*/
		  }).catch((error) => {
	  	     console.log('not data found')
	  	   });
	},[])

	 const detail=(id)=>{
	 	fetch(localStorage.ip_address+'/user_detail/'+id+'/',{
	 		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
	 	})
		  .then(response => response.json())
		  .then((jsonResponse) => {
		  	/*console.log("User Details",jsonResponse)*/
		  	if(jsonResponse.Images.length){	
		  		history.push({pathname: '/employee',state: { Data: jsonResponse,User_id:id }})
		  	}
		  	else{
		  		Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'No screenshot of this employee is available Now',
					icon: 'info',
					showConfirmButton: false,
				})

		  	}
		  	}).catch(error=>{
		  		console.log(error)
		})	
	}
		  		


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);

	const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a href="#" onClick={()=>{detail(cell.id)}} value={ cell.id } >{cell.username.split('@')[0]}</a>
	  	);
	}

	const handleImage=(cell,row)=>{
		return (
			cell.length
			?
	     	 <img  loading="lazy" value={cell.id} className="dashboard_img" width='156' height='96' alt="Not available" src={localStorage.ip_address+ cell} />
		  	:
	     	 <img loading="lazy"  className="dashboard_img text-center" width='109' height='75' alt="Not available" src={signup_logo} />
	  	);
	}

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'center',
	  align:'center',
	  classes: 'align-middle'
	},{ 
	  dataField: 'username',
	  text: 'Employee',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: actionFormatter,
	  classes: 'align-middle'
	}, {
	  dataField: 'images',
	  text:'LastActive',
	  headerAlign: 'center',
	  align:'center',
	  formatter: handleImage
	}, {
	  dataField: 'Time.Today',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Today',
	  classes: 'align-middle'
	}, {
	  dataField: 'Time.Yestarday',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Yesterday',
	  classes: 'align-middle'
	}, {
	  dataField: 'Time.This_Week',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Week',
	  classes: 'align-middle'
	}, {
	  dataField: 'Time.This_Month',
	  headerAlign: 'center',
	  align:'center',
	  text: 'Month',
	  classes: 'align-middle'
	}
	// ,{
	//   dataField: '',
	//   headerAlign: 'center',
	//   align:'center',
	//   text: 'View',
	//   formatter: actionFormatter
	// }
	];

	const options = {
	  	totalSize: emp_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: emp_list.length
		}] 
	};

	return(
		<Fragment>
			{
				isLoading				
				?
				<div className="container loader m-auto">					
				     {
				     <center className="mt-5 pt-5 " id="loader_position">
					     <FadeLoader
	     			          css=""
	     			          height={15}
	     			          width={5}
	     			          radius={2}
	     			          margin={2}
	     			          color={"black"}
	     			          loading={isLoading}    			         
	     			        />
     			    </center>}				
				</div>
				:
				<div className='container-fluid'>
					<div className='row'>
					<div className="col-12">
						<a className="float-right nav-link text-primary" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={()=>{history.push('/invitation')}}>Send invitation</a>
					</div>
					<br/>
					<br/>

					<div id="online_card" className="card px-0 pt-0 col-md-3 mt-0 " style={{maxHeight:'700px'}}>
					    <h6 className='p-3' style={{fontSize: '15px'}}> <span className="text-success" >{active_count.length}</span> <span className='text-muted text-small'>Active Now</span> <span className='text-danger'>{total_act_today}</span> <span className='text-muted'>Total Worked </span></h6>
						{/*<hr className='my-0 w-100 mb-2'/>*/}
						{/*<div className="card-body px-0">*/}
						<div className="scrollable" style={{maxHeight:'400px',overflowY:'auto'}}>
								
							<div>
								<br/>
								<div className="card-text pl-3 pr-3">
									{
										emp_list.map((data)=>(
										<div className="mb-3 py-2 light_bg online_list" onClick={()=>{detail(data.id)}} key={data.username}>
										{
											data.Online 
											? 
											<span className='text-success col-3' style={{filter: "contrast(0) sepia(1) saturate(100) hue-rotate(133deg)" }} >&#127761;</span>
											:
											<span className='text-danger col-3' style={{filter: "sepia(1) saturate(100)"}} >&#127761;</span>
										}
										<span className="point p-2" >{data.username.split('@')[0]}</span>
										
										</div>
										))
									}
								</div>
							</div>
							<p className='text-right text-muted mr-4'>{active_count.length}/{emp_list.length}</p>
						</div>
						<br/>
						<hr className="mb-0"/>
						{/*<div height="200" className="card background_color mt-3 text-white">							
												
							<h6 className="font-weight-bold">Total Working Hours</h6>
							<hr className="mt-0 bg-white"/>
							<ol className="ml-0 pl-0">
							<li><div className="text-small">{Today_Total_work}:<i>Today .</i></div> </li>
							<li><div className="text-small">{Yesterday_Total_Work}:<i>Yesterday.</i></div></li>
							<li><div className="text-small">{This_month_Total_Work}:<i>This Month.</i></div></li>
							<li><div className="text-small">{This_week_Total_Work}:<i>This Week.</i></div></li>
							</ol>							
						</div>*/}
					</div>
					<div className="col-md-9 col-sm-12 col-12">
						<div className="row ">
							<div className="col-lg-12">
								<div className="table-responsive" style={{maxHeight:'700px'}}>
									<BootstrapTable
										keyField="id"
										data={ emp_list }
										columns={ columns }
										headerClasses="event-head"
										pagination={ paginationFactory(options) }
										bordered={ false }									
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			}

		</Fragment>
	)
}

export default Dashboard;

							