import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"
import moment from "moment"
import axios from "axios"
import ReactTooltip from 'react-tooltip';
import {
  ModalHeader, 
  ModalBody,
  Modal, 
} from "reactstrap";

import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const { SearchBar } = Search;

const Company_tasks=(props)=> {
	const [project_name,setProjectname]=useState("")
	const [user_list,setUserdetail]=useState([])
	const history = useHistory()
	const [task_list, setProject_T_list] = useState([])
	const [isLoading, setLoading] = useState(true)
	
	const [ task_title,setTitle]=useState("")
	const [task_description,setDesc]=useState("")
	const [modal,setModal]=useState(false)
	/*const [ass_title,setAsstitle]=useState("")*/
	const [A_modal,setA_model]=useState(false)
	const [EA_modal,setEA_model]=useState(false)
	const [value,setValue]=useState("")
	const [assignT_id,setTask_id]=useState("")
	const [reassign,setReassign]=useState(false)
	const [selected,setSelected]=useState([])
	const [delete_selected,setDelete_ps]=useState("")
	const [edit_modal,setEditModal]=useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [edit_status, setEditStatus] = useState("");
	const [custom_date, setCustomDate] = useState("")
	const [task_status_val, setTaskStatus] = useState("");
	const [edit_custom, setEditCustom] = useState(false)

  	const dropdown_toggle = () => setDropdownOpen(prevState => !prevState);

  	const edit_status_toggle = () => setEditStatus(prevState => !prevState);

  	const edit_custom_toggle = () => setEditCustom(prevState => !prevState);

	useEffect(()=>{
		/*console.log("Com[p dashbpoard",localStorage.company_id)*/
		get_tasks()
		fetch(localStorage.ip_address+'/users_list/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"company_id":localStorage.company_id
				   })
		})
		.then((response)=> response.json())
		.then((resJson)=>{
			/*console.log("Users _list",resJson)*/
			setUserdetail(resJson)
			/*console.log(resJson)*/
			setLoading(false)
			
		}).catch((error)=> {
			setLoading(false)
			Swal.fire({
				position: 'top-end',
				timer: 2150,
				text: error,
				icon: 'Error',
				showConfirmButton: false,
			})
		})
	},[])

	const get_tasks=()=>{
		const head={
				 headers:{"company_id":localStorage.company_id,
				 "username":localStorage.username,"token":localStorage.token,
				  'Content-Type':'application/json'}
				 }
		axios.get(localStorage.ip_address+'/project_task_list/'+localStorage.p_task_id+'/',head)
		  .then((resJson)=>{
				setProject_T_list(resJson.data.Tasks)
				setProjectname(resJson.data.Project)
				setLoading(false)
			}).catch((error)=> {
				setLoading(false)
				Swal.fire({
					position: 'top-end',
					timer: 2000,
					text: 'Error in getting response',
					icon: 'error',
					showConfirmButton: false,
				})
			})

		/*fetch(localStorage.ip_address+'/project_task_list/'+localStorage.p_task_id+'/', {
			method: 'GET',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				setProject_T_list(resJson.Tasks)
				setProjectname(resJson.Project)
				setLoading(false)
			}).catch((error)=> {
				setLoading(false)
				Swal.fire({
					position: 'top-end',
					timer: 2000,
					text: 'Error in getting response',
					icon: 'error',
					showConfirmButton: false,
				})
			})*/
	}

	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
			Showing { from } to { to } of { size } Results
		</span>
	);

	const TaskTitle=(e, cell, row, rowIndex) =>{
		return (	  		
			<a className="table-content-font"  
				style={{color:'#206090'}} 
				value={ cell.Task_id } >
				  {cell.Title}
			</a>
		);
	}

	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font pl-2"
					style={{color:'#206090'}} 
					value={ cell.Task_id } >
					{cell.No}
				</a>
			)
	}

	const created_date=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"   
					style={{color:'#206090'}} 
					value={ cell.Task_id } >
					{moment(cell.created_at).format("MMM Do YYYY")}
				</a>
			)
	}

	const Formatworked_time=(e,cell,row,rowIndex)=>{
		return(
				<a className="table-content-font"   
					style={{color:'#206090'}} 
					value={ cell.Task_id } >
					{cell.Time}
				</a>
			)
	}

	const Show_task=(project_id)=>{
		history.push({pathname: '/Company_tasks',state: { project_id: project_id}})
		/*console.log("project_id",project_id)*/
	}

	const Task_list=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  onClick={()=>{Show_task(cell.id)}} style={{color:'#206090'}} value={ cell.id } >View</a>
			)
	}

	const assign_task=(e,cell,row,rowIndex)=>{
		return(

				<a className="table-content-font text-center point text-primary" 
				onClick={()=>{Assign_task(cell,"assigntask")}} >
				{/*<i class="fas fa-tasks"></i>*/}Assign
			 </a>
			)
	}

	const Assign_task=(cell,open="")=>{
		setTask_id(cell.Task_id)
		/*console.log(cell.Assigned_to)*/
		if(cell.Assigned_to==null && open=="assigntask"){
			setReassign(false)
			A_toggle()
		}
		else if(cell.Assigned_to!=null && open=="assigntask")
		{
			/*alert("Reassign task"+cell.Assigned_to)*/
			Swal.fire({
			  title: 'Are you sure?',
			  text: "You want to Reassign this task!",
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Yes!'
			}).then((result) => {
			  if (result.isConfirmed) {
				setReassign(true)
				A_toggle()
			  }
			  else{
				setLoading(false)
			  }
			})	
		}
		else if(open=="edit"){
			EA_toggle()
		}
		/*console.log(cell.Title,cell.Task_id)*/
		/*setAsstitle(t_title)*/
	}

	const task_status=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font point"  onClick={()=>{Assign_task(cell,"edit")}} style={{color:'#206090'}} value={ cell.id } >
				{
					cell.Completed ? <div className="mb-0">Completed&nbsp;<i class="fas fa-edit"></i>
					</div>:<p className="mb-0">Incomplete&nbsp;<i class="fas fa-edit"></i></p>}
				
				</a>
			)
	}

	const formatdelete_task=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font text-center point"  
					onClick={()=>{delete_task(cell.Task_id)}} 
					style={{color:'#206090'}} value={ cell.Task_id } >
					<i className="fas fa-trash"></i>
				</a>
			)
	}

	const formatedit_task=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font text-center point"  
					onClick={()=> edit_toggle(cell.Task_id)}
					style={{color:'#206090'}} value={ cell.Task_id } >
					<i className="fas fa-edit"></i>
				</a>
			)
	}
	

	const delete_task=(id)=>{
		setLoading(true)
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You want to delete this task!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		  if (result.isConfirmed) {
			/*console.log('deleted',id)*/
			fetch(localStorage.ip_address+'/delete_task/'+id+'/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				/*console.log("Users _list",resJson)*/
				if(resJson.message=="Task Deleted" && resJson.task_list)
				{
					setProject_T_list(resJson.task_list)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Your task has been deleted.",
						icon: 'success',
						showConfirmButton: false,
					})
					/*console.log(resJson)*/
					setLoading(false)
				}
				else{
					get_tasks()
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Can not delete at this moment.",
						icon: 'error',
						showConfirmButton: false,
					})
				}
				}).catch((error)=> {
					setLoading(false)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: error,
						icon: 'Error',
						showConfirmButton: false,
					})
					get_tasks()
				})
			
			}
			else{
				setLoading(false)
			}
		})

	}

					 
	const Format_taskassign=(e,cell,row,rowIndex)=>{
		return(
				<a className="table-content-font text-center "  
					style={{color:'#206090'}} value={ cell.Task_id } >
					{cell.Assigned_to ? <p className="mb-0">{cell.Assigned_to}</p> :<p className="mb-0"> ----</p>}
				</a>
			)
	}

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'left',
	  align:'left',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Assigned_to',
	  text: 'Assigned',
	  headerAlign: 'center'	,
	  formatter: Format_taskassign,
	  align:'center',
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Title',
	  text: 'Task',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: TaskTitle,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}
	,{ 
	  dataField: 'created_at',
	  text: 'Date',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: created_date,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: 'Time',
	  text: 'Hours',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: Formatworked_time,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}/*,{ 
	  dataField: '',
	  text: 'Details',
	  headerAlign: 'left'	,
	  align:'left',
	  formatter: Task_list,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}*/,{ 
	  dataField: 'Completed',
	  text: 'Status',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: task_status,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: assign_task,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	}
	,{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: formatdelete_task,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},{ 
	  dataField: '',
	  text: '',
	  headerAlign: 'center'	,
	  align:'center',
	  formatter: formatedit_task,
	  classes: 'align-middle',
	 headerStyle: {
		fontSize:'20px',
		color:'#fff',
		backgroundColor: '#215C8E'
	  }
	},
	
	];

	const sizePerPageOptionRenderer = ({
	  text,
	  page,
	  onSizePerPageChange
	}) => (
	  <li
	    key={ text }
	    role="presentation"
	    className="dropdown-item"
	  >
	    <a
	      href="#"
	      tabIndex="-1"
	      role="menuitem"
	      data-page={ page }
	      onMouseDown={ (e) => {
	        e.preventDefault();
	        onSizePerPageChange(page);
	      } }
	      style={ { color: 'rgb(33, 92, 142)!important' } }
	    >
	      { text }
	    </a>
	  </li>
	);

	const options = {
		totalSize: task_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageOptionRenderer
		// sizePerPageList: [{
		// text: '5', value: 10
		// }, {
		// text: '10', value: 15
		// }, {
		// text: 'All', value: task_list.length
		// }] 
	};

	const toggle = () =>{
	  setModal(!(modal))
	}
	const A_toggle = () =>{

	  setA_model(!(A_modal))
	}
	const EA_toggle = () =>{

	  setEA_model(!(EA_modal))
	}

	const Add_tasks =(e) =>{
		e.preventDefault()
		setLoading(true)
		fetch(localStorage.ip_address+'/create_task/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"project_id":localStorage.p_task_id,
					"task_title":task_title,
					"task_description":task_description
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				toggle()
				if(resJson.message=="Task created successfully" && resJson.task_list){
					setDesc('')
					setTitle('')
					setProject_T_list(resJson.task_list)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Task created successfully",
						icon: 'success',
						showConfirmButton: false,
					})
				}
				else if(resJson.details){
					/*get_tasks()*/
					Swal.fire({
							position: 'top-end',
							timer: 2150,
							text: resJson.details,
							icon: 'Error',
							showConfirmButton: false,
						})
				}
				
				/*console.log(resJson)*/
				setLoading(false)
				
			}).catch((error)=> {
				toggle()
				get_tasks()
				setLoading(false)
				Swal.fire({
							position: 'top-end',
							timer: 2150,
							text: error,
							icon: 'Error',
							showConfirmButton: false,
						})
			})
	}

	const Assign_T =(e) =>{
		e.preventDefault()
		setLoading(true)
		/*setLoading(true)*/

		fetch(localStorage.ip_address+'/assign_task/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
				"task_id":assignT_id,
				"emp_id":value,
				"reassign":reassign				   	
			   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				/*console.log(resJson)*/
				A_toggle()
				if(resJson.message=="Task assigned successfully" && resJson.task_list){
					setProject_T_list(resJson.task_list)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Task assigned successfully",
						icon: 'success',
						showConfirmButton: false,
					})				
				}
				else if(resJson.details){
					get_tasks()
					Swal.fire({
							position: 'top-end',
							timer: 2150,
							text: resJson.details,
							icon: 'Error',
							showConfirmButton: false,
						})
				}
				/*console.log("Users _list",resJson)*/
				/*setUserdetail(resJson)*/
				/*console.log(resJson)*/
				setLoading(false)				
			}).catch((error)=> {
				A_toggle()
				get_tasks()
				setLoading(false)
				Swal.fire({
					position: 'top-end',
					timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
			})
	}

	const Change_status =(e) =>{
		e.preventDefault()
		setLoading(true)
		/*setLoading(true)*/
		fetch(localStorage.ip_address+'/change_task_status/'+assignT_id+'/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"status":value,
				   })
			})	   	
			.then((response)=> response.json())
			.then((resJson)=>{
				EA_toggle()
				if(resJson.message=="Task status changed" && resJson.task_list){
					setProject_T_list(resJson.task_list)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Task status changed successfully",
						icon: 'success',
						showConfirmButton: false,
					})
				}
				else if(resJson.details){
					get_tasks()
					Swal.fire({
							position: 'top-end',
							timer: 2150,
							text: resJson.details,
							icon: 'Error',
							showConfirmButton: false,
						})
				}
				/*console.log("Users _list",resJson)*/
				/*setUserdetail(resJson)*/
				/*console.log(resJson)*/
				setLoading(false)				
			}).catch((error)=> {
				EA_toggle()
				get_tasks()
				setLoading(false)
				Swal.fire({
					position: 'top-end',
					timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
			})
	}

	const handleBtnClick = () => {
		if (!selected.includes(2)) {
		   setSelected([...selected, 2])
		} else {
			setSelected(selected.filter(x => x !== 2))
		}
	}

  const handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			setSelected([...selected, row.Task_id])
		} else {
			setSelected(selected.filter(x => x !== row.Task_id))
		}
	}

  const handleOnSelectAll = (isSelect, rows) => {
		const ids = rows.map(r => r.Task_id);
		if (isSelect) {
			setSelected(ids)
		} else {
			setSelected([])
		}
	}

	const selectRow = {
	  mode: 'checkbox',
	  clickToSelect: true,
	  style: { backgroundColor: '#c8e6c9' },
	  selectColumnPosition: 'right',
	  selected: selected,
	  onSelect: handleOnSelect,
	  onSelectAll: handleOnSelectAll
	};

	const handleChoose=(e)=>{
		e.preventDefault()
		if(delete_selected=="Delete"){
			setLoading(true)
			fetch(localStorage.ip_address+'/delete_multiple_task/'+localStorage.p_task_id+'/', {
			method: 'POST',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"task_ids":selected
				   })
			})
			.then((response)=> response.json())
			.then((resJson)=>{
					/*console.log(resJson)*/
				if(resJson.message=="Tasks deleted successfully"){
					setSelected([])
					setProject_T_list(resJson.task_list)
					Swal.fire({
						position: 'top-end',
						timer: 2150,
						text: "Selected tasks deleted successfully",
						icon: 'success',
						showConfirmButton: false,
					})
					setLoading(false)
				}
			}).catch((error)=> {
				Swal.fire({
					position: 'top-end',
					timer: 2150,
					text: error,
					icon: 'Error',
					showConfirmButton: false,
				})
				setLoading(false)
			})
				/*console.log(selected)*/
		}
		else if(delete_selected=="Create")
			toggle()
		setLoading(false)
	}


	const expandRow = {
		showExpandColumn: true,
		renderer: row => (
		<div>
			<p><b style={{color: 'rgb(33, 92, 142)'}}>Sn.:-</b>{ ` ${row.No}` }</p>
			<p><b style={{color: 'rgb(33, 92, 142)'}}>Title:-</b>{ ` ${row.Title}` }</p>
			<p><b style={{color: 'rgb(33, 92, 142)'}}>Description:-</b>{ ` ${row.Description}` }</p>
			<p><b style={{color: 'rgb(33, 92, 142)'}}>Date:-</b>{ ` ${row.created_at}` }</p>
			<p><b style={{color: 'rgb(33, 92, 142)'}}>Total hours of work on this task:-</b>{ `${row.Time}hrs` }</p>
		</div>
	  ),
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
	    if (isAnyExpands) {
	      return <b style={{color: 'rgb(33, 92, 142)'}}>-</b>;
	    }
	    return <b style={{color: 'rgb(33, 92, 142)'}}>+</b>;
	  	},
		expandColumnRenderer: ({ expanded }) => {
		    if (expanded) {
		      return (
		        <b style={{color: 'rgb(33, 92, 142)'}}>-</b>
		      );
		    }
		    return (
		      <b style={{color: 'rgb(33, 92, 142)'}}>+</b>
		    );
		}

	};

	const HandleKeyDown = (e) =>{
	    if (e.key === 'Enter') {
	    	const headers = {
	    		headers:
	    		 {"token":localStorage.token,
	    		 "username":localStorage.username,
	    		 'Content-Type':'application/json'}
	    	}
		    const data = {'data': e.target.value, 'project_id': localStorage.p_task_id}
		    axios.post(localStorage.ip_address+"/search_task/", data, headers)
		    .then(response=>{
		      	setProject_T_list(response.data)
		    }).catch(error=> console.log(error))
	    }
	}

	const onChange = (e) =>{
		if(e.target.value===''){
			get_tasks()
		}
	}

	const edit_toggle = (id) =>{
		if(typeof id == 'number'){
    		setTask_id(id)
	    	fetch(localStorage.ip_address+'/task_detail/'+id+'/',{
	    		headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
	    	}
	    	)
	    	.then(res=>res.json())
	    	.then((resJson=>{
	    		setTitle(resJson.Title)
	    		setDesc(resJson.Description)
	    	}))
	    	.catch(error=> console.log(error))
	    }
	    else{
	    	setTitle('')
	    	setDesc('')
		}
    	setEditModal(!(edit_modal))
    }

    const editTask = (e) =>{
    	e.preventDefault()
    	fetch(localStorage.ip_address+'/update_task/'+assignT_id+'/',{
    		method: 'post',
    		headers :{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
    		body:JSON.stringify({
			   	"task_title": task_title,
			   	"task_description": task_description
			})
    	})
    	.then(res=> res.json())
    	.then((resJson=>{
    		if('Task_id' in resJson){
    			Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'Task edited successfully',
					icon: 'success',
					showConfirmButton: false,
				})
				setTitle('')
				setTask_id('')
				setDesc('')
				edit_toggle()
				get_tasks()
    		}
    	}))
    	.catch(error=> console.log(error))
    }

    const changeValue = (e) => {
        console.log(e.currentTarget.textContent)
        
        const headers = {
    		headers:{"token":localStorage.token,
    		 		 "username":localStorage.username,
    		 		 'Content-Type':'application/json'}
    	}

        if(e.currentTarget.textContent === 'Task Status'){
        	edit_status_toggle()
        }
        if(e.currentTarget.textContent === "Today's Date"){
        	let d = new Date()
        	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
			const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
			const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        	console.log(da +'/' + mo + '/' + ye)
        	const data = {'data': ye +'-' + mo + '-' + da, 'project_id': localStorage.p_task_id}

        	axios.post(localStorage.ip_address+"/search_task/", data, headers)
		    .then(response=>{
		      	if(!('message' in response.data && response.data.message === "No result found")){
		    		setProject_T_list(response.data)
		    	}
		    	else{
		    		Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: 'No result found',
						icon: 'info',
						showConfirmButton: false,
					})	
		    	}
		    }).catch(error=> console.log(error))
        }
        if(e.currentTarget.textContent === "Custom Date"){
        	edit_custom_toggle()
        }
    }

    const submitStatus = (e) =>{
    	e.preventDefault()
	   	const headers = {
			headers:
			 {"token":localStorage.token,
			 "username":localStorage.username,
		 	 'Content-Type':'application/json'}
    	}
		const data = {'data': task_status_val, 'project_id': localStorage.p_task_id}
        axios.post(localStorage.ip_address+"/search_task/", data, headers)
	    .then(response=>{
	      	if(!('message' in response.data && response.data.message === "No result found")){
	    		setProject_T_list(response.data)
	    	}
	    	else{
	    		Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'No result found',
					icon: 'info',
					showConfirmButton: false,
				})	
	    	}
	      	edit_status_toggle()
	    }).catch(error=> console.log(error))
    }

    const submitCustomDate = (e) =>{
    	e.preventDefault()
	   	const headers = {
			headers:
			 {"token":localStorage.token,
			 "username":localStorage.username,
		 	 'Content-Type':'application/json'}
    	}
		const data = {'data': custom_date, 'project_id': localStorage.p_task_id}
        axios.post(localStorage.ip_address+"/search_task/", data, headers)
	    .then(response=>{
	    	if(!('message' in response.data && response.data.message === "No result found")){
	    		setProject_T_list(response.data)
	    	}
	    	else{
	    		Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'No result found',
					icon: 'info',
					showConfirmButton: false,
				})	
	    	}
	      	edit_custom_toggle()
	    }).catch(error=> console.log(error))
    }
	  
	return(
			<Fragment>
		   {
		   isLoading				
		   ?
		   <div className="container loader m-auto">
			  {
			  <center className="mt-5 pt-5 " id="loader_position">
				 <FadeLoader
					css=""
					height={15}
					width={5}
					radius={2}
					margin={2}
					color={"black"}
					loading={isLoading}    			         
					/>
			  </center>
			  }				
		   </div>
		   :
		   <div className='container-fluid'>
			  	<div className='row'>
					<div className="col-4 text-center">
						<a className="nav-link font-weight-bold text-primary">
						 Project Title :{project_name}
						</a>
					</div>
					<div className="col-3">
						<form className="mb-2 pr-4 float-right" onSubmit={handleChoose}>
						{	
							selected.length
							?
							<div class="form-row align-items-center">
								<div class="col-md-7 mx-2 my-1">
									<select value={delete_selected} onChange={e => setDelete_ps(e.currentTarget.value) } class="custom-select  mr-sm-2" >
										<option selected>Choose...</option>
										<option value="Delete">Delete</option>
										{/*<option value="Create">Create New Task</option>*/}
									</select>
								</div>
								<div class="col-3 my-1">
								  <button type="submit" class="btn btn-primary">Submit</button>
								</div>
							</div>
							:
							null
						}
						</form>		
					</div>
					<div className="offset-lg-2 col-lg-1">
			            <div className="">
			            	{/*<div className="input-group-prepend">
			            		<span className="input-group-text border-left-0 border-right-0 border-top-0 search-icon mt-2"><i className="fa fa-search"></i></span>
			            	</div>
			            	<input 
			            	 className="form-control search-btn border-left-0 border-right-0 border-top-0" 
			            	 placeholder="Search" 
			            	 data-tip="Search by task title, date(format YYYY-mm-dd) and status" 
			            	 data-place="bottom"
			            	 onChange={onChange} onKeyDown={HandleKeyDown}/>
			            	 <ReactTooltip />*/}
			            	 <Dropdown isOpen={dropdownOpen} toggle={dropdown_toggle}>
						      <DropdownToggle caret className="btn-primary">
						        Filter By
						      </DropdownToggle>
						      <DropdownMenu>
						        <DropdownItem header>Apply Filters</DropdownItem>
						        <DropdownItem onClick={changeValue}>Today's Date</DropdownItem>
						        <DropdownItem onClick={changeValue}>Custom Date</DropdownItem>
						        <DropdownItem onClick={changeValue}>Task Status</DropdownItem>
						      </DropdownMenu>
						    </Dropdown>
			            </div>
			        </div>
					 <div className="col-2 text-center">
						<a className="text-left nav-link text-primary " 
						style={{cursor:'pointer',textDecoration: 'underline'}}
						onClick={toggle}>
						<i class="fa fa-plus" aria-hidden="true"></i>Create Task
						</a>
					</div>
					 <br/>
					 <br/>
					 {/* Create New task*/}
					 <Modal  className="text-align-center" isOpen={modal} fade={false} toggle={toggle}>
						<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={toggle} className="">
						<span className="m-auto text-color-gradient font-weight-bold">Create New Task</span></ModalHeader>
						<ModalBody>
						   <form className="form m-auto col-10" onSubmit={Add_tasks}>
								<div className="form-group mt-3">
									<br/>
									<input className="form-control m-auto" placeholder="Enter Task Title" 
									   type="text" name="task_title" value={task_title} 
									   onChange={e => setTitle(e.target.value)} 
									/>
								</div>
								<div className="form-group m-auto">
									<textarea className="form-control mt-3" name="task_desc" 
									 id='task_desc' onChange={e => setDesc(e.target.value)} 
									 value={task_description} placeholder="Write..."></textarea>
									<button type="submit" className="btn btn-primary col-12 mt-3">Save</button>
							  </div>
							  <br/><br/><br/>
						   </form>
						</ModalBody>
					 </Modal>
					 {/* Assign Task */}
					 <Modal  className="text-align-center" isOpen={A_modal} fade={false} toggle={A_toggle}>
						<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={A_toggle} className="">
						<span className="m-auto text-color-gradient font-weight-bold">Assign Task</span></ModalHeader>
						<ModalBody>
						   <form className="form m-auto col-10" onSubmit={Assign_T}>
								<div className="form-group mt-3">
									 <br/>
									<select value={value} onChange={e =>
										setValue(e.currentTarget.value) } className="form-control mt-3">
										<option className="text-muted" disable>Select</option>
										{user_list.length
										?
										user_list.map(data=>(
										<option className="text-dark" value={data.id}>{data.employee_name}</option>
										))
										:
										null
										}
									</select>
								</div>
								<div className="form-group m-auto">
									<button type="submit" className="btn btn-primary col-12 mt-3">Submit</button>
								</div>
							  <br/><br/><br/>
							</form>
						</ModalBody>
					</Modal>
					 {/* Task Status */}
					<Modal  className="text-align-center" isOpen={EA_modal} fade={false} toggle={EA_toggle}>
						<ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={EA_toggle} className="">
						<span className="m-auto text-color-gradient font-weight-bold">Change Task Status</span></ModalHeader>
						<ModalBody>
							<form className="form m-auto col-10" onSubmit={Change_status}>
								<div className="form-group mt-3">
									<br/>
									<select value={value} onChange={e =>
										setValue(e.currentTarget.value) } className="form-control mt-3">
										<option className="text-muted" disable>Select</option>
										<option className="text-dark" value="True">Complete</option>
										<option className="text-dark" value="False">Incomplete</option>
										{/*
										<option className="text-dark" value={data.id}>{data.employee_name}</option>
										*/}
									</select>
								</div>
								<div className="form-group m-auto">
									<button type="submit" className="btn btn-primary col-12 mt-3">Submit</button>
								</div>
								<br/><br/><br/>
							</form>
						</ModalBody>
					</Modal>
					<div className="col-md-12 col-sm-12 col-12">
						<div className="row ">
						   <div className="col-lg-12">
						   		{task_list.length != 0?
									<div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
										{/*<BootstrapTable
										  keyField="Task_id"
										  data={ task_list }
										  columns={ columns }
										  headerClasses="event-head"
										  pagination={ paginationFactory(options) }
										  bordered={ false }	
										  selectRow={ selectRow }
										  expandRow={ expandRow }								
									  	/>*/}
									  	<ToolkitProvider
								          	keyField="Task_id"
					                     	data={ task_list }
					                     	columns={ columns }
					                     	headerClasses="event-head"
					                     	pagination={ paginationFactory(options) }
								          	search
								        >
								          {
								            toolkitprops => (
								              <div>
								                <SearchBar { ...toolkitprops.searchProps } />
								                <BootstrapTable
								                	bordered={ false }
								                	selectRow={ selectRow }	
								                	expandRow={ expandRow }
								                	pagination={ paginationFactory(options) }
								                  { ...toolkitprops.baseProps }
								                />
								              </div>
								            )
								          }
								        </ToolkitProvider>
									</div>
									:<h3 className="text-center mt-5 text-primary">There is no task for this project. Please click on create task and add new one.</h3>
								}	
							</div>
					 	</div>
				  	</div>
		   		</div>
		   		<Modal isOpen={edit_modal} toggle={edit_toggle}>
			        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_toggle}>
			        	<span className="m-auto text-color-gradient font-weight-bold">Edit Task</span>
			        </ModalHeader>
			        <ModalBody>
						<form className="form m-auto col-10" onSubmit={editTask}>
							<div className="form-group mt-3">
								<br/>
								<input className="form-control m-auto" placeholder="Enter Task Title" 
								   type="text" name="task_title" value={task_title} 
								   onChange={e => setTitle(e.target.value)} 
								/>
							</div>
							<div className="form-group m-auto">
								<textarea className="form-control mt-3" name="task_desc" 
								 id='task_desc' onChange={e => setDesc(e.target.value)} 
								 value={task_description} placeholder="Write..."></textarea>
								<button type="submit" className="btn btn-primary col-12 mt-3">Edit Task</button>
						  	</div>
						  	<br/><br/><br/>
						</form>	          
			        </ModalBody>
		      	</Modal>
		      	<Modal isOpen={edit_status} toggle={edit_status_toggle}>
		        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_status_toggle}>
		        	<span className="m-auto text-color-gradient font-weight-bold">Enter Task Status</span>
		        </ModalHeader>
		        <ModalBody>
					<form className="form m-auto col-8 p-4" onSubmit={submitStatus}>
		          		<div className="form-group mt-3">
			          		<input className="form-control m-auto input-focus" placeholder="Incomplete / Completed" 
		          				type="text" name="p_title" value={task_status_val} 
		          				onChange={e => setTaskStatus(e.target.value)} autoFocus
		          				required
			          		/>
		          			<button type="submit" className="btn btn-primary col-12 mt-2">Apply Filter</button>
		          		</div>
	        		</form>		          
		        </ModalBody>
		      </Modal>
		      <Modal isOpen={edit_custom} toggle={edit_custom_toggle}>
		        <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={edit_custom_toggle}>
		        	<span className="m-auto text-color-gradient font-weight-bold">Enter Date</span>
		        </ModalHeader>
		        <ModalBody>
					<form className="form m-auto col-8 p-4" onSubmit={submitCustomDate}>
		          		<div className="form-group mt-3">
			          		<input className="form-control m-auto input-focus" placeholder="Enter date" 
		          				type="date" name="p_title" value={custom_date} 
		          				onChange={e => setCustomDate(e.target.value)} autoFocus
		          				required
			          		/>
		          			<button type="submit" className="btn btn-primary col-12 mt-2">Apply Filter</button>
		          		</div>
	        		</form>		          
		        </ModalBody>
		      </Modal>
			   	<hr className="my-5"/>
			   <Footer/>
		   </div>
		   }
		   <br/>
		   <br/>
		</Fragment>
			)
		}

export default Company_tasks;

							