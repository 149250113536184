import ProgressBar from 'react-bootstrap/ProgressBar'
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
/*import '../css/Employee.css'*/
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import FadeLoader from "react-spinners/FadeLoader";
import Swal from "sweetalert2"
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers"
import LazyLoad from 'react-lazy-load';
import {
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";
import DatePicker from 'react-datepicker';

class Emp_Dashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			all_images:[],
			emp_today_date:"",
			emp_week:"",
			emp_month:"",
			ip_address:localStorage.ip_address,
			hourly_img:[],
			user_id:localStorage.user_id,
			NewTime:'',
			isLoading:true,	
			emp_name:'',
			emp_email:'',
			next_prev_img:[],
			img_path:null,
			img_modal:false,
			startDate:new Date(),
			date_modal:false,	
		}
		this.setHourlyImg=this.setHourlyImg.bind(this)	
		this.openPopupbox=this.openPopupbox.bind(this)
		this.openPopupbox1=this.openPopupbox1.bind(this)	
	}

	componentDidMount(){
		fetch(this.state.ip_address+'/user_detail/'+localStorage.user_id+'/',{
			method: 'get',
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
			})
		.then(resj=>resj.json())
		.then(res=>{
			/*console.log("Images of emp",res)*/

			this.setState({all_images:res.Images,
				isLoading:false,
				emp_today_date:res.Date,
				emp_week:res.This_Week,
				emp_today_hrs:res.Today.split(":"),
				emp_month:res.This_Month,
				emp_name:res.Name,
				emp_email:res.Email,

			})
			this.setHourlyImg()
		}).catch(err=>{
			this.setState({isLoading:false})
			console.log(err)
		})		
	}


	setHourlyImg=()=>{
		/*let date=this.state.emp_today_date.split(" ")*/
/*		console.log("date",date,)*/
		let date = new Date().getDate();
		let month = new Date().getMonth() + 1;
		let year = new Date().getFullYear();
		if(date<10){
      	date=0+String(date)
      }
      if(month<10){
      	month=0+String(month)
      }
		let array=[]
		let today=this.state.all_images.filter(data=> (data.date) ==String(date)
		&& (data.month)==String(month) && (data.year)==String(year)  && parseInt(data.hour)>=0 )						
/*		console.log("check after date",today)*/
		for(let h=24; h>=0; h--)
		{
			let temp=today.filter(value=>value.hour==h)
			if(temp.length)
			{
				array.push(temp)
			}
		}	
		if(array.length){
			this.setState({
				hourly_img:array,
				isLoading:false
			},()=>{
				/*console.log("check",this.state.hourly_img)*/
				let arr=[]
				for(let i=0; i<this.state.hourly_img.length; i++ ){
/*					console.log("check",i,this.state.hourly_img[i])*/
					for(let j=0; j<this.state.hourly_img[i].length; j++){
						arr.push(this.state.hourly_img[i][j].image)
					}
				}/*console.log("completed img",arr)*/
				this.setState({next_prev_img:arr})
			})
		}
	}


	DateWiseFilter=(val_date)=>{
		/*console.log("val_date",val_date)*/
  		this.props.history.push({pathname: '/FilterImg',state: {User_id:val_date,all_images:this.state.all_images }})
	}

	openPopupbox=(path)=> {
		this.setState({img_path:path},
			()=>{this.img_toggle()})
	   
	}

	openPopupbox1=(path)=> {
		this.setState({img_path:path})
	   
	}

	img_toggle=()=>{
		this.setState({img_modal:!(this.state.img_modal)})
	}
	

	/*openPopupbox=(path)=> {
	    var content = <div>
	    <Magnifier
			  imageSrc={this.state.ip_address+path}
			  imageAlt="image not found"
			  largeImageSrc={this.state.ip_address+path}
			  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} 
			  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} 
			/>
    	{	
    		this.state.next_prev_img.indexOf(path)!=0
			?
	    	<span onClick={()=>this.openPopupbox(this.state.next_prev_img[this.state.next_prev_img.indexOf(path)-1])} className="btn btn-primary mr-5">
	    		<i className=" fas fa-angle-left  text-white">-Prev</i>
	    	</span>
	    	:
	    	null
	    }


	    {	
    		this.state.next_prev_img.indexOf(path)!= this.state.next_prev_img.length-1
			?
		   	<span onClick={()=>this.openPopupbox(this.state.next_prev_img[this.state.next_prev_img.indexOf(path)+1])} className="btn btn-primary float-right" >
		   		Next-<i className="fas fa-angle-right  text-white"></i>

			</span>
			:
	    	null
    	}
	    </div>

		PopupboxManager.open({
	        content,
	        config: {
	          titleBar: {
	            enable: true,
	            text: 'Screenshot',
	          },
	          fadeIn: true,
	          fadeInSpeed: 100,
	        }
	      })
	}*/	   	
	

	date_toggle = (id) =>{
		if(this.state.date_modal){
			this.setState({date_modal: false, user_id: id})
		}
		else{
			this.setState({date_modal: true, user_id: id})
		}
	}

	onFormSubmit =(e)=> {
	    e.preventDefault();

	    /*console.log(localStorage.b_os.split(" ")[0])*/

	    if(localStorage.browser=="firefox" || localStorage.b_os.split(" ")[0]=="Windows"){
		    var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			if(parseInt(date[1])<10){
				date[1]=0+date[1]
			}
			if(parseInt(date[0])<10){
				date[0]=0+date[0]
			}
			console.log(date[2]+'-'+date[1]+'-'+date[0] )
			/*console.log(this.state.User_id)*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.user_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[0]+'-'+date[1]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({emp_today_hrs:resJson.today_work.split(":"),
								   emp_today_date: this.state.startDate.toDateString()})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[1],month:date[0],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[1] && (data.month)==date[0] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
				},()=>{
					let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
				})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    }
	    else{
	    	// console.log(this.state.startDate)
	    	var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			/*console.log(typeof(date[2]+'-'+date[1]+'-'+date[0]))*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.user_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[1]+'-'+date[0]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({emp_today_hrs:resJson.today_work.split(":"),
								   emp_today_date: this.state.startDate.toDateString()})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[0],month:date[1],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[0] && (data.month)==date[1] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
					},()=>{
						let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
					})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    } 
		this.date_toggle()
	}

	handleChange=(date) =>{
		console.log(date)
	    this.setState({
	      startDate: date
	    })
	}


	render() {
		const {all_images,emp_month,emp_week,emp_today_date,emp_today_hrs,emp_email,emp_name}=this.state
		const {ip_address,hourly_img,user_id,NewTime,isLoading,img_path}=this.state
		return (
			<Fragment>
			   <div className="container">
			   {
			   	isLoading
			   	?
			   	<div className="container loader m-auto">
			      {
			      	<center className="mt-5 pt-5 " id="loader_position">
			        	<FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      	</center>
			      }				
			   </div>
			   :
			   	<Fragment>
			     	<div className="card-group">
				        <div className="card m-3">
				            <div className="card-body ">
				               <h3 className="card-title text-center mt-4 text-color font-weight-bold">{emp_today_date}</h3>
				               <hr className='w-75 mx-auto'/>
				               { 
				               	emp_today_hrs
				               	? 
				               <h1 className="card-subtitle mb-2 text-center text-muted" id="emp_today_hrs">{emp_today_hrs[0]}<small>Hrs</small>:{emp_today_hrs[1]}<small>Min</small>:{emp_today_hrs[2]}<small>Sec</small></h1>
				               :null
				               }
				               <p className="card-text text-center text-muted">This Week:{emp_week} &nbsp; = &nbsp; This Month {emp_month}</p>
				            </div>
				        </div>
				         <div className="card m-3">
				            <div className="card-body">
				               <h3 className="card-title text-center mt-4 text-color  font-weight-bold">Note</h3>
				               <hr className='w-75 mx-auto' />
				               <ol className='pl-0'>
				                  <li>Name:-{emp_name}</li>
				                  {/*					                    	
				                  <li>Screen shot captures in every <span className='text-color font-weight-bold'>{NewTime}min</span></li>
				                  */}					                    	
				                  <li>Email:-{emp_email}</li>
				               </ol>
				            </div>
				        </div>
				    </div>
			      <div className='m-3'>
			      	<i className="fa fa-search ml-1 text-color" aria-hidden="true"></i>
			        <a className="text-color"  style={{cursor:'pointer'}} onClick={()=>this.date_toggle(user_id)}>Show Date wise filter</a>
			      </div>
			    {				 				
			        isLoading && hourly_img.length>1
			        ?
			        <div className="container loader m-auto">
			        {
			        <center className="mt-5 pt-5 " id="loader_position">
			            <FadeLoader
			               css=""
			               height={15}
			               width={5}
			               radius={2}
			               margin={2}
			               color={"black"}
			               loading={isLoading}    			         
			               />
				         </center>
				         }
				    </div>
				      :
				      hourly_img.length>0
				       ?
			      hourly_img.map((data,index)=>(
			      	<Fragment key={index+"500"}>
			        	<h2 className="text-color text-center font-weight-bold mb-3">{data[0].hour}:00{data[0].mode} to {parseInt(data[0].hour)+1}:00{data[0].mode}</h2>
			        	<div className="col-md-12 mb-4">
			            	<hr className="w-25 m-auto" style={{backgroundColor:'#1976D2'}} />
			         	</div>
				        <div className="row text-center text-lg-left">
				            {
				            data.map((value,i)=>(
				            <div key={value.mode+i} className="col-lg-3 mb-3 col-md-4 col-6">
				               <div className='px-1 my-0 pb-2 card' style = {{cursor: 'pointer'}}>
					               <small className="">{value.hour}:{value.min}{value.mode}&nbsp;&nbsp;<i onClick={()=>this.removeImg(value.id)} className="float-right pt-1 fa fa-trash delete-icon" aria-hidden="true"></i></small>
					               <a onClick={()=>{this.openPopupbox(value.image)}} className="d-block mb-2 h-100">
					               <LazyLoad>
					               <img loading="lazy" height='150' width='300' className="img-fluid img-thumbnail" src={value.image} alt="not available"/>
					              	</LazyLoad>
					               </a>
					               {
					               parseInt(value.activity)>60
					               ?
					               <span  className="px-3">
					                  <ProgressBar variant="success"  now={parseInt(value.activity)} label={`${value.activity}%`} />
					               </span>
					               :
					               <span  className="px-3">
					                  <ProgressBar variant="danger"  now={parseInt(value.activity)} label={`${value.activity}%`} />
					               </span>
					               }
					            </div>
				        	 </div>
				         	))
				         }
				         <br/>
				         <div   className="btn btn-sm col-md-12 my-2"></div>
				   		</div>
				   </Fragment>	
			   ))
			   :
			   <div className=" col-md-12 px-0">
			      <div className="p-5 mb-0 alert text-center alert-info alert-dismissible fade show">
			         <strong>Sorry!</strong> No image is available yet<br/><br/>
			         <p className="mt-2">Please start your timer to see your screenshots</p>
			      </div>
			   </div>
			   }
			   </Fragment>
			}
			   </div> 						    
			   {/*Image modal show magnify*/}
	    	 <Modal   size="lg" style={{maxWidth: '1400px', width: '80%',margin:'auto'}}
 				className="text-align-center" isOpen={this.state.img_modal} fade={false} toggle={this.img_toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} 
	          toggle={this.img_toggle} 
	          className="font-weight-bold text-align-center">
	          </ModalHeader>
	          <ModalBody className="pt-0">
						<div className="container  bg-transparent">
						{ 
							this.state.img_path!=null
							?
							<div className="w-90 m-auto">
							    <Magnifier
									  imageSrc={img_path}
									  imageAlt="image not found"
									  largeImageSrc={img_path}// Optional
									  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} // Optional
									  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} // Optional
								/>
					    	{/*<img className="text-styles img-fluid mb-2"  height='550'  src={this.state.ip_address+path} alt="Image not found"/>*/}
					    	{	
					    		this.state.next_prev_img.indexOf(img_path)!=0
								?
						    	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)-1])} 
						    	className="btn btn-primary mr-5">
						    		<i className=" fas fa-angle-left  text-white">-Prev</i>
						    	</span>
						    	:
						    	null
						    	}
							    {	
						    		this.state.next_prev_img.indexOf(img_path)!= this.state.next_prev_img.length-1
									?
								   	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)+1])} 
								   	className="btn btn-primary float-right" >
								   		{/*console.log(this.state.next_prev_img.indexOf(path))*/}
								   		Next-<i className="fas fa-angle-right  text-white"></i>

									</span>
									:
							    	null
						    }
						    </div>
			          		:
			          		null
						}
					</div>
		       </ModalBody>
	        </Modal>
	        	<Modal className="text-align-center" isOpen={this.state.date_modal} fade={false} toggle={this.state.date_toggle}>
		            <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.state.date_toggle} className="font-weight-bold text-align-center"><span className="m-auto">Select Date</span></ModalHeader>
		            <ModalBody>
			            <form onSubmit={this.onFormSubmit}>
			                <div className="modal-body">
			                    <div className="form-group ">
			                        <center>
			                            <DatePicker
			                              className="form-control w-100"
			                              onChange={(e)=>this.handleChange(e)}
			                              selected={this.state.startDate}
			                              dateFormat="MMMM d, yyyy "							        
			                             />
			                        </center>
			                        <br/>
			                        <center><button type="submit" className="btn col-6 btn-primary">Show</button>
			                        </center>
			                    </div>
			                </div>
		                  {/*
		                  <div className="modal-footer border-top-0 d-flex justify-content-center">
		                     <br/>
		                     <button type="submit" className="btn btn-primary col-md-4 mt-0">Submit</button>
		                  </div>
		                  */}
		               	</form>
		            </ModalBody>
	        	</Modal>
			   <ScrollUpButton style={{backgroundColor: '#1976D2'}} /> 
			</Fragment>
		)
	}
}

export default Emp_Dashboard
