import React, { Component,Fragment } from 'react';
import "./SoftLand/assets/css/style.css"
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import nav_logo from '../images/nav_logo.jpeg'
import {Helmet} from 'react-helmet'
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import Home2 from '../Home2';
import ReactDom from 'react-dom';
import history from "../history"
import Register_employee from "../Register_employee"
import Reset_password from '../Reset_password'
import Swal from 'sweetalert2'
import FadeLoader from "react-spinners/FadeLoader";
import $ from 'jquery'
import Footer from '../Footer'
import CookieConsent from "react-cookie-consent";
import LazyLoad from 'react-lazy-load';
import { Carousel } from 'react-bootstrap';
import company_dashboard from "./Screenshots/company_dashboard.png"
import monthly_activity from "./Screenshots/monthly_activity.png"
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

import "../css/common.css"

import axios from 'axios'

import {
	Button,
  Container,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";

class index extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			 name:'',
			 email:'',
			 contact_number:'',
			 message:'',
			 csrftoken:'',
			 loader:false,
			 modal: false,
			 privacyshow:false,
			 Tmodal:false,
			 Dmodal:false,
			 feedback:'',
			 modal_f:false,
			 img_modal:false,
			 img_path:null,

		}
		this.Contact_us=this.Contact_us.bind(this)
		this.toggle=this.toggle.bind(this)
		this.togglePrivacy=this.togglePrivacy.bind(this)
		this.disc_toggle=this.disc_toggle.bind(this)
		this.img_toggle=this.img_toggle.bind(this)
		this.showmodal=this.showmodal.bind(this)

	}

	/*Router To ./Home2.js component*/
	Navigate=(val,os="none")=>{
		if(val=="login_register"){
			localStorage.setItem('show','login')
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			);
		}
		else if(val=="download" && (os=="ubuntu" || os=="windows")){
			localStorage.setItem('show','download')
			localStorage.setItem('os',os)
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			);
		}
		else if(val=="register"){
			localStorage.setItem('show','register_company')
			ReactDom.render(
			<Router history={history}>
			  		<Home2/>
			  	</Router>
			  ,
			  document.getElementById('root')
			 );
		}
	}

	componentDidMount(){
		axios.get(localStorage.ip_address+'/get_application_version/')
		.then(resJson=>{
			console.log("Version",resJson)
			localStorage.setItem('app_version',resJson.data[0])
			console.log("local storage",localStorage.app_version)
		}).catch(err=>{
			console.log("error",err)
		})
		/*Get url from url bar*/
		const link=window.location.href
		const arr=link.split('/')
		
		/*console.log(arr)*/
		/*const link="http://trackme.wangoes.com/invy_11"*/

		/*const link="http://localhost:3000/change_password/inrp_29/5k3-d2c15a9829157dc77482/" */
		/*console.log("Home Page",arr)
*/		/*For ForgotPassword Route*/

		
		/*Chnage Password condition check*/
		if(arr[3]=="change_password"){
			localStorage.setItem('link1',arr[4])
			localStorage.setItem('link2',arr[5])
			fetch(localStorage.ip_address+'/change_password/'+parseInt(localStorage.link1.split('_')[1])+'/'+localStorage.link2+'/',{
				method: 'GET',
				headers: {'Content-Type':'application/json'}
				}).then(res=>res.json())
	 		.then(resJson=>{
	 			/*console.log("componentDidMount",resJson.error)*/
	 			if(resJson.message=="Active"){
	 				this.setState({link_status:resJson.message})
	 				ReactDom.render(<Router history={history}><Reset_password /></Router>, document.getElementById('root')); 

	 			}else if(resJson.error="Token already used"){
	 				Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Link is Expired",
						icon: 'info',
						showConfirmButton: false,

					})
					/*localStorage.setItem('show','login')
	 				ReactDOM.render(
					  		<Home2/>					  
					  ,
					  document.getElementById('root')
					);*/

	 			}	 			
	 		}).catch(err=>{
	 			console.log(err)
	 			
	 		})

		}
		const id=arr[3].split('_')
		/*console.log("id",id[0])*/	
		localStorage.setItem('id_href',arr[3])

		/*For Employee Register Route*/
		if(id[0]=="invy"){
			ReactDom.render(<Router history={history}><Register_employee /></Router>, document.getElementById('root')); 

		}
		if(arr[3] != "change_password" && arr[3]!="invy" && (localStorage.username|| localStorage.show=='download' || localStorage.password || localStorage.show=="login" || localStorage.show=="register_company")){
			ReactDom.render(<Router history={history}><Home2 /></Router>, document.getElementById('root')); 
		}
	}
			

	onChange=(event)=>{this.setState({[event.target.name]:[event.target.value]})}

	/*Contact Us  Modal Toggling*/
	 toggle = () =>{

	  this.setState({modal:!(this.state.modal)})
	}

	/*Feed Back modal Toggling*/
	toggle_f = () =>{

	  this.setState({modal_f:!(this.state.modal_f)})
	}
	/*Privacy policy Modal toggling*/
	togglePrivacy=()=>{
		this.setState({privacyshow:!(this.state.privacyshow)})
	}
	/*Terms and condition Modal toggling*/
	terms_toggle = () =>{

	  this.setState({Tmodal:!(this.state.Tmodal)})
	}
	/*Term Disclamour*/
	disc_toggle = () =>{

	  this.setState({Dmodal:!(this.state.Dmodal)})
	}

	/*Contack us form*/
	Contact_us =(event)=>{
		event.preventDefault()	
			this.setState({loader:true})
			if (this.state.contact_number[0].match( 
	                    /[0-9]/g) && this.state.contact_number[0].length == 10) 
	            {
	            	const { username, password } = this.state;
					fetch(localStorage.ip_address+'/contact_us/', {
					method: 'post',
					headers: {"X-CSRFToken": this.state.csrftoken,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"name": this.state.name[0],
						    "contact_number": this.state.contact_number[0],
						    "email":this.state.email[0],
						    "message":this.state.message[0]
						    
						   })
					}).then((response) => response.json())
					.then((responseJson) => {
						if(responseJson.message=="Message send Successfully"){
							
							this.setState({
								name:'',
								 email:'',
								 contact_number:'',
								 message:'',
								 loader:false,phone_no:''
							})
							this.toggle()
							Swal.fire({
								position: 'top-end',
					  			timer: 2000,
								text: "Send Successfully",
								icon: 'success',
								showConfirmButton: false,
							})
						}
										   
					})
					.catch((error) => {
					this.setState({loader:false})
					this.toggle()
					 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Error In sending detail",
						icon: 'error',
						showConfirmButton: false,
					})
					 console.log(error)
					});
				}
				else{
				this.setState({loader:false,phone_no:''})
					this.toggle()
					this.setState({
								 contact_number:'',
							})

					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Please Enter Phone Number carefully",
						icon: 'warning',
						showConfirmButton: false,
					})
					
				}
	}

	/*Submit Feedback*/
	submitFeedback =(event)=>{
		event.preventDefault()
		fetch(localStorage.ip_address+'/submit_feedback/', {
					method: 'post',
					headers: {"X-CSRFToken": this.state.csrftoken,'Content-Type':'application/json'},
					body: JSON.stringify({
						   	"name": this.state.name[0],
						    "email":this.state.email[0],
						    "message":this.state.feedback[0]
						    
						   })
					}).then((response) => response.json())
					.then((responseJson) => {
						/*console.log(responseJson)*/
						if(responseJson.message=="Feedback send successfully"){
							
							this.setState({
								name:'',
								 email:'',
								 contact_number:'',
								 message:'',
								 loader:false
							})
							this.toggle_f()
							Swal.fire({
								position: 'top-end',
					  			timer: 2000,
								text: "Send Successfully",
								icon: 'success',
								showConfirmButton: false,
							})
						}
										   
					})
					.catch((error) => {
					this.setState({loader:false})
					this.toggle_f()
					 Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Error In sending detail",
						icon: 'error',
						showConfirmButton: false,
					})
					 console.log(error)
					});
	}

	img_toggle=()=>{
		this.setState({img_modal:!(this.state.img_modal)})
	}

	showmodal=(image)=>{
		this.setState({img_path:image},
			()=>{this.img_toggle()})
	}



	render() {
		return (
			<Fragment>
			<Helmet>
	              <meta name="description" content = 'wecome to my web app'/>
	              <link rel="shortcut icon" href={nav_logo} /> 
          </Helmet>
			{
				this.state.loader==true
		      		?
	      				<div className="container loader m-auto">					
					     	{
					     	<center className="mt-5 pt-5 " id="loader_position">
						     	<FadeLoader
		     			          css=""
		     			          height={15}
		     			          width={5}
		     			          radius={2}
		     			          margin={2}
		     			          color={"black"}
		     			          loading={this.state.loader}    			         
		     			        />
	     			        </center>}
						</div> 
	    	        :
	    	        <Fragment>
					<Router >
			          <div id="nav-background" >
			            <Helmet>
			              <meta name="description" content = 'wecome to my web app'/>
			              <link rel="shortcut icon" href={nav_logo} /> 
			              			             
			            </Helmet>
			            <nav id="navigation-bar"   className="navbar navbar-expand-lg navbar-dark" >
			               <a className="navbar-brand" href="/"><img className="logo_attribute" src={nav_logo} alt="not found" />  </a>
			             
			                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			                  <span className="navbar-toggler-icon"></span>
			                </button>
			                <div className="collapse navbar-collapse " id="navbarSupportedContent">
			                    <ul className="navbar-nav ml-auto">
			                      <li className='nav-item text-white nav-link font-weight-bold'> Home</li>
			                      {/*<li className='nav-item text-white nav-link font-weight-bold' onClick={()=>{this.Navigate("download")}}>Download App</li>*/}
			                      <li className='nav-item text-white nav-link font-weight-bold' onClick={()=>{this.Navigate("login_register")}}>Login</li>
			                      <li className='nav-item text-white nav-link font-weight-bold' onClick={()=>{this.Navigate("register")}}>Register</li>
			                      <li className='nav-item text-white nav-link font-weight-bold' > <a onClick={this.toggle} type="button" >Contact Us</a></li>
			                      <li className="nav-item dropdown ">
			                         <a className="nav-link text-white dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			                         Download App
			                         </a>
			                         <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left:'-26px'}}>
			                            <hr className="m-0"/>
			                            <Link to={'/'}>
			                            <p className="dropdown-item pb-0 mb-0" onClick={()=>{this.Navigate("download","windows")}}>Windows</p>
			                            </Link>
			                            <hr className="m-0"/>
			                            <Link to={'/'}>
			                            <p className="dropdown-item pb-0 mb-0" onClick={()=>{this.Navigate("download","ubuntu")}}>Ubuntu</p>
			                            </Link>
			                            <hr className="m-0"/>
			                         </div>
			                      </li>
			                   </ul>
			                </div>
			          </nav>
			        </div>
			      </Router>

			{/*Contact Us  Modal*/}
		    <Modal  className="text-align-center" isOpen={this.state.modal} fade={false} toggle={this.toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.toggle} className="">
	          <span className="m-auto text-color-gradient font-weight-bold">Contact Us</span></ModalHeader>
	          <ModalBody>
	              <form onSubmit={this.Contact_us}>
				        <div className="modal-body px-5">
				          <div className="form-group">
				            <input type="text" className="form-control" name="name" onChange={this.onChange} value={this.state.name} id="username" placeholder="Enter Full Name" required />
				          </div>
				          <div className="form-group">
				            <input type="email" className="form-control" name="email" onChange={this.onChange} value={this.state.email} id="email"  placeholder="Enter email" required />
				          </div>
				          <div className="form-group">
			            <input type="text" className="form-control" name="contact_number" onChange={this.onChange} value={this.state.contact_number} id="phone_no" maxLength="10" placeholder="Phone Number" required />
				          </div>
				          <div className="form-group">
				          	<textarea className="form-control mt-3" name="message" id='meassage' onChange={this.onChange} value={this.state.message} placeholder="Write..."></textarea>
				          </div>
				        </div>
				        <div className="modal-footer border-top-0 d-flex justify-content-center">
				         <br/>
				          <button type="submit" className="btn btn-primary col-md-4 mt-0">Submit</button>
				        </div>
				      </form>
	          </ModalBody>
	        </Modal>

	    {/*Feed Back form*/}
		    <Modal size="md" 
 				className="text-align-center"  className="text-align-center" isOpen={this.state.modal_f} fade={false} toggle={this.toggle_f}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.toggle_f} className="">
	          <span className="m-auto text-color-gradient font-weight-bold">FEEDBACK</span></ModalHeader>
	          <ModalBody>
	              <form onSubmit={this.submitFeedback}>
				        <div className="modal-body px-5">
				        	<div className="form-group">
				            <input type="text" className="form-control" name="name" onChange={this.onChange} value={this.state.name} id="username" placeholder="Enter Your Name" required />
				          </div>
				          <div className="form-group">
				            <input type="email" className="form-control" name="email" onChange={this.onChange} value={this.state.email} id="email"  placeholder="Enter Your Email Address" required />
				          </div>
				          <div className="form-group">
				          	<textarea className="form-control mt-3" rows={4} name="feedback" id='feedback' onChange={this.onChange} value={this.state.feedback} placeholder="Write your feedback..."></textarea>
				          </div>
				          <button type="submit" className="btn btn-primary col-12  mt-0">Submit</button>
				        </div>
				        <br/><br/>
				    </form>
	          </ModalBody>
	        </Modal>




	    {/*Privacy And Policy model*/}
	        <Modal scrollable={true}  size="lg" style={{maxWidth: '1600px', width: '100%'}}
 				className="text-align-center" isOpen={this.state.privacyshow} fade={false} toggle={this.togglePrivacy}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.togglePrivacy} className="font-weight-bold text-align-center">
	          <h3 className="m-auto text-color-gradient font-weight-bold">PRIVACY & POLICY</h3><small>www.trackme.wangoes.com</small></ModalHeader>
	          <ModalBody>
						<div className="container">
						<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
						<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy..</p>
						<h2>Interpretation</h2>
						<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
						<h2>Definitions</h2>
						<p>For the purposes of this Privacy Policy:</p>
						<ul>
							<li>
								<p>
									<strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
								
								</p>
							</li>
							<li>
								<p>
									<strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Trackme.
								
								</p>
							</li>
							<li>
								<p>
									<strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
								
								</p>
							</li>
							<li>
								<p>
									<strong>Country</strong> refers to: Madhya Pradesh,  India
								
								</p>
							</li>
							<li>
								<p>
									<strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
								</p>
							</li>
							<li>
								<p>
									<strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
								
								</p>
							</li>
							<li>
								<p>
									<strong>Service</strong> refers to the Website.
								
								</p>
							</li>
							<li>
								<p>
									<strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
								
								</p>
							</li>
							<li>
							<p>
								<strong>Website</strong> refers to Trackme, accessible from 
								
								<a href="https://trackme.wangoes.com"  target="_blank">&nbsp;trackme.wangoes.com</a>
							</p>
						</li>
						<li>
							<p>
								<strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
							
							</p>
						</li>
						</ul>
						<h2 className="mb-4">Collecting and Using Your Personal Data</h2>
						<h3>Types of Data Collected</h3>
						<h4>Personal Data</h4>
						<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is limited to:</p>
						<ul>
						<li>
							<p>Email address</p>
						</li>
						<li>
							<p>Phone number</p>
						</li>
						</ul>
						<h3>Tracking Technologies and Cookies</h3>
						<p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.</p>
						<p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
						<p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: 
							
							<a href="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies</a>.
						
						</p>
						
						
						<p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
						<h2>Use of Your Personal Data</h2>
						<p>The Company may use Personal Data for the following purposes:</p>
						<ul>
							<li>
								<strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
							
							</li>
							<li>
								<strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
							
							</li>
							<li>
								<strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
							
							</li>
							<li>
								<strong>To provide You</strong> information regarding latest version of our Application, technical support and feedback.
							
							</li>
							<li>
								<strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
							
							</li>
						</ul>
						<p className=" mt-3">We may share your personal information in the following situations:</p>
						<ul>
							<li>
								<strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.
							
							</li>
							<li>
								<strong>For Business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
							
							</li>
							<li>
								<strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. 
							
							</li>
							<li>
								<strong>With Business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.
							
							</li>
						</ul>
						<h2 className="mt-3">Retention of Your Personal Data</h2>
						<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
						<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
						<h2>Transfer of Your Personal Data</h2>
						<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
						<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
						<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
						<h2>Disclosure of Your Personal Data</h2>
						<h3>Business Transactions</h3>
						<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
						<h3>Law enforcement</h3>
						<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
						<h3>Other legal requirements</h3>
						<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
						<ul>
							<li>Comply with a legal obligation</li>
							<li>Protect and defend the rights or property of the Company</li>
							<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
							<li>Protect the personal safety of Users of the Service or the public</li>
							<li>Protect against legal liability</li>
						</ul>
						<h2>Security of Your Personal Data</h2>
						<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
						<h2>Changes to this Privacy Policy</h2>
						<p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
						<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
						<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
						<h2>Contact Us</h2>
						<p>If you have any questions about this Privacy Policy, You can contact us:</p>
						<ul>
							<li>
								<p>By email: info@wangoes.com</p>
							</li>
							<li>
								<p>Address: 
								Our Office
								322, PU-4, near Retina Speciality Hospital, Vijay Nagar, Indore, 452010 Madhya Pradesh,
								India
								(+91) 731 4968757
								</p>
							</li>
						</ul>
					
					</div>
		        </ModalBody>
		        <ModalFooter className="container">
		          <Button color="primary" onClick={this.togglePrivacy}>Accept</Button>{' '}
		          <Button color="secondary" onClick={this.togglePrivacy}>Cancel</Button>
		        </ModalFooter>

	        </Modal>
	    {/*Terms And Conditions*/}
	    	 <Modal scrollable={true}  size="lg" style={{maxWidth: '1600px', width: '100%'}}
 				className="text-align-center" isOpen={this.state.Tmodal} fade={false} toggle={this.terms_toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.terms_toggle} className="font-weight-bold text-align-center">
	          <h3 className="m-auto text-color-gradient font-weight-bold">TERMS & CONDITIONS</h3><small>www.trackme.wangoes.com</small></ModalHeader>
	          <ModalBody>
						<div className="container">
						<p >Last updated: 2020-09-28</p>
						<p>1. <b>Introduction</b></p>
						<p>Welcome to <b>Trackme App</b> (“Company”, “we”, “our”, “us”)!</p>
						<p>These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at <b>trackme.wangoes.com</b> (together or individually “Service”) operated by <b>Trackme App</b>.</p>
						<p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</p>
						<p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
						<p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at <b>info@wangoes.com</b> so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>
						<p>2. <b>Communications</b></p>
						<p>By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send.</p>


						<p>3. <b>Content</b></p>
						<p>Content found on or through this Service are the property of Trackme App or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>

						<p>4. <b>Prohibited Uses</b></p>
						<p>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>
						<p>4.1. In any way that violates any applicable national or international law or regulation.</p>
						<p>4.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
						<p>4.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
						<p>4.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>
						<p>4.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
						<p>4.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p>
						<p>Additionally, you agree not to:</p>
						<p>1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>
						<p>2. Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
						<p>3. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
						<p>4. Use any device, software, or routine that interferes with the proper working of Service.</p>
						<p>5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
						<p>6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>
						<p>7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
						<p>8. Take any action that may damage or falsify Company rating.</p>
						<p>9. Otherwise attempt to interfere with the proper working of Service.</p>
						<hr/>
						<p>5. <b>No Use By Minors</b></p>
						<p>Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.</p>
						<p>6. <b>Accounts</b></p>
						<p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
						<p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password.</p>
						<p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
						<p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
						<p>7. <b>Intellectual Property</b></p>
						<p>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Trackme App and its licensors.</p>
						<p>8. <b>Copyright Policy</b></p>
						<p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>
						<p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to <b>info@wangoes.com</b>.</p>
						<p>You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</p>

						

						<p>9. <b>Error Reporting and Feedback</b></p>
						<p>You may provide us either directly at info@wangoes.com with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>

						

						<p>10. <b>Disclaimer Of Warranty</b></p>
						<p>THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
						<p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
						<p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
						<p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>


						<p>11. <b>Limitation Of Liability</b></p>
						<p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>


						<p>12. <b>Termination</b></p>
						<p>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
						<p>If you wish to terminate your account, you may simply discontinue using Service.</p>
						<p>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

						<p>13. <b>Governing Law</b></p>
						<p>These Terms shall be governed and construed in accordance with the laws of Madhya Pradesh,India, which governing law applies to agreement without regard to its conflict of law provisions.</p>
						<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>

						<p>14. <b>Changes To Service</b></p>
						<p>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>

						<p>15. <b>Amendments To Terms</b></p>
						<p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
						<p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
						<p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>

					

						<p>16. <b>Acknowledgement</b></p>
						<p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
						<p>17. <b>Contact Us</b></p>
						<p>If you have any questions about this Privacy Policy, You can contact us:</p>
						<ul>
							<li>
								<p>By email: info@wangoes.com</p>
							</li>
							<li>
								<p>Address: 
								Our Office
								322, PU-4, near Retina Speciality Hospital, Vijay Nagar, Indore, 452010 Madhya Pradesh,
								India
								(+91) 731 4968757
							</p>
							</li>
						</ul>
					
					</div>
		       </ModalBody>
		        <ModalFooter className="container">
		          <Button color="primary" onClick={this.terms_toggle}>Accept</Button>{' '}
		          <Button color="secondary" onClick={this.terms_toggle}>Cancel</Button>
		        </ModalFooter>

	        </Modal>

	    {/*Term Disclamour*/}

			<Modal  className="text-align-center" size="lg"  isOpen={this.state.Dmodal} fade={false} toggle={this.disc_toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.disc_toggle} className="">
	          <span className="m-auto text-color-gradient font-weight-bold">Legal Disclaimer</span></ModalHeader>
	          <ModalBody>
	              <p class=" m-auto content-justify">
				Please read this disclaimer ("disclaimer") carefully before using [website] website (“website”, "service") operated by [trackme.wangoes.com] ("us", 'we", "our").
				The content displayed on the website is the intellectual property of the [name]. You may not reuse, republish, or reprint such content without our written consent.
				All information posted is merely for educational and informational purposes. It is not intended as a substitute for professional advice. Should you decide to act upon any information on this website, you do so at your own risk.
				While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.
				We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest 	changes, we advise you to frequently visit this page.

			</p>
	          </ModalBody>
	          <ModalFooter className="container">
		          <Button color="btn btn-outline-secondary" onClick={this.disc_toggle}>Close</Button>{' '}
		        </ModalFooter>
	        </Modal>


	        {/*Image modal show magnify*/}
	    	 <Modal centered={true}   size="lg" style={{maxWidth: '1400px', width: '80%',margin:'auto'}}
 				className="text-align-center" isOpen={this.state.img_modal} fade={false} toggle={this.img_toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} 
	          toggle={this.img_toggle} 
	          className="font-weight-bold text-align-center">
	          </ModalHeader>
	          <ModalBody>
						<div className="container p-3 bg-transparent">
						{ 

							this.state.img_path!=null
							?
									<Magnifier
			          					  imageSrc={this.state.img_path}
			          					  imageAlt="image not found"
			          					  largeImageSrc={this.state.img_path}
			          					  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK}
			          					  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} 
			          					/>
			          		:
			          		null
						}
					</div>
		       </ModalBody>
	        </Modal>	       
			  {/*<!-- ======= Hero Section ======= -->*/}
			  <section className="hero-section " id="hero">
			    <div className="container">
			      <div className="row align-items-center">
			        <div className="col-12 hero-text-image">
			          <div className="row">
			            <div className="col-lg-5 text-center mb-3 text-lg-left">
			              <h1 data-aos="fade-right">Keep on Tracking.</h1>
			              <p className="mb-2" data-aos="fade-right" data-aos-delay="100">Track Your Employees Every Day Activity With <br/> Trackme App For Free</p>
			              <a href="#" onClick={()=>{this.Navigate("register")}} className="btn btn-outline-white">Sign Up For Free</a>
			            </div>
			            <div className="col-lg-6 col-md-12 pl-0 mt-sm-4 iphone-wrap">
			              <img src={ require('./SoftLand/assets/img/trackme-employee-monitoring.png')} alt="Image" className="phone-1  m-auto" data-aos="fade-right"/>
        			    </div>
			          </div>
			        </div>
			      </div>
			    </div>

			  </section>{/*<!-- End Hero -->*/}

			  <main id="main">

			    {/*<!-- ======= Home Section ======= -->*/}
			    <section className="section">
			      <div className="container">

			        <div className="row justify-content-center text-center mb-5">
			          <div className="col-md-6" data-aos="fade-up">
			            <h2 className="section-heading ">Analyse Your Employees Performance</h2>
			          </div>
			        </div>

			        <div className="row">
			          <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
			            <div className="feature-1 text-center">
			              <div className="wrap-icon icon-1">
			              <span className="fas icon fa-hourglass-end"></span>
			                {/*<span className="icon la la-users"></span>*/}
			              </div>
			              <h3 className="mb-3">Total working hours</h3>
			              <p>Total Hours of work done on various Projects </p>
			            </div>
			          </div>
			          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
			            <div className="feature-1 text-center">
			              <div className="wrap-icon icon-1">
			                <span className="icon fas fa-tasks"></span>
			              </div>
			              <h3 className="mb-3">Activity Analysis</h3>
			              <p>Performance Of A Developer On Assigned Projects</p>
			            </div>
			          </div>
			          <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
			            <div className="feature-1 text-center">
			              <div className="wrap-icon icon-1">
			                <span className="icon fas fa-list-ol"></span>
			              </div>
			              <h3 className="mb-3">Summary</h3>
			              <p>Generate Activity Summary Of All Projects Developer Worked In A Month.</p>
			            </div>
			          </div>
			        </div>

			      </div>
			    </section>

			    <section className="section">

			      <div className="container">
			      <div className="row justify-content-center text-center mb-5">
			          <div className="col-md-8" data-aos="fade-up">
			            <h2 className="section-heading ">Working hours,Currently working Employees</h2>
			          </div>
			        </div>
			        <div className="row justify-content-center text-center mb-5" data-aos="fade">
			          <div className="col-md-10 m-auto mb-5">
			          <br/>
			          {/*<Magnifier
			          					  imageSrc={require("./Screenshots/company_dashboard.png")}
			          					  imageAlt="image not found"
			          					  largeImageSrc={require("./Screenshots/company_dashboard.png")}// Optional
			          					  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} // Optional
			          					  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} // Optional
			          					/>*/}
			        
			          <img onClick={()=>{this.showmodal(company_dashboard)}}  
			          	src={company_dashboard} alt="Image" 
			          	className="img-fluid point"/>
			          </div>
			        </div>
			        <section className="section">

			      <div className="container">
			      <div className="row justify-content-center text-center mb-5">
			          <div className="col-md-7" data-aos="fade-up">
			            <h2 className="section-heading ">Monthly activity & Download Summary in Excel</h2>
			          </div>
			        </div>
			        <div className="row justify-content-center text-center" data-aos="fade">
			          <div className="col-md-10 m-auto mb-5">
			          <br/>
			         {/* <Magnifier
			         					  imageSrc={require("./Screenshots/monthly_activity.png")}
			         					  imageAlt="image not found"
			         					  largeImageSrc={require("./Screenshots/monthly_activity.png")}// Optional
			         					  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} // Optional
			         					  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} // Optional
			         					/>*/}
			           <img  onClick={()=>{this.showmodal(monthly_activity)}} 
			           	src={require("./Screenshots/monthly_activity.png")} 
			           	alt="Image" className="img-fluid point"/>
			          </div>
			        </div>
			      </div>
			    </section>
			        <div className="row">
			          <div className="col-md-4">
			            <div className="step">
			              <span className="number">01</span>
			              <h3>Register</h3>
			              <p>Sign Up Company Account And verify account via  Email.</p>
			            </div>
			          </div>
			          <div className="col-md-4">
			            <div className="step">
			              <span className="number">02</span>
			              <h3>Send Invitation</h3>
			              <p>Send Invitation to your employees for their Account Registration</p>
			            </div>
			          </div>
			          <div className="col-md-4">
			            <div className="step">
			              <span className="number">03</span>
			              <h3>Enjoy The App</h3>
			              <p>Confirm your account and Download Monitor app.</p>
			            </div>
			          </div>
			        </div>
			      </div>

			    </section>

			    <section className="section" >
				    <div className="container">
				        <div className="row align-items-center">
				        	<div className="col-md-12 card m-auto">
					    	<Carousel>
							  <Carousel.Item interval={2000}>
							    <img
							      className="d-block m-auto w-100"
							      src={require("./Screenshots/company_register.png")}
							      alt="First slide"
							    />
							    <Carousel.Caption>
							     {/* <h3 className="text-dark">Register Company</h3>
							     							      <p className="text-secondary">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
							     							    */}
								</Carousel.Caption>
							  </Carousel.Item>
							  <Carousel.Item interval={2000}>
							    <img
							      className="d-block w-100"
							      src={require("./Screenshots/send_invitation.png")}
							      alt="Third slide"
							    />

							    <Carousel.Caption>
							      {/*<h3 className="text-secondary">Second slide label</h3>
							      							      <p className="text-secondary">Lorem ipsum dolor sit amet,
							      							       consectetur adipiscing elit.</p>*/}
							    </Carousel.Caption>
							  </Carousel.Item>
							  <Carousel.Item interval={2000}>
							    <img
							      className="d-block w-100"
							      src={require("./Screenshots/employee_screenshot.png")}
							      alt="Third slide"
							    />

							    <Carousel.Caption>
							      {/*<h3 className="text-secondary">Third slide label</h3>
							      							      <p className="text-secondary">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							      							    */}
							    </Carousel.Caption>
							  </Carousel.Item>
							</Carousel>
							</div>
						</div>
					</div>
			    </section>

			    <section className="section">
			    <LazyLoad debounce={false}>
			      <div className="container">
			        <div className="row align-items-center">
			          <div className="col-md-4 mr-auto">
			            <h2 className="mb-4">Completely Free</h2>
			            <p className="mb-4">There is no catch,this application is Completely free.Register your account send 
			            countless invitations to employees and Use. Click below to see how It works.</p>
			          </div>
			          <div className="col-md-6" data-aos="fade-left">
			          
			            <img src={require("./SoftLand/assets/img/People_work.jpg")} alt="Image" className="img-fluid"/>
			          </div>
			        </div>
			      </div>
			         </LazyLoad>
			    </section>
			    <section className="section">
			    <LazyLoad debounce={false}>
			      <div className="container">
			        <div className="row align-items-center">
			          <div className="col-md-4 ml-auto order-2">
			            <h2 className="mb-4">Contact Us</h2>
			            <p className="mb-4">Send us your valuable suggestion do not forget to write your comment.</p>
			            <p><button className="btn btn-primary" onClick={this.toggle}>Contact Us</button></p>
			          </div>
			          <div className="col-md-6" data-aos="fade-right">
			            <img src={require("./SoftLand/assets/img/undraw_svg_3.svg")} alt="Image" className="img-fluid"/>
			          </div>
			        </div>
			      </div>
			    </LazyLoad>
			    </section>



			    {/*<!-- ======= Testimonials Section ======= -->*/}
			    <section className="section border-top border-bottom">
			      <div className="container">
			        <div className="row justify-content-center text-center mb-5">
			          <div className="col-md-4">
			            <h2 className="section-heading">Review From Our Users</h2>
			          </div>
			        </div>
			        <div className="row justify-content-center text-center">
			          <div className="col-md-7">
			            <div className="owl-carousel testimonial-carousel">
			              <div className="review text-center">
			                <p className="stars">
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star muted"></span>
			                </p>
			                <h3>Excellent App!</h3>
			                <blockquote>
			                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, numquam
			                    aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi,
			                    provident voluptates consectetur maiores quos.</p>
			                </blockquote>

			                <p className="review-user">
			                  <img src={require("../images/person_1.jpg")} alt="Image" height="70px" width="70px" className="img-fluid rounded-circle mb-3"/>
			                  <span className="d-block">
			                    <span className="text-black">Jean Doe</span>, &mdash; App User
			                  </span>
			                </p>

			              </div>

			              <div className="review text-center">
			                <p className="stars">
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star muted"></span>
			                </p>
			                <h3>This App is easy to use!</h3>
			                <blockquote>
			                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, numquam
			                    aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi,
			                    provident voluptates consectetur maiores quos.</p>
			                </blockquote>

			                <p className="review-user">
			                  <img src={require("../images/person_1.jpg")} alt="Image" height="70px" width="70px" className="img-fluid rounded-circle mb-3"/>
			                  <span className="d-block">
			                    <span className="text-black">Johan Smith</span>, &mdash; App User
			                  </span>
			                </p>

			              </div>

			              <div className="review text-center">
			                <p className="stars">
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star"></span>
			                  <span className="icofont-star muted"></span>
			                </p>
			                <h3>Awesome functionality!</h3>
			                <blockquote>
			                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, numquam
			                    aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi,
			                    provident voluptates consectetur maiores quos.</p>
			                </blockquote>

			                <p className="review-user">
			                  <img src={require("../images//person_1.jpg")} height="70px" width="70px" alt="Image" className="img-fluid rounded-circle mb-3"/>
			                  <span className="d-block">
			                    <span className="text-black">Jean Thunberg</span>, &mdash; App User
			                  </span>
			                </p>

			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </section>{/*<!-- End Testimonials Section -->*/}

			    {/*<!-- ======= CTA Section ======= -->*/}
			    <section className="section cta-section">
			      <div className="container">
			        <div className="row align-items-center">
			          <div className="col-md-6 mr-auto text-center text-md-left mb-5 mb-md-0">
			            <h3>Time tracking instantly improves employee's focus and productivity.
						</h3>
			          </div>
			          <div className="col-md-5 text-center text-md-right">
			            <p><a onClick={()=>{this.Navigate("download")}} href="#" className="btn btn-lg btn-outline-white"><span className="icofont-brand-apple"></span>Start Employee Time Tracking</a></p>
			          </div>
			        </div>
			      </div>
			    </section>{/*<!-- End CTA Section -->*/}

			  </main>{/*<!-- End #main -->*/}

			  {/*<!-- ======= Footer ======= -->*/}
			<footer className="footer" role="contentinfo">
			  			    <div className="container">
			  			      <div className="row">
			  			        <div className="col-md-4 text-justify mb-4 mb-md-0">
			  			          <h3>About Trackme</h3>
			  			          <p>Trackme app is free Desktop application to keep an eye on your employee simply Register your account as company send invitation 
			  			          to your employee on email,make them register and download the trackme app just few simple steps and you are ready to go. </p>
			  			          <p className="social">
			  			            <a title="Linked-in" href="https://www.linkedin.com/company/wangoes-technology-indore" target="_blank"><span className="icofont-twitter fab fa-linkedin-in"></span></a>
			  			            <a title="Facebook" href="#"><span className="icofont-facebook fab fa-facebook"></span></a>
			  			            <a title="Gmail" href="#"><span className="icofont-dribbble fas fa-envelope" ></span></a>
			  			            <a title="Instagram" href="https://www.instagram.com/wangoes_technology/" target="_blank"><span className="icofont-behance fab fa-instagram"></span></a>
			  			          </p>
			  			        </div>
			  			        <div className="col-md-7 ml-auto">
			  			          <div className="row site-section pt-0">
			  			            <div className="col-md-4 mb-4 mb-md-0">
			  			              <h3>Explore</h3>
			  			              <ul className="list-unstyled">
			  			                
			  			                {/*<li><a href="#">About Us</a></li>*/}
			  			                <li><a className="point"  type="button" onClick={this.terms_toggle}>Terms & Conditions</a></li>
			  			                <li><a className="point" onClick={this.togglePrivacy} type="button">Privacy & Data Protection Terms</a></li>
			  			                <li><a className="point" onClick={this.toggle} type="button">Contact Us</a></li>
			  			                <li><a className="point" onClick={this.disc_toggle} type="button">Legal Disclaimer</a></li>

			  			              </ul>
			  			            </div>
			  			            
			  			                
			  			            <div className="col-md-4 mb-4 mb-md-0">
			  			              <h3>Services</h3>
			  			              <ul className="list-unstyled">
			  			                
			  			                <li><a className="point" onClick={this.toggle_f}>Support/FeedBack</a></li>
			  			              </ul>
			  			            </div>
			  			            <div className="col-md-4 mb-4 mb-md-0">
			  			              <h3>Downloads</h3>
										<ul className="list-unstyled">
				  			                <li><a className="point" onClick={()=>{this.Navigate("download","windows")}}>Windows</a></li>
				  			                 <li><a className="point"  onClick={()=>{this.Navigate("download","ubuntu")}}>Ubuntu</a></li>
			  			              </ul>
			  			            </div>
			  			          </div>
			  			        </div>
			  			      </div>


			  
		  			      	<div className="row justify-content-center text-center">
						        <div className="col-md-7">
						          <p className="copyright">&copy; Copyright SoftLand. All Rights Reserved</p>
						          <div className="credits">
						           
						            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
						          </div>
						        </div>
						      </div>
			  			    </div>
			  			  </footer>
			  			  {/*<Footer/>*/}
						<CookieConsent
							  location="bottom"
							  buttonText="Accept"
							  enableDeclineButton
							  declineButtonText='Decline'
							  cookieName="myAwesomeCookieName2"
							  style={{ background: "#2B373B" }}
							  
							  expires={150}
							>
							  <p key={"apj"} className="text-white text-justify">
							  	<p key={"apj12"} className="text-white text-justify">This website uses cookies to enhance the user experience.
								  	<a className="log_font text-white ml-1" onClick={this.terms_toggle} href="#">Terms &amp; conditions</a>
								  	<a className="log_font text-white ml-1" onClick={this.togglePrivacy} href="#">privacy policy</a></p>
							  	</p>
						</CookieConsent>

		   	<ScrollUpButton style={{backgroundColor: '#1976D2'}} />
		</Fragment>
	}
		</Fragment>
			);
	}
}


export default index