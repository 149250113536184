import React, { Component } from 'react'   
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";
import Footer from './Footer';

const style={
    fontSize:'20px'
}

class Summary extends Component { 
        constructor(props) {  
            super(props)  
            this.state = {  
                    Summary_res: [] ,
                    startDate :new Date(),
                    Name:'',
                    MONTH:["January","February","March","April","May","June",
                    "July","August","September","October","November","December"]
            } 
            this.handleChange=this.handleChange.bind(this) 
            this.showScreenshot=this.showScreenshot.bind(this)
        }  
        componentDidMount() { 
            let newDate = new Date()
            /*let date = newDate.getDate();*/
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear(); 
            /*console.log(month,year,this.state.startDate)*/
            this.showSummary(month,year,"componentdidmount")
             fetch(localStorage.ip_address+"/report/"+parseInt(localStorage.summary_id)+"/",{
            method: 'post',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            body: JSON.stringify({
                       "year":String(year),
                       "month":String(month)
                   })
            }).then((response) => response.json())
            .then((responseJson) => {
                this.setState({Summary_res:responseJson,Name:responseJson[0].member})
                /*console.log("Summary",responseJson)*/
            }).catch(err=>{
                console.log(err)
            })   
        }

        handleChange(date) {
           /* console.log('month',date)*/
            this.setState({
              startDate: date
            },()=>{
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                this.showSummary(month,year,"handle change")         
            })
        }

        showSummary=(month,year,message)=>{
            /*console.log(message,year,month)*/
            fetch(localStorage.ip_address+"/report/"+parseInt(localStorage.summary_id)+"/",{
            method: 'post',
            headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
            body: JSON.stringify({
                       "year":String(year),
                       "month":String(month)
                   })
            }).then((response) => response.json())
            .then((responseJson) => {
                this.setState({Summary_res:responseJson})
                if(responseJson.length){
                    /*console.log(responseJson.length)*/
                }
                else{
                    /*Swal.fire({
                    position: 'top-end',
                      timer: 2200,
                      title:this.state.MONTH[month-1],
                    text: 'No activity of this month is available',
                    icon: 'info',
                    showConfirmButton: false,
                })*/
                }
                /*console.log("Summary",responseJson)*/
            }).catch(err=>{
                console.log(err)
            })
        }

        showScreenshot=(date,id)=>{
             /*console.log("val_date",date,'id',id)*/
          this.props.history.push({pathname: '/showscreenshot',state: {User_id:id,Date:date}})
    

        }

        render() {  
            const {Summary_res,Name}=this.state
                return (  
                        <div className="container-fluid">
                           <div className="row px-3  mb-0">
                             {/* <div className="nav-link col-4  px-0">
                                {Summary_res.length?
                                     <ReactHTMLTableToExcel  
                                        className="btn btn-primary "  
                                        table="emp"  
                                        filename="ReportExcel"  
                                        sheet="Sheet"  
                                        buttonText="Export excel" />
                                :null}
                              </div>*/}
                              <div className="font-weight-bold text-color-gradient text-capitalize col-4 pt-2 m-auto">
                                 <h3>{Name}</h3>
                              </div>
                              <div className="text-right col-4 ml-auto">
                                {Summary_res.length?
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        dateFormat="MMMM,yyyy"
                                        showMonthYearPicker
                                        className="form-control point"
                                        />
                                :null}
                              </div>
                           </div>
                           <table id="emp" className="table mb-2">
                              {Summary_res.length?
                                  <thead>
                                     <tr  style= {{
                                     fontSize:'20px',
                                     color:'#fff',
                                     backgroundColor: '#215C8E'
                                     }}> 
                                     <th className="text-center">S.No.</th>
                                     <th className="text-center">Organization</th>
                                     <th className="text-center">Project</th>
                                     <th className="text-center">Date</th>
                                     <th className="text-center">Time Zone</th>
                                     <th className="text-center">Time</th>
                                     <th className="text-center" >Activity</th>
                                     <th className="text-center">Screenshot</th>
                                     </tr>  
                                  </thead>
                                  :<h3 className="text-center mt-5 text-primary">There is no summary generated for any employee.</h3>}
                              <tbody>
                                 {
                                 Summary_res.length
                                 ?
                                 Summary_res.map(data=>(
                                 <tr>
                                    <td style={style} className="text-center">{data.no}</td>
                                    <td style={style}>{data.organization}</td>
                                    <td style={style}>{data.project}</td>
                                    <td style={style} className="text-center">{moment(data.date).format("Do MMM YYYY")}</td>
                                    <td style={style} className="text-center" >{data.timezon}</td>
                                    <td style={style} className="text-center">{data.time}</td>
                                    <td style={style} className="text-center">{data.activity}%</td>
                                    <td style={style} className="text-center point" 
                                        onClick={()=>this.showScreenshot(data.date,localStorage.summary_id)}>
                                    <i className="fa fa-eye"></i>
                                    </td>
                                 </tr>
                                 )) 
                                 :
                                 null
                                 } 
                              </tbody>
                            </table>
                             <div className="float-right">
                                {Summary_res.length?
                                    <ReactHTMLTableToExcel  
                                     className="btn btn-primary"  
                                     table="emp"  
                                     filename="ReportExcel"  
                                     sheet="Sheet"  
                                     onClick={()=>
                                  alert('clicked')} 
                                  buttonText="Export excel" /> 
                                :null}      
                            </div>
                            <hr className="my-5 mt-3"/>
                            <Footer/>
                        </div>  
                    )  
            }  
}  

export default Summary