import React, { Component,Fragment } from 'react';
import DatePicker from 'react-datepicker';
import "../css/filterlist.css"
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/Employee.css"
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import ProgressBar from 'react-bootstrap/ProgressBar'
import LazyLoad from 'react-lazy-load';
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

import {
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";

class FilterImg extends Component {
	constructor (props) {
    super(props)

	const prop_pass=this.props.location.state

    this.state = {
      startDate: new Date(),
      hourly_img:[],
      all_images:prop_pass.all_images,
      User_id:prop_pass.User_id,
      ip_address:localStorage.ip_address,
      date:'',
      month:'',
      year:"",
      modal: false,
      next_prev_img:[],
      filter_day_hours:"",
      img_path:null,
	  img_modal:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.toggle=this.toggle.bind(this);
    this.openPopupbox=this.openPopupbox.bind(this)
	this.openPopupbox1=this.openPopupbox1.bind(this)
/*    this.setHourlyImg=this.setHourlyImg.bind(this)
*/  }



  handleChange(date) {
    this.setState({
      startDate: date
    })
  }
  
  toggle = () =>{
	  this.setState({modal:!(this.state.modal)})
	}
			

  openPopupbox=(path)=> {
		this.setState({img_path:path},
			()=>{this.img_toggle()})
	   
	}

	openPopupbox1=(path)=> {
		this.setState({img_path:path})
	   
	}

	img_toggle=()=>{
		this.setState({img_modal:!(this.state.img_modal)})
	}
	
	    
	removeImg=(id)=>{
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You want to delete this Screenshot",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		  if (result.isConfirmed) {
		  	fetch(localStorage.ip_address+"/delete_shot_image/"+id+'/',{
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
		})
		.then(res=>res.json())
		.then(resJson=>{
			/*console.log(resJson)*/
			if(resJson.message="Image Successfully Deleted")
			{
				Swal.fire({
					position: 'top-end',
		  			timer: 2200,
					text: 'Screenshot Deleted Successfully',
					icon: 'success',
					showConfirmButton: false,
				})



				fetch(localStorage.ip_address+'/user_detail/'+this.state.User_id+'/',{
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
				})
			.then(resj=>resj.json())
			.then(res=>{
				/*console.log("set images",res.Images)*/
				this.setState({all_images:res.Images},()=>{
					let array=[]
					let filter_date=this.state.all_images.filter(data=>(data.date)==this.state.date && (data.month)==this.state.month && (data.year)==this.state.year)
					/*console.log("filter",filter_date)*/
					for(let h=1; h<24;h++){
						let temp=filter_date.filter(value=>value.hour==h)
						if(temp.length)
						{
							array.push(temp)
						}
					}
					/*console.log("array",array)*/

					if(array.length){
						this.setState({
							hourly_img:array
						},()=>{
							let arr=[]
							for(let i=0; i<this.state.hourly_img.length; i++ ){
								/*console.log("check",i,this.state.hourly_img[i])*/
								for(let j=0; j<this.state.hourly_img[i].length; j++){
									arr.push(this.state.hourly_img[i][j].image)
								}
							}/*console.log("completed img",arr)*/
							this.setState({next_prev_img:arr})
							/*this.setHourlyImg()*/
						})
					}
					else{
						this.setState({hourly_img:[]})
					}
				})
			})
			.catch(err=>{
				Swal.fire({
					position: 'top-end',
		  			timer: 2200,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
				console.log(err)
			})
			}
			else if(resJson.message="Image Not Found")
			{
				Swal.fire({
					position: 'top-end',
		  			timer: 2200,
					text: 'Screenshot Not Found',
					icon: 'info',
					showConfirmButton: false,
				})

			}
		}).catch(err=>{
			Swal.fire({
					position: 'top-end',
		  			timer: 2200,
					text: 'Unable To Delete At This Moment',
					icon: 'error',
					showConfirmButton: false,
				})
			})  
		}
		})
	}	

  	onFormSubmit(e) {
	    e.preventDefault();

	    /*console.log(localStorage.b_os.split(" ")[0])*/

	    if(localStorage.browser=="firefox" || localStorage.b_os.split(" ")[0]=="Windows"){
		    var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			if(parseInt(date[1])<10){
				date[1]=0+date[1]
			}
			if(parseInt(date[0])<10){
				date[0]=0+date[0]
			}

			console.log(date[2]+'-'+date[1]+'-'+date[0] )
			/*console.log(this.state.User_id)*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.User_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[0]+'-'+date[1]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({filter_day_hours:resJson.today_work.split(":")})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[1],month:date[0],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[1] && (data.month)==date[0] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
				},()=>{
					let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
				})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    }
	    else{
	    	/*console.log(this.state.startDate)*/
	    	var date = this.state.startDate.toLocaleDateString().split("/");
			var time = this.state.startDate.toLocaleTimeString().split(":");
			/*console.log(date)*/
			/*console.log(typeof(date[2]+'-'+date[1]+'-'+date[0]))*/
			/*console.log(this.state.User_id)*/
			fetch(localStorage.ip_address+"/get_working_hours/"+parseInt(this.state.User_id)+'/',{
					method:"POST",
					headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
					body: JSON.stringify({
							   	"date":date[2]+'-'+date[1]+'-'+date[0]
							   })
				})
				.then(res=>res.json())
				.then(resJson=>{
					this.setState({filter_day_hours:resJson.today_work.split(":")})
					}).catch(err=>{
					console.log(err)
				})
			let array=[]
			this.setState({date:date[0],month:date[1],year:date[2]})

			let filter_date=this.state.all_images.filter(data=>(data.date)==date[0] && (data.month)==date[1] && (data.year)==date[2])
			/*console.log("filter",filter_date)*/
			for(let h=1; h<24;h++){
				let temp=filter_date.filter(value=>value.hour==h)
				if(temp.length)
				{
					array.push(temp)
				}
			}
			if(array.length){
				this.setState({
					hourly_img:array
					},()=>{
						let arr=[]
						for(let i=0; i<this.state.hourly_img.length; i++ ){
							/*console.log("check",i,this.state.hourly_img[i])*/
							for(let j=0; j<this.state.hourly_img[i].length; j++){
								arr.push(this.state.hourly_img[i][j].image)
							}
						}/*console.log("completed img",arr)*/
						this.setState({next_prev_img:arr})
					})
			}
			else{
				this.setState({hourly_img:[]})
			}
	    } 
		this.toggle()
	}
 
  render() {
  	const {all_images}=this.state
	const {ip_address,hourly_img,user_id,filter_day_hours,img_path}=this.state
	/*console.log('renderr',hourly_img)*/
    return (
    		<Fragment>
			   <div className='container'>
			      <div className="row ">
			         <a className="btn nav-link" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={this.toggle} type="button" >Filter</a>
			         <br/>
			         <br/>
			         <h2 className="text-center nav-link col-12 text-primary p-3">{filter_day_hours[0]}Hrs:{filter_day_hours[1]}Min:{filter_day_hours[2]}Sec</h2>
			         <Modal className="text-align-center" isOpen={this.state.modal} fade={false} toggle={this.toggle}>
			            <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} toggle={this.toggle} className="font-weight-bold text-align-center"><span className="m-auto">Select Date</span></ModalHeader>
			            <ModalBody>
				            <form onSubmit={this.onFormSubmit}>
				                <div className="modal-body">
				                    <div className="form-group ">
				                        <center>
				                            <DatePicker
				                              className="form-control w-100"
				                              onChange={this.handleChange}
				                              selected={ this.state.startDate }
				                              dateFormat="MMMM d, yyyy "							        
				                             />
				                        </center>
				                        <br/>
				                        <center><button type="submit" className="btn col-6 btn-primary">Show </button>
				                        </center>
				                    </div>
				                </div>
			                  {/*
			                  <div className="modal-footer border-top-0 d-flex justify-content-center">
			                     <br/>
			                     <button type="submit" className="btn btn-primary col-md-4 mt-0">Submit</button>
			                  </div>
			                  */}
			               	</form>
			            </ModalBody>
			        </Modal>
			        <div className="col-md-12 mt-5">
			            {	 		
			            hourly_img.length 
			            ?
			            hourly_img.map((data,index)=>(
			            <Fragment>
			               <h2 className="text-center font-weight-bold mb-3 text-color">{data[0].hour}:00{data[0].mode} to {parseInt(data[0].hour)+1}:00{data[0].mode}</h2>
			               <div className="col-md-12 mb-4">
			                  <hr className="w-25 m-auto" style={{backgroundColor:'#1976D2'}} />
			               </div>
			               <div className="row text-center  text-lg-left">
			                  {
			                  data.map((value,i)=>(
			                  <div key={value.mode+i} className="col-lg-3 col-md-4 my-2 col-6">
			                     <div className='px-1 my-0 pb-2 card' style = {{cursor: 'pointer'}}>
			                     <small className="">{value.hour}:{value.min}{value.mode}&nbsp;&nbsp;{localStorage.mode!="employee"?<i onClick={()=>this.removeImg(value.id)} className="float-right pt-1 fa fa-trash" aria-hidden="true"></i>:null}</small>
			                     <a onClick={()=>{this.openPopupbox(value.image)}} className="d-block mb-2 h-100">
									<LazyLoad>
			                     		<img width='300' className="img-fluid img-thumbnail" src={ip_address+value.image} alt="not available"/>
								    </LazyLoad>			                     </a>
			                     {
			                     parseInt(value.activity)>60
			                     ?
			                     <span  className="px-3">
			                        <ProgressBar variant="success"  now={parseInt(value.activity)} label={`${value.activity}%`} />
			                     </span>
			                     :
			                     <span  className="px-3">
			                        <ProgressBar variant="danger"  now={parseInt(value.activity)} label={`${value.activity}%`} />
			                     </span>
			                     }
			                  </div>
			               </div>
			               ))
			               }				   			
			               <br/>
			               <div  height='170' width='303' className="col-md-12 mt-5"></div>
			         </div>
			         </Fragment>	
			         ))
			         :
			         <Fragment>
			            {
			            <div className='text-center'>No Data Available please search </div>
			            }
			         </Fragment>
			         }
			      </div>
			   </div>
			   </div> 
			    {/*Image modal show magnify*/}
	    	 <Modal   size="lg" style={{maxWidth: '1400px', width: '80%',margin:'auto'}}
 				className="text-align-center" isOpen={this.state.img_modal} fade={false} toggle={this.img_toggle}>
	          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} 
	          toggle={this.img_toggle} 
	          className="font-weight-bold text-align-center">
	          </ModalHeader>
	          <ModalBody className="pt-0">
						<div className="container  bg-transparent">
						{ 
							this.state.img_path!=null
							?
							<div className="w-90 m-auto">
							    <Magnifier
									  imageSrc={img_path}
									  imageAlt="image not found"
									  largeImageSrc={img_path}// Optional
									  mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK} // Optional
									  touchActivation={TOUCH_ACTIVATION.SINGLE_TAP} // Optional
								/>
					    	{/*<img className="text-styles img-fluid mb-2"  height='550'  src={this.state.ip_address+path} alt="Image not found"/>*/}
					    	{	
					    		this.state.next_prev_img.indexOf(img_path)!=0
								?
						    	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)-1])} 
						    	className="btn btn-primary mr-5">
						    		<i className=" fas fa-angle-left  text-white">-Prev</i>
						    	</span>
						    	:
						    	null
						    	}
							    {	
						    		this.state.next_prev_img.indexOf(img_path)!= this.state.next_prev_img.length-1
									?
								   	<span onClick={()=>this.openPopupbox1(this.state.next_prev_img[this.state.next_prev_img.indexOf(img_path)+1])} 
								   	className="btn btn-primary float-right" >
								   		{/*console.log(this.state.next_prev_img.indexOf(path))*/}
								   		Next-<i className="fas fa-angle-right  text-white"></i>

									</span>
									:
							    	null
						    }
						    </div>
			          		:
			          		null
						}
					</div>
		       </ModalBody>
	        </Modal>
			   <ScrollUpButton style={{backgroundColor: '#1976D2'}} /> 
			</Fragment>
    	);
  	}
  
}

export default FilterImg;