import React from 'react'
import Home2 from '../Home2'
import ReactDOM from 'react-dom'
import * as Ip_address from "../Ip_address"
import Swal from "sweetalert2"

export class Emp_Logout extends React.Component {

	componentDidMount(){
		fetch(localStorage.ip_address+'/website_user_logout/', {
	  	   method: 'POST',
	  	   headers: {'Content-Type':'application/json'},
	  	   body: JSON.stringify({
	  	   	   	"username": localStorage.username	  	   	    
	  	   	   })
	  	  }).then((response) => response.json())
	  	   .then((responseJson) => {
	  	   	this.props.history.push('')	
			localStorage.clear()
			
			localStorage.setItem('ip_address',Ip_address.ip_address)

			localStorage.setItem("show",'login')										
			ReactDOM.render(<Home2 />,document.getElementById('root'));
		  	   
	  	   	})
	  	   	.catch((error) => {
	  	    	Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: 'Error in log out',
						icon: 'error',
						showConfirmButton: false,
					})
	  	   	})
	}
	
	render() {
		return (
			<div>
				
			</div>
		)
	}
}

export default Emp_Logout