import React, { Component, Fragment,useState,useEffect } from 'react';
import ReactDom from 'react-dom';
import '../css/Add_Employee.css';
import history from '../history';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import Footer from '../Footer';
	
function Add_Employee (){

	const history = useHistory()
	const [password1, setPass1] = useState('')
	const [password2,setPass2]=useState('')
	const [csrftoken,setCsrf]=useState('')
	const [email,setEmail]=useState('')
	const [total_registered,setReg]=useState([])
	const [isLoading ,setLoad]=useState(false)


	const handleSubmit=(event)=>{ 
		event.preventDefault();
		setLoad(true)
		fetch(localStorage.ip_address+'/send_invite/', {
		method: 'post',
		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
		body: JSON.stringify({
		   	"email": email,
		   	"company_id":localStorage.company_id
		   })
		}).then((response) => response.json())
		.then((responseJson) => {
			/*console.log("response of register type",responseJson)*/
			if(responseJson.message=="Invite send"){
				setLoad(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Invitation is send to ' +email,
					icon: 'success',
					showConfirmButton: false,
				})
				setEmail('')
			}
			else if(responseJson.message=="Already Invited")	
			{
				setLoad(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Already Invited',
					icon: 'success',
					showConfirmButton: false,
				})
				setEmail('')
			}
			else if(responseJson.message=="Invalid email address")
			{
				setLoad(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Invalid Email Address',
					icon: 'warning',
					showConfirmButton: false,
				})
				setEmail('')
			}				   
		})
		.catch((error) => {
		setLoad(false)
		 Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'Error In sending Invitation',
					icon: 'warning',
					showConfirmButton: false,
				})
				setEmail('')
		 console.log(error)
		});		
	}

	return(

		<Fragment>
			{				 				
			   isLoading
			   ?
			   <div className="container loader m-auto">
			      {
			      <center className="mt-5 pt-5 " id="loader_position">
			         <FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      </center>
			      }
			   </div>
			   :
			   <div id="fullscreen_bg" className="">
			      <div className="container-fluid body">
			         <div className="row py-5">
			            <p id="profile-name" className="profile-name-card"></p>
			            <div className="col-sm-7 m-auto">
			            <div className="container register mt-5">
    	                  <div className="row">
    	                     <div className="col-md-3 register-left">
    	                        <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt=""/>
    	                        <h3 className="font-weight-bold">Welcome</h3>
    	                        <h4 className="font-weight-bold">Send Invite</h4>
    	                     </div>
    	                     <div className="col-md-9 register-right">
    	                     	<h4 className="text-primary mt-4 text-center">Enter and email address to send invite</h4>
    	                        <div className="tab-content">
    	                           <form className="form-signin offset-2 mb-3" onSubmit = {handleSubmit }>
				                     <span id="reauth-email" className="reauth-email"></span>
				                     {/*<input name='username' type="text" id="username" className="form-control" placeholder="username"  onChange = { this.onChange }  autoFocus />*/}
				                     <input name='email' type="email" id="email" value={email} className="form-control input-focus" placeholder="Email address"  onChange={e => setEmail(e.target.value)}  />
				                     {/*{<input name='password1' type="password" id="password1" className="form-control"  onChange = { this.onChange } placeholder="Password"  />
				                     <input name='password2' type="password" id="password2" className="form-control"  onChange = { this.onChange } placeholder="Re-EnterPassword"  />}*/}
				                     <div className="row text-center">
				                        <div className="col-12 mt-1">
				                           <button className="btn btn-primary btn-block btn-signin-create col-12"  
				                           type="submit">Send</button>
				                        </div>
				                     </div>
				                     {/*{<a className='offset-md-1' style ={{cursor: 'pointer',color:'#1976D2'}} onClick={()=>{this.AlreadyRegistered()}}>Already Have An Account?</a>}*/}
				                  </form>
    	                        </div>
    	                     </div>
    	                  </div>
    	               </div>
    	            </div>
			      </div>
			   </div>
			   <div className="container-fluid text-center login_footer">
			      {/*
			      <p className="login_footer_text">All rights reserved | 2020-2025</p>
			      */}
			   </div>
			   </div>
			}
			<hr className="my-5"/>
			<Footer/>
		</Fragment>
	)
}

export default Add_Employee;