import React, { useState, useEffect, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import FadeLoader from "react-spinners/FadeLoader";
import Swal from 'sweetalert2';
import '../css/Dashboard.css'
import '../css/common.css'
import Footer from "../Footer"
import moment from 'moment'

const Task_list=()=> {
	const [emp_list, setUserdetail] = useState([])
	const [isLoading, setLoading] = useState(true)
	

	useEffect(()=>{	
		fetch(localStorage.ip_address+'/employee_task_list/'+localStorage.user_id+'/'+localStorage.emp_project_id+'/', {
			method: 'GET',
			headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			})
			.then((response)=> response.json())
			.then((resJson)=>{
				/*console.log("Users _list",resJson)*/
				setUserdetail(resJson['task_list'])
				/*console.log(resJson)*/
				setLoading(false)
			}).catch((error)=> {
				setLoading(false)
				Swal.fire({
					position: 'top-end',
		  			timer: 2150,
					text: 'Error',
					icon: 'error',
					showConfirmButton: false,
				})
			})	
	},[])

	 const detail=(id)=>{
	 	/*fetch(localStorage.ip_address+'/user_detail/'+id+'/',{
	 		headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
	 	})
		  .then(response => response.json())
		  .then((jsonResponse) => {
		  	
		  	if(jsonResponse.Images.length){	
		  		history.push({pathname: '/emp_screenshots',state: { Data: jsonResponse,User_id:id }})
		  	}
		  	else{
		  		Swal.fire({
					position: 'top-end',
		  			timer: 2000,
					text: 'No screenshot of this employee is available Now',
					icon: 'info',
					showConfirmButton: false,
				})
		  	}
		  	}).catch(error=>{console.log(error)
		  		console.log(error)
			})*/	
	}


	const customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		    Showing { from } to { to } of { size } Results
		</span>
	);


	const actionFormatter=(e, cell, row, rowIndex) =>{
	  	return (	  		
	    	<a className="table-content-font"  style={{color:'#206090'}} value={ cell.Task_id } >{cell.Project_Title}</a>
	  	);
	}


	const actionFormatterSn=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font" style={{color:'#206090'}} 
					value={ cell.Task_id } >
						{cell.No}
				</a>
			)
	}


	const actionFormatterDate=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"  
				 	style={{color:'#206090'}} value={ cell.Task_id } >
				 	{moment(cell.created_at).format("MMM Do YYYY")}
				 </a>
			)
	}
	

	const actionFormatterTask=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"
					  style={{color:'#206090'}}
					   value={ cell.Task_id } >
					   {cell.Task_Title}
				</a>
			)
	}


	const actionFormatterStatus=(e, cell, row, rowIndex)=>{
		return( 
				<a className="table-content-font" 
				style={{color:'#206090'}} value={ cell.Task_id } >
				{cell.Completed ? <p>Complete</p>:<p>Incomplete</p>}</a>
			)
	}


	const actionFormatterTime=(e, cell, row, rowIndex)=>{
		return(
				<a className="table-content-font"   style={{color:'#206090'}} value={ cell.id } >{cell.Time}</a>
			)
	}

	/*const handleImage=(e, cell, row, rowIndex)=>{
		return (
			cell.images.length
			?
	     	<img  className="dashboard_img" onClick={()=>{detail(cell.id)}} value={cell.id} width='170' height='96' alt="Not available" src={cell.images[0]} />
		  	:
	     	<img loading="lazy" onClick={()=>{detail(cell.id)}} value={cell.id}  className="dashboard_img text-center" width='109' height='75' alt="Not available" src={signup_logo} />
	  	);
	}*/

	const columns = [{
	  dataField: 'No',
	  text: 'S.No.',
	  headerAlign: 'left',
	  align:'left',
	  classes: 'align-middle',
	  formatter: actionFormatterSn,
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#215C8E'
	  }
	}, {
	  dataField: 'Task_Title',
	  headerAlign: 'Left',
	  align:'left',
	  formatter: actionFormatterTask,
	  text: 'Task',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	},
	 {
	  dataField: 'Time',
	  headerAlign: 'center',
	  formatter: actionFormatterTime,
	  align:'center',
	  text: 'Working Hours',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, {
	  dataField: 'created_at',
	  headerAlign: 'center',
	  formatter: actionFormatterDate,
	  align:'center',
	  text: 'Date',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	},{
	  dataField: 'Completed',
	  headerAlign: 'center',
	  formatter: actionFormatterStatus,
	  align:'center',
	  text: 'Status',
	  classes: 'align-middle',
	  headerStyle: {
	   	fontSize:'20px',
	   	color:'#fff',
	   	backgroundColor: '#1B7097'
	  }
	}, 
	
	];


	const options = {
	  	totalSize: emp_list.length,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: 'Back',
		nextPageText: 'Next',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
		text: '5', value: 5
		}, {
		text: '10', value: 10
		}, {
		text: 'All', value: emp_list.length
		}] 
	};


	return(
			<Fragment>
			   	{
			   isLoading				
			   ?
			   <div className="container loader m-auto">
			      {
			      <center className="mt-5 pt-5 " id="loader_position">
			         <FadeLoader
			            css=""
			            height={15}
			            width={5}
			            radius={2}
			            margin={2}
			            color={"black"}
			            loading={isLoading}    			         
			            />
			      </center>
			      }				
			   </div>
			   :
			   <div className='container-fluid'>
			      <div className='row'>
			         <div className="col-12">
			            <a className="float-right nav-link text-primary mb-2" 
			             ></a>
			         </div>
			         <br/>
			         <br/>			         
			            <div className="col-md-12 col-sm-12 col-12">
			               <div className="row ">
			                  <div className="col-lg-12">
			                     <div className="table-responsive col-lg-12" style={{maxHeight:'700px',overFlowX: 'auto'}}>
			                     <BootstrapTable
			                     keyField="id"
			                     data={ emp_list }
			                     columns={ columns }
			                     headerClasses="event-head"
			                     pagination={ paginationFactory(options) }
			                     bordered={ false }									
			                     />
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			      <hr className="my-5"/>
			  	 <Footer/>
			   </div>
			   }
			   <br/>
			   <br/>
			   
			</Fragment>
			)
		}

export default Task_list;

							