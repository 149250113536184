import React, { Component,Fragment } from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import Swal from 'sweetalert2'
import ReactDOM from 'react-dom'
import Home2 from "./Home2"
import { BrowserRouter as Router } from 'react-router-dom';

import history from './history'


 class Reset_pass extends Component {
 	constructor(props) {
 		super(props)	
 		this.state = {
 			 password1:'',
 			 password2:'',
 			 text_color:'text-white',
 			 link_status:'',
 			 loader:false,
 		}
 		/*this.AlertFun=this.AlertFun.bind(this)*/
 		this.onChange=this.onChange.bind(this)
 		this.Resetpassword=this.Resetpassword.bind(this)
 		this.Loader=this.Loader.bind(this)
 		this.login_route=this.login_route.bind(this)
 	}

 	onChange=(event)=>{
 		this.setState({ [event.target.name]:event.target.value})
 	}

 	componentDidMount(){
 	
 		fetch(localStorage.ip_address+'/change_password/'+parseInt(localStorage.link1.split('_')[1])+'/'+localStorage.link2+'/',{
				method: 'GET',
				headers: {'Content-Type':'application/json'}
				}).then(res=>res.json())
	 		.then(resJson=>{
	 			/*console.log("componentDidMount",resJson.error)*/
	 			if(resJson.message=="Active"){
	 				this.setState({link_status:resJson.message})
	 				
	 			}else if(resJson.error="Token already used"){
	 				Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Link is Expired",
						icon: 'info',
						showConfirmButton: false,

					})
					localStorage.setItem('show','login')
					this.props.history.push('')
	 				ReactDOM.render(
					  		<Home2/>					  
					  ,
					  document.getElementById('root')
					);

	 			}
	 			
	 		}).catch(err=>{
	 			console.log(err)
	 			
	 		})
 	}

 	Loader=(val)=>{
 		if(val=="false"){
 			this.setState({loader:false})
 		}
 		else if(val=="true"){
 			this.setState({loader:true})
 		}
 	}
	 


/*
 	AlertFun =(status)=>{
 		if(status=="success"){
 			Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Password Reset Successfully",
						icon: 'success',
						showConfirmButton: false,
					})
 					this.setState({password1:'', password2:'',loader:false})
 		}
 		else if(status=='error'){
 			Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Re-enter password carefully",
						icon: 'warning',
						showConfirmButton: false,
					})
 					this.setState({password1:'', password2:'',loader:false})
 		}
 		else if(status=='response_error'){
 			Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Error In Updating Password",
						icon: 'error',
						showConfirmButton: false,
					})
 					this.setState({password1:'', password2:'',loader:false})
 		}
 		else if(status=='Link_Expired'){
			Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Link is expired",
						icon: 'warning',
						showConfirmButton: false,
					})
					this.login_route()
 					this.setState({password1:'', password2:'',loader:false})
 		}
 	}*/

 	login_route=()=>{

				ReactDOM.render(
					<Router history={history}>
					  		<Home2/>
					  	</Router>
					  ,
					  document.getElementById('root')
					);
 	}

 	Resetpassword=(e)=>{
 		this.Loader('true')
 		e.preventDefault()

	 	if(this.state.password1 == this.state.password2)
 		{	
	 		fetch(localStorage.ip_address+'/change_password/'+parseInt(localStorage.link1.split('_')[1])+'/'+localStorage.link2+'/',{
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify({
					   	'new_password':this.state.password1
					   })
				}).then(res=>res.json())
	 		.then(resJson=>{
	 			if(resJson.message=='Password Successfully Reset'){
	 				this.Loader('false')
	 				Swal.fire({
						position: 'top-end',
			  			timer: 2250,
						text: "Password is Updated Successfully",
						icon: 'success',
						showConfirmButton: false,
					})
	 				localStorage.setItem("show","login")

	 				this.login_route()

	 			}

	 		}).catch(err=>{
	 			console.log("lnk expired",err)
	 		})
 		}
 		else{
 			this.Loader('false')
 			Swal.fire({
						position: 'top-end',
			  			timer: 2150,
						text: "Re-enter password carefully",
						icon: 'warning',
						showConfirmButton: false,
					})
 					this.setState({password1:'', password2:'',loader:false})
 			
 		}


 	}

	render() {
		return (
			<Fragment>
			   <div className="container">
			      	<div className="row">
			      		{
			      			this.state.loader==true
			      			?
			      			<div className="container loader m-auto">					
							     	{
							     	<center className="mt-5 pt-5 " id="loader_position">
								     	<FadeLoader
				     			          css=""
				     			          height={15}
				     			          width={5}
				     			          radius={2}
				     			          margin={2}
				     			          color={"black"}
				     			          loading={this.state.loader}    			         
				     			        />
			     			        </center>}
							</div> 
							:
			    	         <div className="col-md-8 m-auto pt-0">
			    	            <div id="Download" className="Download">
			    	            <br/>
			    	               <div className="container register mt-5">
			    	                  <div className="row">
			    	                     <div className="col-md-3 register-left">
			    	                        <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt=""/>
			    	                        <h3 className="font-weight-bold">Welcome</h3>
			    	                        <h4 className="font-weight-bold">Reset Password</h4>
			    	                     </div>
			    	                     <div className="col-md-9 register-right">
			    	                        <div className="tab-content">
			    	                           <form className="mt-5 ml-5 form mb-3" onSubmit={this.Resetpassword}>
			    	                              
			    	                              <div className="form-group">
			    	                              	<input required="required" value={this.state.password1} className="form-control input-focus" id="password1" type="password" name="password1" onChange={this.onChange} placeholder="Password" minLength="8"/>
			    	                              </div>
			    	                              <div className="form-group">
			    	                              	<input required="required" value={this.state.password2} className="form-control input-focus" id="password2" type="password" name="password2" onChange={this.onChange} placeholder="Confirm password" minLength="8"/>
			    	                              </div>		    	                             
			    	                              <div className="form-group">
			    	                              	<input type="submit" class="btn btn-primary col-12 ,mb-3 btn-outline-primary"   value="Reset Password"/>
			    	                               </div>
			    	                               <small className={this.state.text_color} id="warning">Password combination must have one Number,one character, length eight.  </small>

			    	                               {/*<a href="#" onClick={()=>this.showDownload("login")} className="forgot-password mb-3">
							                			Already have an account? Login here
							            		 	</a>*/}
			    	                              
			    	                           </form>
			    	                        </div>
			    	                     </div>
			    	                  </div>
			    	               </div>
			    	            </div>
			    	         </div>	
			    	     }
		    	      
		    	         {
		    	        localStorage.Register_page=="alreadyregister"
		    	        ?
		    	        <div className="container-fluid body">
								<div className="row py-5">
									<div className="card col-sm-5 m-auto" style={{borderRadius: '20px'}}>
							          
										<p id="profile-name" className="profile-name-card"></p>
										<div className="row">
											<div className="col-sm-12 text-center">
											<span >You are already registered</span>
												<form className="form-signin offset-2 mb-3" >
									                
									               <div className="row text-center">
									                	<div className="col-12 mt-1">
									                		<button onClick={()=>this.Redirect()}  className="btn btn-primary btn-block btn-signin-create col-12"  >Go to Download page</button>
									                	</div>
									                </div>
								           		</form>        
								           	</div>	
							           	</div>
									</div>
								</div>
							</div>
							:
							null
		    	         }

		    	         {
		    	         	localStorage.Register_page=="no register"
		    	         	?
		    	         	/*<h1>Invalid Link address</h1>*/
		    	         	<div className="card card-waves col-md-9 p-5">
							    
							    <div className="p-5 mb-0 alert text-center alert-danger alert-dismissible fade show">
								    <strong>Error!</strong> Invalid Link Address<br/><br/>
								    <a className="mt-2" href="trackme.wangoes.com">Click here to visit our website</a>
								</div>
							</div>	
		    	         	:
		    	         	null
		    	         }
		      		</div>
		   		</div>
			</Fragment>
		);
	}
}

export default Reset_pass