import React, {Component, Fragment} from 'react';
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import { Bar } from 'react-chartjs-2';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Card } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class WeeklyReport extends Component{

	constructor(props){
		super(props);
		this.state = {
			data:{
			  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			  datasets: [
			    {
			      label: 'Weekly Report',
			      fill: false,
			      lineTension: 0.1,
			      backgroundColor: 'rgba(75,192,192,0.4)',
			      borderColor: 'rgba(75,192,192,1)',
			      borderCapStyle: 'butt',
			      borderDash: [],
			      borderDashOffset: 0.0,
			      borderJoinStyle: 'miter',
			      pointBorderColor: 'rgba(75,192,192,1)',
			      pointBackgroundColor: '#fff',
			      pointBorderWidth: 1,
			      pointHoverRadius: 5,
			      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
			      pointHoverBorderColor: 'rgba(220,220,220,1)',
			      pointHoverBorderWidth: 2,
			      pointRadius: 1,
			      pointHitRadius: 10,
			      data: [65, 59, 80, 81, 56, 55, 40]
			    }
			  ]
			},
			data_val: [
			  { id: 1, name: 'George', animal: 'Monkey' },
			  { id: 2, name: 'Jeffrey', animal: 'Giraffe' },
			  { id: 3, name: 'Alice', animal: 'Giraffe' },
			  { id: 4, name: 'Alice', animal: 'Tiger' }
			],
			columns: [
			  { dataField: 'id',
			    text: 'Id',
			    headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			  { dataField: 'name', text: 'Project Name',
			  	headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			  { dataField: 'animal', text: 'Report',
			  	headerStyle: {
				   	fontSize:'20px',
				   	color:'#fff',
				   	backgroundColor: '#215C8E'
			  	}
			  },
			],
			options: {
			    totalSize: 4,
				paginationSize: 4,
				pageStartIndex: 1,
				firstPageText: 'First',
				prePageText: 'Back',
				nextPageText: 'Next',
				lastPageText: 'Last',
				nextPageTitle: 'First page',
				prePageTitle: 'Pre page',
				firstPageTitle: 'Next page',
				lastPageTitle: 'Last page',
				showTotal: true,
				disablePageTitle: true,
				sizePerPageList: [{		
				text: '5', value: 5
				}, {
				text: '10', value: 10
				}, {
				text: 'All', value: 4
				}] 
			}
		}
	}

	customTotal = (from, to, size) =>(
		<span className="react-bootstrap-table-pagination-total ml-3">
		  Showing { from } to { to } of { size } Results
		</span>
	);

	handleSelect(start, end, label){
		// alert(start)
  	}

  	handleEvent(event, picker) {
    	console.log(picker.startDate);
  	}

	render(){
		const selectionRange = {
      		startDate: new Date(),
      		endDate: new Date(),
      		key: 'selection',
    	}
		return(
			<Fragment>
				<Helmet>
					<meta http-equiv='cache-control' content='no-cache'/>
					<meta http-equiv='expires' content='0'/>
					<meta http-equiv='pragma' content='no-cache'/>
				</Helmet>
				<div className="container-fluid">
					<h3 className="text-primary mb-4">Weekly Report</h3>
					<div className="row mb-5">
						<div className="col-3">
							<Card>
						      <CardContent>
						        <Typography color="textSecondary" gutterBottom>
						         Projects Worked
						        </Typography>
						        <Typography className="text-primary" color="textSuccess" variant="h6" component="h2">
						         1
						        </Typography>
						      </CardContent>
						    </Card>	
						</div>
						<div className="col-3">
							<Card>
						      <CardContent>
						        <Typography color="textSecondary" gutterBottom>
						          Avg. Hours Per Day
						        </Typography>
						        <Typography className="text-primary" color="textSuccess" variant="h6" component="h2">
						         20
						        </Typography>
						      </CardContent>
						    </Card>	
						</div>
						<div className="col-3">
							<Card>
						      <CardContent>
						        <Typography color="textSecondary" gutterBottom>
						          Avg Activity
						        </Typography>
						        <Typography className="text-primary" color="textSuccess" variant="h6" component="h2">
						         30 %
						        </Typography>
						      </CardContent>
						    </Card>	
						</div>
						<div className="col-3">
							<Card>
						      <CardContent>
						        <Typography color="textSecondary" gutterBottom>
						          Earned
						        </Typography>
						        <Typography className="text-primary" color="textSuccess" variant="h6" component="h2">
						         Test
						        </Typography>
						      </CardContent>
						    </Card>	
						</div>
					</div>
					<div className="d-flex flex-row-reverse">
						<div className="col-sm-3 float-right">
							<DateRangePicker
						        initialSettings={{ startDate: '1/1/2021', endDate: '3/1/2021' }}
						        onEvent={this.handleEvent}
						        onCallback={this.handleSelect}
						    >
						    	<input type="text" className="form-control" />
				      		</DateRangePicker>
			      		</div>
			      	</div>
				    <h3 className="text-primary text-center">Total hours worked per day</h3>
					<div>
						<Bar
						  data={this.state.data}
						  width={150}
						  height={250}
						  options={{ maintainAspectRatio: false }}
						/>
					</div>
					<h3 className="text-primary mt-3 mb-3">Project Name</h3>
					<BootstrapTable
	                    keyField="id"
	                    data={this.state.data_val}
	                    columns={ this.state.columns }
	                    headerClasses="event-head"
	                    pagination={ paginationFactory(this.state.options) }
	                    bordered={ false }								
	                />
	                <hr className="my-5 mt-3"/>
	                <Footer />
				</div>
			</Fragment>
		)
	}
}

export default WeeklyReport;