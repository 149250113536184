import React, { Component, Fragment } from 'react';
import ReactDom from 'react-dom';
import '../css/Add_Employee.css';
import history from '../history';
import Swal from 'sweetalert2'
import FadeLoader from "react-spinners/FadeLoader";
import Footer from '../Footer';


class Change_Password extends Component{

	constructor(props){
	  super(props);
	  this.state = {
	  	username :'',
	  	password1 : '',
	  	password2 : '',
	  	old_password:'',
	  	emp_id: localStorage.resetpass_id,
	  	emp_list:[],
	  	loader:false,
	  }
	  this.handleSubmit = this.handleSubmit.bind(this);
	  this.Setloader=this.Setloader.bind(this);
	}

	onChange = event => this.setState({[event.target.name]:event.target.value});

	componentDidMount(){
			/*if(localStorage.mode=="employee" || localStorage.mode=="admin"){
				this.setState({emp_id: localStorage.user_id})
			}
			else if(localStorage.mode=="company"){
				this.setState({emp_id: localStorage.company_id})
			}
			fetch(localStorage.ip_address+'/users_list/')
			.then((response)=> response.json())
			.then((resJson)=>{
				this.setState({emp_list:resJson })
			}).catch((error)=> {
				console.log(error)
			})*/
	}

	Setloader(val){
		val ? this.setState({loader:true}):this.setState({loader:false})
	}

	handleSubmit(event){ 
		event.preventDefault();
		this.Setloader(true)
		/*console.log("run submit",this.state.old_password,this.state.emp_id,this.state.password1,this.state.password2)*/
		if(this.state.password1==this.state.password2){	
			fetch(localStorage.ip_address+'/reset_password/'+this.state.emp_id+'/', {
			method: 'POST',
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'},
			body: JSON.stringify({
					"old_password": this.state.old_password,
				    "new_password": this.state.password1,			    
				   })
			}).then((response) => response.json())
			.then((responseJson) => {
				/*console.log(responseJson)*/
				if(responseJson.message=="Password Successfully Reset")
				{	
					this.Setloader(false)
					Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Passowrd change successfully",
						icon: 'success',
						showConfirmButton: false,
					})
	   				this.setState({
	   					old_password:'',
	   					password1:'',
	   					password2:''
	   				})
	   				if(localStorage.mode=='employee'){
	   					this.props.history.push('/emp_logout')
	   				}
	   				else if(localStorage.mode=="company"){
	   					this.props.history.push('/comp_logout')
	   				}
	   				else{
	   					this.props.history.push('/logout')
	   				}
	   			}
	   			else if(responseJson.message== "Invalid details"){
	   				this.Setloader(false)
	   				Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Wrong old password",
						icon: 'error',
						showConfirmButton: false,
					})
					this.setState({old_password: ''})

	   			}
	   			else if(responseJson.message=="Invalid Password"){
	   				this.Setloader(false)
	   				Swal.fire({
						position: 'top-end',
			  			timer: 2000,
						text: "Wrong old password",
						icon: 'error',
						showConfirmButton: false,
					})
	   				this.setState({old_password: ''})
	   			}		   
			})
			.catch((error) => {
			this.Setloader(false)
			 Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: "Invalid login detail",
				icon: 'info',
				showConfirmButton: false,
			})
			 console.log(error)
			});
		}
		else{
			this.Setloader(false)
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: "Password Missmatch",
				icon: 'error',
				showConfirmButton: false,
			})
		}
	   
	 
	 };
	 
	/*AlreadyRegistered=()=>{
		this.props.history.push('/')
	}*/


	render(){
			const {emp_list}=this.state;
		return(
			<Fragment>
				<div className="container">
				<br/>
				<br/>
				{
    	         	this.state.loader==true
    	         	?
    	         		<div className="container loader m-auto">					
					     	{
						     	<center className="mt-5 pt-5 " id="loader_position">
							     	<FadeLoader
			     			          css=""
			     			          height={15}
			     			          width={5}
			     			          radius={2}
			     			          margin={2}
			     			          color={"black"}
			     			          loading={this.state.loader}    			         
			     			        />
		     			        </center>
	     			    	}
						</div> 
    	         	:
					<form className="form col-6 m-auto card" onSubmit={this.handleSubmit}>
						<center><i class="fa fa-user" aria-hidden="true"></i></center>
						<div className="form-group">
							<div className="input-group-prepend">
								<br/>
							</div>
						</div>
						<div className="form-group">
							<div className="input-group-prepend ">
								<span className="input-group-text" id="basic-addon1"><i className="fa fa-key"></i></span>
								{/*<span className="input-group-text" id="basic-addon1">Old Passsword</span>*/}
								<input value={this.state.old_password} className="form-control input-focus" id="old_password" type="password" name="old_password" onChange={this.onChange} placeholder="Old Password"  required/>
							</div>
						</div>
						<div className="form-group">
							<div className="input-group-prepend ">
							<span className="input-group-text" id="basic-addon1"><i className="fa fa-key"></i></span>
								<input value={this.state.password1} className="form-control input-focus" id="password1" type="password" name="password1" onChange={this.onChange} placeholder="New Password" minLength="8" required/>
							</div>
						</div>

						<div className="form-group">
							<div className="input-group-prepend ">
								<span className="input-group-text" id="basic-addon1"><i className="fa fa-key"></i></span>
								<input value={this.state.password2} className="form-control input-focus" id="password2" type="password" name="password2" onChange={this.onChange} placeholder="Confirm Password" minLength="8" required/>
							</div>
						</div>
						
						<button type="submit" className="btn btn-primary col-12 m-auto">Submit</button>
					</form>

				}
			</div>
			<hr className="my-5"/>
			<Footer/>
		</Fragment>
		)
	}
}

export default Change_Password;
