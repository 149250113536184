import {createBrowserHistory} from 'history';

export default createBrowserHistory()   

{/*<p>Accept this one {this.props.location.state.time}-
	-{this.props.location.state.some}</p>

!)pass the props with path
this.props.history.push({pathname: '/employee',state: { Data: jsonResponse }})


access props of history in this way

this.props.location.state.__name_od your prop_

*/}