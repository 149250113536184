import React,{useState,useEffect,Fragment} from 'react'
import "../css/common.css"
import * as $ from 'jquery';
import {
	Button,
 CardHeader,
  CardBody,
  Badge,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from '../Footer';

const Set_time=()=> {

	const [Time,setTime]=useState('')
	const [Newtime,setNewtime]=useState('')
	const[ip_address]=useState(localStorage.ip_address)
	const [delTime,setDeltime]=useState('')
	const [newDeltime,setDtime]=useState("")
	const [modal, setModal] = useState(false);
	const [modal_s,setModals]=useState(false)

	/*const location = useLocation()*/

	const toggle = () => setModal(!modal);

	const toggle_s=()=>setModals(!modal_s);

	useEffect(() => {
		/*Get Screen Shot time*/
		fetch(ip_address+'/set_shot_time/',{
			headers:{"company_id":localStorage.company_id,"token":localStorage.token,"username":localStorage.username,	 'Content-Type':'application/json'}
		})
		.then(res=>res.json())
		.then(resJson=>{
			resJson ? setTime(resJson.screenshot_time):console.log("")	;				
		}).catch(err=> {console.log(err)})

		/*Get already set delete screenshot time*/
		fetch(ip_address+'/delete_images_time/',{
			headers:{"company-id":localStorage.company_id,"token":localStorage.token,"username":localStorage.username, 'Content-Type':'application/json'}
		})
		.then(res=>res.json())
		.then(resJson=>{
			/*console.log('del time',resJson)*/
			resJson ? setDeltime(resJson.delete_image_time):console.log("");					
		}).catch(err=> {console.log(err)})

	},[])
	
	const onChange = event =>{ setNewtime({[event.target.name]:event.target.value});
							 setDeltime({[event.target.name]:event.target.value});
							}

/* Post new screen shot capture time*/
	const handleSubmit=(e)=>{
		e.preventDefault()
		fetch(ip_address+'/set_shot_time/', {
		  	method: 'post',
		  	headers: {"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json',"company_id":localStorage.company_id},
		  	body: JSON.stringify({
		  	 	"shot_time":Newtime
	  	   	})
	  	  }).then((response) => response.json())
	  	   .then((resJson)=>{
/*	  	   	console.log(resJson)
*/	  	   	if(resJson.screenshot_time){
	  	   		setTime(resJson.screenshot_time)
  	   			Swal.fire({
				  position: 'top-end',
				  icon: 'success',
				  title: 'New Time Set Successfully',
				  showConfirmButton: false,
				  timer: 2100
				})
	  	   	}
	  	   	toggle_s()
	  	   	setNewtime('')
	  	   }).catch((err)=>{
		  	   	Swal.fire({
				  position: 'top-end',
				  icon: 'error',
				  title: 'Please Enter Carefully',
				  showConfirmButton: false,
				  timer: 2100
				})
	  	   		toggle_s()
	  	   		setNewtime('')
	  	   		console.log("errr",err)
	  	   })
	}

/*Post New screenshot delete days*/
	const handleSubmitDel=(e)=>{
		e.preventDefault()
		fetch(ip_address+'/delete_images_time/', {
		  	   method: 'POST',
		  	   headers: {"token":localStorage.token,"username":localStorage.username,"company-id":localStorage.company_id,'Content-Type':'application/json'},
		  	   body: JSON.stringify({
		  	   	   	"delete_time":newDeltime
	  	   	   })
	  	  }).then((response) => response.json())
	  	   .then((resJson)=>{
	  	   	/*console.log("dyas",resJson)*/
	  	   	if(resJson.delete_image_time){
	  	   		setDeltime(resJson.delete_image_time)
	  	   		Swal.fire({
			  position: 'top-end',
			  icon: 'success',
			  title: 'Image Deletion time is Updated',
			  showConfirmButton: false,
			  timer: 2100
			})
	  	   	toggle()
	  	   	setDtime('')
	  	   	}
	  	   }).catch((err)=>{
	  	   		Swal.fire({
			  position: 'top-end',
			  icon: 'error',	
			  title: 'Please Enter Carefully',
			  showConfirmButton: false,
			  timer: 2100
			})
	  	   	setDtime('')
	  	   	toggle()
	  	   	console.log("errr",err)
	  	   })
	}

	return (
		<Fragment>
			<div className='container'>
				<div className="row">
					<div className="card col-md-5">
						<h3 className="card-title text-color text-center">Screenshot Capture Time</h3>
						<hr/>
						<div className="card-body text-center">
							<h2 className='text-center'>
					                  <small>Current Time is:</small>
					                  <Badge><span id="time">{Time}&nbsp;</span>min</Badge>
					               </h2> {/*
							<button className='btn rounded-circle btn-primary mt-2' type="button" title="Change" onClick={toggle_s}> <i class="fas fa-edit"></i> </button>*/}
							<hr className="w-50" /> </div>
					</div>
					<Modal isOpen={modal_s} toggle={toggle_s}>
						<ModalHeader cssModule={{ 'modal-title': 'w-100 text-center'}} toggle={toggle_s}> <span className="m-auto text-color-gradient font-weight-bold">Change Time</span> </ModalHeader>
						<ModalBody>
							<form onSubmit={handleSubmit} className='col-12'>
								<div className="modal-body px-5">
									<div className="form-group">
										<input type="text" className="form-control" name="Newtime" id="Newtime" maxLength="2" placeholder="Enter Days " value={Newtime} onChange={e=>{setNewtime(e.target.value)}} />
										<button type="submit" className="btn btn-primary col-12 mt-4">Submit</button>
									</div>
								</div>
							</form>
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={toggle_s}>Cancel</Button>
						</ModalFooter>
					</Modal>
					<div className="card col-md-5">
						<h3 className="card-title text-color text-center">Screenshot Delete Days</h3>
						<hr/>
						<div className="card-body text-center">
							<h2 className='text-center'>
					                  <small>Current Days is:</small>
					                  <Badge><span id="time">{delTime}&nbsp;</span>Days</Badge>
					               </h2> {/*
							<button className='btn  btn-primary mt-2 rounded-circle' title="Change" onClick={toggle} type="button"> <i class="fas fa-edit"></i> </button>*/}
							<hr className="w-50" /> </div>
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggle}>
					<ModalHeader cssModule={{ 'modal-title': 'w-100 text-center'}} toggle={toggle}><span className="m-auto text-color-gradient font-weight-bold">Change Time</span></ModalHeader>
					<ModalBody>
						<form onSubmit={handleSubmitDel}>
							<div className="modal-body px-5">
								<div className="form-group">
									<input type="text" className="form-control" name="newDeltime mt-2" id="newDeltime" maxLength="2" placeholder="Enter Days " value={newDeltime} onChange={e=>{setDtime(e.target.value)}} />
									<button type="submit" className="btn btn-primary col-12 mt-4">Submit</button>
								</div>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
			<hr className="my-5"/>
			<Footer/>
		</Fragment>
	)
}
export default Set_time
					  

