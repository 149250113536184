import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import '../css/Employee.css'
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import FadeLoader from "react-spinners/FadeLoader";
/*import ProgressBar from 'react-progressbar';
*/import ProgressBar from 'react-bootstrap/ProgressBar'
import Swal from 'sweetalert2'


class Employee extends React.Component {
	constructor(props) {
		super(props)
		const response=this.props.location.state.Data
		const user_id=this.props.location.state.User_id	
		this.state = {
			all_images:[],
			emp_month:response.This_Month,
			emp_week:response.This_Week,
			emp_today_date:response.Date,
			emp_today_hrs:response.Today,
			ip_address:localStorage.ip_address,
			hourly_img:[],
			user_id:user_id,
			NewTime:'',
			isLoading:true,		
		}
		this.setHourlyImg=this.setHourlyImg.bind(this)
		this.removeImg=this.removeImg.bind(this)
	}

	componentDidMount(){
		fetch(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',{
			headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
		})
		.then(resj=>resj.json())
		.then(res=>{
			/*console.log("set images",res.Images)*/
			this.setState({all_images:res.Images})
			this.setHourlyImg()
		})

		fetch(this.state.ip_address+'/set_shot_time/')
		.then(res=>res.json())
		.then(resJson=>{
			resJson ? this.setState({NewTime:resJson.screenshot_time}):console.log("Error in getting Time")			
		})	
	}


	setHourlyImg=()=>{
		let date=this.state.emp_today_date.split(" ")
		/*console.log("date",date,)*/
		let array=[]
		let today=this.state.all_images.filter(data=> data.date==date[2] && parseInt(data.hour)>=0 )						
/*		console.log("check after date",today)
*/		for(let h=24; h>=0; h--)
		{
			let temp=today.filter(value=>value.hour==h)
			if(temp.length)
			{
				array.push(temp)
			}
		}	
		if(array.length){
			this.setState({
				hourly_img:array,
				isLoading:false
			})
		}
	}
		

	DateWiseFilter=(val_date)=>{
		/*console.log("val_date",val_date)*/
  		this.props.history.push({pathname: '/FilterImg',state: {User_id:val_date,all_images:this.state.all_images }})
	}
		
	
	openPopupbox(path) {
	    var content = <div>
	    <img className="text-styles"  height='450' width='750' src={path} alt="Image not found"/>
	    </div>
		PopupboxManager.open({
	        content,
	        config: {
	          titleBar: {
	            enable: true,
	            text: 'Screenshot',
	          },
	          fadeIn: true,
	          fadeInSpeed: 100,
	        }
	      })
	}


	removeImg=(Img_id)=>{
	  	fetch(this.state.ip_address+'/delete_shot_image/'+Img_id+'/')
		.then(res=>res.json())
		.then(resJson=>{
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Screenshot deleted',
				icon: 'info',
				showConfirmButton: false,
			})	
/*			console.log(resJson.message)
*/			fetch(localStorage.ip_address+'/user_detail/'+this.state.user_id+'/',{
				headers:{"token":localStorage.token,"username":localStorage.username,'Content-Type':'application/json'}
			})
		.then(resj=>resj.json())
		.then(res=>{
			/*console.log("set images",res.Images)*/
			this.setState({all_images:res.Images})
			this.setHourlyImg()
		})
		
		}).catch(err=>{
			Swal.fire({
				position: 'top-end',
	  			timer: 2000,
				text: 'Error',
				icon: 'error',
				showConfirmButton: false,
			})
			console.log(err)
		})
	}
	

	render() {
		const {all_images,emp_month,emp_week,emp_today_date,emp_today_hrs}=this.state
		const {ip_address,hourly_img,user_id,NewTime,isLoading}=this.state
		return (
			<Fragment>	
				<div className="col-12">
						<a className="float-right nav-link mb-5" style={{cursor:'pointer',textDecoration: 'underline'}} onClick={()=>{this.props.history.push('/invitation_comp')}}>Send invitation</a>
				</div>
				<br/>
				<br/>
				<br/>			
				<div className="container ">
				    <div className="row" >
				        <div className="col-sm-6 py-2">
				            <div className="card card-body h-75">
				                <h3 className="card-title text-center mt-4 text-color  font-weight-bold">{emp_today_date}</h3>
							    <hr className='w-75 mx-auto'/>
							    <h1 className="card-subtitle mb-2 text-center text-muted" id="emp_today_hrs">{emp_today_hrs}&nbsp;Hrs</h1>
							    <p className="card-text text-center text-muted">Week:{emp_week} &nbsp; = &nbsp; Month {emp_month}</p> 
				            </div>
				        </div>
				        <div className="col-sm-6 py-2">
				            <div className="card h-75 text-dark" >
				                <div className="card-body "  >
				                    <div className="col-12 " >
				                    <h3 className="text-color text-center">Note</h3>
				                    	<hr/>
				                    	<ol className='pl-0'>
					                    	<li>Screen shot captures in every <span className='text-color font-weight-bold'>{NewTime}min</span></li>
					                    					                    		
										</ol>   
									</div>
				                </div>
				            </div>
				        </div>     
    				</div>
		    		<div>
		    			<a className="text-color"  style={{cursor:'pointer'}} onClick={()=>{this.DateWiseFilter(user_id)}}>Show Date wise filter</a>
		    			<i className="fa fa-search ml-1 text-color" aria-hidden="true"></i>
		    		</div>
		   	{					 				
		   		isLoading
		   		?
				<div className="container loader m-auto">					
			    	{
					    <center className="mt-5 pt-5 " id="loader_position">
					    	<FadeLoader
	     			          css=""
	     			          height={15}
	     			          width={5}
	     			          radius={2}
	     			          margin={2}
	     			          color={"black"}
	     			          loading={isLoading}    			         
	     			        />
	     			    </center>
	     			}				
				</div>		   		
		   		:
		   		hourly_img.map((data,index)=>(
		   			<Fragment key={index+"500"}>	   		
		   				<h2 className="text-color text-center font-weight-bold mb-3">{data[0].hour}:00{data[0].mode} to {parseInt(data[0].hour)+1}:00{data[0].mode}</h2>
				   		<div className="col-md-12 mb-4"><hr className="w-25 m-auto" style={{backgroundColor:'#1976D2'}} /></div>
		   				   	<div className="row text-center text-lg-left">	
				   				{
					   				data.map((value,i)=>(
										<div key={value.mode+i} className="col-lg-3 col-md-4 mb-3 col-6">
											<div className='px-1 my-2 pb-2 card' style = {{cursor: 'pointer'}}>
											    <small className="">{value.hour}:{value.min}{value.mode}&nbsp;&nbsp;<i onClick={()=>this.removeImg(value.id)} className="float-right pt-1 fa fa-trash delete-icon" aria-hidden="true"></i></small>
											    <a onClick={()=>{this.openPopupbox(ip_address+value.image)}} className="d-block mb-2 h-100">
											        <img loading="lazy" height='150' width='300' className="img-fluid img-thumbnail" src={ip_address+value.image} alt="not available"/>
											    </a>
												{
													parseInt(value.activity)>60
													?
													<span  className="px-3"><ProgressBar variant="success"  now={parseInt(value.activity)} label={`${value.activity}%`} /></span>									    
													:
													<span  className="px-3"><ProgressBar variant="danger"  now={parseInt(value.activity)} label={`${value.activity}%`} /></span>									    
												}
											</div>  
									    </div>
						   			))
				   				}
				   				<br/>
				   				<div   className="btn btn-sm col-md-12 my-2"></div>
		   				   	</div>
		   			</Fragment>	
		   		))
			}
 			</div> 						    
			<PopupboxContainer />
		   	<ScrollUpButton style={{backgroundColor: '#1976D2'}} /> 
		</Fragment>
		)
	}
}

export default Employee